import React from 'react';
import './style.scss';

import Spinner from '../../components/spinner';

const LazyLoading = () => (
  <div id="lazyScreen">
    <Spinner />
  </div>
);

export { LazyLoading as default };
