import React from "react";
import * as PropTypes from "prop-types";
import Localised from "../../components/localised";
import './style.scss'
import history from "../../storage/history";
import rotateToPortrait from '../../assets/rlaxxTV_Icons_SVG_220114/rotate-to-portrait/white.svg'
import rotateToLandscape from '../../assets/rlaxxTV_Icons_SVG_220114/rotate-to-landscape/white.svg'
import {isPortrait} from "../../helpers/measurements";

// import {isPortrait} from "../../helpers/measurements";



class RotateWarning extends React.Component {
  constructor() {
    super();
    this.state = { isLoaded: false };
  }

  componentDidMount() {
    this.loadTo = setTimeout(() => {
      this.setState({ isLoaded: true });
    },250);
  }

  componentWillUnmount() {clearTimeout(this.loadTo)}

  cancelFullScreen = () => {
    this.setState({isLoaded:false},()=>{
      this.loadTo=setTimeout(()=>{
        const { addRotateWarningForFullScreen } = this.props;
        if (history.location.pathname === "/" || history.location.pathname.indexOf("/home") !== -1) {
          if (typeof addRotateWarningForFullScreen === "function") addRotateWarningForFullScreen(false);
        } else {
          // eslint-disable-next-line import/no-named-as-default-member
          history.goBack();
        }

        const fullScreenElement =
          document.fullscreenElement ||
          document.msFullscreenElement ||
          document.mozFullScreenElement ||
          document.webkitFullscreenElement;

        if (fullScreenElement) {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.webkitExitFullscreen) {
            /* Safari */
            document.webkitExitFullscreen();
          } else if (document.msExitFullscreen) {
            /* IE11 */
            document.msExitFullscreen();
          } else {
            document.requestFullScreen();
          }
        }
      },250)
    })

  };

  showCloseButton() {
    // ((history.location.pathname.includes('/home')
    //   ||history.location.pathname==='/') && isPortrait()==="portrait")
    // ||
    if (
      history.location.pathname === "/on-demand" ||
      history.location.pathname.includes("/on-demand/channel-page/") ||
      (history.location.pathname.indexOf("/home") !== -1 && isPortrait()==="landscape")
    ) {
      return null;
    }


    return (
      // eslint-disable-next-line react/button-has-type
      <button
        onClick={() => {
          this.cancelFullScreen();
        }}
      >
        <Localised node="buttons.close" />
      </button>
    );
  }

  render() {
    const { type } = this.props;
    const {isLoaded}=this.state;
    return (
      <div className="rotate-wrapper">
        <div className={`warning-wrapper ${type} ${isLoaded && "isLoaded"}`}>
          <div className={`warning-item ${type}`}>
            <h2>
              <Localised node="modals.device_rotate.title" />
            </h2>
          </div>
          <div className={`warning-item ${type}`}>
            <img
              src={type === "landscape" ? rotateToLandscape : rotateToPortrait}
              alt="rotate-device"
            />
          </div>
          <div className={`warning-item ${type}`}>
            <p>
              <Localised node={`modals.device_rotate.description.rotate_to_${type}`} />
            </p>
          </div>
          <div className={`warning-item ${type}`}>{this.showCloseButton()}</div>
        </div>

      </div>
    );
  }
}

RotateWarning.propTypes={
  type:PropTypes.string.isRequired
}


export default RotateWarning;
