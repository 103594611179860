/* eslint-disable react/button-has-type,react/jsx-no-target-blank,jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */
import React from 'react'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import {Col, Row} from "react-bootstrap";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Lottie from "react-lottie";
import history from "../../storage/history";
import BurgerIcon from "./burger-icon";
import logoIcon from '../../assets/images/rlaxx_logo_mobile.svg'
import logoLong from '../../assets/images/rlaxx_logo_long.svg'
import settingsIcon from '../../assets/rlaxxTV_Icons_SVG_220114/icon/settings/white.svg'
import vod from '../../assets/rlaxxTV_Icons_SVG_220114/icon/vod/white.svg'
import liveTv from '../../assets/rlaxxTV_Icons_SVG_220114/icon/livetv/white.svg'
import epg from '../../assets/rlaxxTV_Icons_SVG_220114/icon/epg/white.svg'
import './style.scss'
import {getMobileDevicePlatform} from "../../helpers/measurements";
import Localised from "../localised";
import store, {appActions} from "../../storage/store";
import spinnerData from "../../helpers/spinner.json";





class Header extends React.Component {
  constructor(props) {
    super(props);
    this.logoIconRef = React.createRef();
    this.refLiveTVButton = React.createRef();
    this.refProgramGuideButton = React.createRef();
    this.refOnDemandButton = React.createRef();
    this.refHeader = React.createRef();
    this.refSettingsButton = React.createRef();
    this.toggleBurgerIcon = this.toggleBurgerIcon.bind(this);
    this.state = { collapsed: 0 };
  }

  componentDidMount() {
    const { extended } = this.props;

    // eslint-disable-next-line react/destructuring-assignment
    if (extended===1 && extended !== this.state.collapsed) {
      this.toggleBurgerIcon();
    }
    if (
      this.refLiveTVButton.current &&
      this.refProgramGuideButton.current &&
      this.refOnDemandButton.current
    ) {
      if (history.location.pathname === "/") {
        this.refLiveTVButton.current.style.backgroundColor = "#657BA4";
        this.refLiveTVButton.current.disabled = true;
      }
      if (history.location.pathname.includes("/home")) {
        this.refLiveTVButton.current.style.backgroundColor = "#657BA4";
        this.refLiveTVButton.current.disabled = true;
      }
      if (history.location.pathname.includes("/program-guide")) {
        this.refProgramGuideButton.current.style.backgroundColor = "#657BA4";
        this.refProgramGuideButton.current.disabled = true;
      }
      if (history.location.pathname.includes("/on-demand")) {
        this.refOnDemandButton.current.style.backgroundColor = "#657BA4";
        this.refOnDemandButton.current.disabled = true;
      }
      if (history.location.pathname.includes("/settings")) {
        this.refSettingsButton.current.style.backgroundColor = "#657BA4";
        this.refSettingsButton.current.disabled = true;
      }
    }
  }

  componentWillUnmount() {
    const{hideUncollapsedMenu}=this.props;
    if(typeof hideUncollapsedMenu ==='function') hideUncollapsedMenu();


  }

  activeViewButton = (item) => {
    if (history.location.pathname.includes(item.url)) return { backgroundColor: "#657BA4" };
    return {};
  };


  toggleBurgerIcon() {
    // eslint-disable-next-line no-unused-vars
    const { showCollapsedMenu, hideUncollapsedMenu, extended } = this.props;
    const { collapsed } = this.state;

    const logo = document.getElementById("iconLogo");
    const appParent=document.querySelector(".home-header");
    const app = document.querySelector(".header");
    // const blurs = document.querySelector(".blurs");
    const collapsedMenu =  document.getElementById("responsive-navbar-nav");
    const onDemand=document.getElementById("on-demand-page");
    const programGuide=document.getElementById("programGuide");
    const programGuideWrapper=document.getElementById("programGuideWrapper");

    const homeInfo = document.getElementById("home-info");
    const tabBar = document.querySelector(".tab-bar-wrapper");

    if (collapsed === 0) {
      this.setState({ collapsed: 1 }, () => {
        if (typeof showCollapsedMenu === "function") showCollapsedMenu();
        if (extended === 1) {
          app.style.transition="none";
          app.style.webkitTransition="none";
          if(logo){
            logo.style.transition = "none";
            logo.style.webkitTransition="none";
          }
          tabBar.style.transition ="none";
          tabBar.style.webkitTransition ="none";
          collapsedMenu.style.transition ="none";
          collapsedMenu.style.webkitTransition ="none";
          if (homeInfo) {homeInfo.style.transition = "none";
            homeInfo.style.webkitTransition = "none";
          }
          if (appParent) {
            appParent.style.transition="none";
            appParent.style.webKitTransition="none";
          }

        }else{
          if (appParent) {
            appParent.style.transition = "height 200ms ease-in-out";
            appParent.style.webKitTransition = "height 200ms ease-in-out";
          }
          app.style.transition="all 250ms ease-in-out";
          app.style.webkitTransition="all 250ms ease-in-out";
          if(logo){
            logo.style.transition = "all 250ms ease-in-out"
            logo.style.webkitTransition="all 250ms ease-in-out";
          }
          tabBar.style.transition = "all 0ms ease-in-out 249ms";
          tabBar.style.webkitTransition = "all 0ms ease-in-out 249ms";
          collapsedMenu.style.transition = "all 150ms ease-in-out!important";
          collapsedMenu.style.webkitTransition = "all 150ms ease-in-out!important";
          if (homeInfo) {homeInfo.style.transition = "all 250ms ease-in-out";
            homeInfo.style.webkitTransition = "all 250ms ease-in-out";
          }
        }
        if(onDemand) onDemand.style.overflow="hidden"
        if(programGuide) programGuide.style.overflow="hidden"
        if(programGuideWrapper){
          collapsedMenu.style.transition = "height 150ms ease-in-out!important";
          collapsedMenu.style.webkitTransition = "height 150ms ease-in-out!important";
          programGuideWrapper.style.height="0"
        }
        if(appParent) appParent.style.zIndex="5001"
        // if (homeInfo) homeInfo.style.zIndex = "-200";
        // if (blurs) blurs.style.zIndex = "-200";
        app.style.zIndex = "5002";

        if(logo){
          logo.style.width = "75.6px";
          logo.style.height = "24px";
        }


        app.style.background = "rgba(10, 19, 32, 0.95)";
        // app.style.width = `${window.innerWidth}px`;
        // collapsedMenu.style.width = `${window.innerWidth}px`;

        // if (appParent) {
        //   appParent.style.height = "100%";
        // }
        app.style.height="100vh";
        tabBar.style.zIndex = "0";
        // collapsedMenu.style.height="100%";
        // collapsedMenu.style.opacity="1";

        // if (extended === 0) store.dispatch(appActions.getBurgerMenuExtended(1));
      });
    } else {
      this.setState({ collapsed: 0 }, () => {
        app.style.transition="all 250ms ease-in-out";
        app.style.webkitTransition="all 250ms ease-in-out";
        if(logo){
          logo.style.transition = "all 250ms ease-in-out"
          logo.style.webkitTransition="all 250ms ease-in-out";
        }
        if (appParent) {
          appParent.style.transition = "height 250ms ease-in-out";
          appParent.style.webKitTransition = "height 250ms ease-in-out";
        }
        tabBar.style.transition = "none";
        tabBar.style.webkitTransition = "none";
        collapsedMenu.style.transition = "all 150ms ease-in-out";
        collapsedMenu.style.webkitTransition = "all 150ms ease-in-out";
        if (homeInfo) {homeInfo.style.transition = "all 250ms ease-in-out";
          homeInfo.style.webkitTransition = "all 250ms ease-in-out";
        }
        // collapsedMenu.style.height="1%";
        // collapsedMenu.style.opacity="0";
        app.style.height="auto";
        if(appParent){
          // appParent.style.height="auto";
          appParent.style.zIndex = "1";
        }
        if(logo){
          logo.src = logoIcon;
          logo.style.width = "38.77px";
          logo.style.height = "15px";
        }
        if(onDemand) onDemand.style.overflow="scroll"
        if(programGuide) programGuide.style.overflow="scroll"
        if(programGuideWrapper){
          collapsedMenu.style.transition = "height 150ms ease-in-out!important";
          collapsedMenu.style.webkitTransition = "height 150ms ease-in-out!important";
          programGuideWrapper.style.height="calc(100% - 56.25vw - 70px)";

        }
        tabBar.style.zIndex = "5003";
        // if (homeInfo) homeInfo.style.zIndex = "2";
        // if (blurs) blurs.style.zIndex = "0";

        app.style.backgroundColor = "transparent";
        if (typeof hideUncollapsedMenu === "function") hideUncollapsedMenu();
        if (extended === 1) store.dispatch(appActions.getBurgerMenuExtended(0));
      });
    }
  }

  clickDesktopNavButton(path) {
    if (
      this.refLiveTVButton.current &&
      this.refProgramGuideButton.current &&
      this.refOnDemandButton.current &&
      this.refSettingsButton.current
    ) {
      const buttonArr = [
        this.refLiveTVButton,
        this.refProgramGuideButton,
        this.refOnDemandButton,
        this.refSettingsButton,
      ];

      buttonArr.forEach((btn) => {
        // eslint-disable-next-line no-param-reassign
        btn.current.style.backgroundColor = "#334B6D";
      });

      if (path === "/") this.refLiveTVButton.current.style.backgroundColor = "#657BA4";

      if (path.includes("/home")) this.refLiveTVButton.current.style.backgroundColor = "#657BA4";

      if (path.includes("/program-guide"))
        this.refProgramGuideButton.current.style.backgroundColor = "#657BA4";

      if (path.includes("/on-demand"))
        this.refOnDemandButton.current.style.backgroundColor = "#657BA4";

      if (path.includes("/settings"))
        this.refSettingsButton.current.style.backgroundColor = "#657BA4";

      history.push(path);
    }
  }

  clickMobileWatchButton(path) {
    const {extended}=this.props;
    // const {collapsed}=this.state;
    this.toggleBurgerIcon();
     if(extended!==0) store.dispatch(appActions.getBurgerMenuExtended(0));
    if (history.location.pathname.indexOf(path)=== -1 )    history.push(path);
  }

  clickMobileSettingsButton(path) {
    const { collapsed } = this.state;
    store.dispatch(appActions.getBurgerMenuExtended(collapsed));
    this.toggleBurgerIcon();
    history.push(`/settings/${path.url}`);
  }

  // eslint-disable-next-line class-methods-use-this
  clickRlaxxAppButton() {
    let path = "";
    if (getMobileDevicePlatform() === "Android") {
      path = "https://play.google.com/store/apps/details?id=com.rlaxxtv.tvapp.atv";
    } else if (getMobileDevicePlatform() === "IOS") {
      path = "https://apps.apple.com/app/rlaxx-tv/id1537986411";
    }

    return path;
  }

  renderLogo() {
    const {  isBuffering, UIActive, deviceType } = this.props;
    const { collapsed } = this.state;
    let mobileLogo;

    const spinnerOptions = {
      loop: true,
      autoplay: true,
      animationData:spinnerData,
    };

    const spinnerGif=<Lottie
      options={spinnerOptions}
      width={deviceType==="desktop"?60:40}
      height={deviceType==="desktop"?60:40}
    />

    if (collapsed === 1) {
      mobileLogo = logoLong;
    } else {
      mobileLogo = logoIcon;
    }


    if(collapsed!==1 && isBuffering) return spinnerGif;

    if (deviceType!=="desktop") {
      return (
        <img
          id="iconLogo"
          className={`${collapsed===0 && isBuffering ? "loading" : "loaded"} ${collapsed===1 && 'collapsed-logo'}`}
          src={mobileLogo}
          alt="rlaxx-logo"
        />
      );
    }

    return (
      <img
        className={`desktopLogo ${isBuffering && UIActive ? "loading" : "loaded"}`}
        src={isBuffering && UIActive ? spinnerGif : logoLong}
        alt="rlaxx-logo"
      />
    );
  }

  renderBurgerMenuItems() {
    const watchNow = [
      { title: "live", url: "home", icon: liveTv },
      { title: "on_demand", url: "on-demand", icon: vod },
    ];
    const settings = [
      { title: "language", url: "language" },
      { title: "notifications_and_announcements", url: "notifications_and_announcements" },
      // { title: "faq", url: "faq" },
      { title: "help_and_support", url: "help_and_support" },
      { title: "consent_preferences", url: "consent_preferences" },
      { title: "privacy_policy", url: "privacy_policy" },
      { title: "imprint", url: "imprint" },
    ];
    const more = [
      { title: "rlaxx TV App", url: this.clickRlaxxAppButton() },
      { title: "More About rlaxx TV", url: "https://rlaxxtv.com/" },
    ];

    return (
      <Nav className="mobile-nav mr-auto">
        <div className="mobile-menu-item">
          <h3>
            <Localised node="buttons.watch_now" />
          </h3>
          {watchNow.map((item) => (
            <div className="target-wrapper" role="button"   onClick={() => {
              this.clickMobileWatchButton(`/${item.url}`);
            }}>
            <button
              key={item.title}
              className="header-button-watch"
              style={this.activeViewButton(item)}
            >
              <img className="burger-menu-button-icon" src={item.icon} alt={item.title} />{" "}
              <Localised node={`navigation.${item.title}`} />
            </button>
            </div>
          ))}
        </div>
        <div className="mobile-menu-item">
          <h3>
            <img width="18.22" height="19.93" src={settingsIcon} alt="settings" />{" "}
            <Localised node="navigation.settings" />
          </h3>
          {settings.map((item) => (
            <div className="target-wrapper" role="button"  onClick={() => {
              this.clickMobileSettingsButton(item);
            }}>
            <button
              className="header-button-settings"
            >
              <Localised node={`settings.${item.title}.title`} />
            </button>
            </div>
          ))}
        </div>
        <div className="mobile-menu-item">
          {more.map((item) => (
            <div className="target-wrapper">
            <a target="_blank" href={item.url}>
              <button className="header-button-more">{item.title}</button>
            </a>
            </div>
          ))}
        </div>
        <div className="version-number"><p> <Localised node="general.app_version"/>: {process.env.REACT_APP_VERSION}</p> </div>
      </Nav>
    );
  }

  renderDesktopHeader() {
    return (
      <>
        <Col lg={{ order: 2 }}>
          <Row className="justify-content">
            <div className="header-button-group">
              <button
                ref={this.refLiveTVButton}
                className="header-button"
                onClick={() => {
                  this.clickDesktopNavButton("/home");
                }}
              >
                <img src={liveTv} alt="live-tv" /> <Localised node="navigation.live" />
              </button>
              <button
                ref={this.refProgramGuideButton}
                className="header-button"
                onClick={() => {
                  this.clickDesktopNavButton("/program-guide");
                }}
              >
                <img src={epg} alt="epg" /> <Localised node="navigation.program_guide" />
              </button>
              <button
                ref={this.refOnDemandButton}
                className="header-button on-demand"
                onClick={() => {
                  this.clickDesktopNavButton("/on-demand");
                }}
              >
                <img src={vod} alt="on-demand-channels" /> <Localised node="navigation.on_demand" />
              </button>
            </div>
          </Row>
        </Col>

        <Col className="desktop-settings" lg={{ span: 2, order: 3 }}>
          <button
            ref={this.refSettingsButton}
            onClick={() => {
              this.clickDesktopNavButton("/settings");
            }}
            className="header-button settings"
          >
            <img className="header-button-settings-icon" src={settingsIcon} alt="settings" />
          </button>
        </Col>
      </>
    );
  }

  renderMobileHeader() {
    const { collapsed } = this.state;
    const { ref,extended, deviceType } = this.props;
    return (
      <Col xs={{ span: 12, order: 1 }} style={{ paddingLeft: "0", height:"100%"}}>
        <Navbar
          ref={ref}
          id="navbar"
          collapseOnSelect
          expand={deviceType==="desktop"?"lg":false}
          bg="transparent"
          style={{ padding: 0 }}
          onToggle={this.toggleBurgerIcon}
          expanded={collapsed === 1}
        >
          <Navbar.Toggle
            id="toggler-button"
            aria-controls="responsive-navbar-nav"
            // eslint-disable-next-line react/no-children-prop
            children={<BurgerIcon collapsed={extended === 1 ? 1 : collapsed} />}
          />
          <Navbar.Collapse id="responsive-navbar-nav">
            {this.renderBurgerMenuItems()}
          </Navbar.Collapse>
        </Navbar>
      </Col>
    );
  }

  renderHeader = () => {
    // eslint-disable-next-line no-unused-vars
    const { isBuffering,deviceType } = this.props;
    const { collapsed } = this.state;
    return (
        <Container
          id="header"
          ref={this.refHeader}
          fluid
          m-auto
          className={`header ${deviceType==="desktop" ? "desktop" : "mobile"}`}
        >
          <Row md="10" m-auto className="header-menu">
            <Col
              className={`corner-logo ${deviceType==="desktop" && "desktop"} ${collapsed!==1 && isBuffering && "buffering"}`}
              lg={deviceType==="desktop"?{ span: 2, offset: 0, order: 1 }:{ span: collapsed === 1 ? 2 : 1, order: 2 }}
              xs={{ span: collapsed === 1 ? 2 : 1, order: 2 }}
            >

              {this.renderLogo()}
            </Col>
            {deviceType!=="desktop"? this.renderMobileHeader() : this.renderDesktopHeader()}
          </Row>
        </Container>
      )

  };

  render() {
    return this.renderHeader();
  }
}

Header.propTypes={
  isBuffering:PropTypes.bool,
  hideUncollapsedMenu: PropTypes.func,
  showCollapsedMenu:PropTypes.func,
  UIActive:PropTypes.bool,
  // screenSize:PropTypes.number,
  extended:PropTypes.number


}

Header.defaultProps={
  isBuffering:false,
  hideUncollapsedMenu:undefined,
  showCollapsedMenu:undefined,
  UIActive:undefined,
  // screenSize:undefined,
  extended:undefined,

}

const mapStateToProps = state => ({
  extended: state.app.extended
})

export default connect(mapStateToProps,)(Header);



