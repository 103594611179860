// eslint-disable-next-line import/no-extraneous-dependencies
import { createHashHistory} from 'history';

const history = createHashHistory({
  baseName:process.env.PUBLIC_URL,
  initialIndex:0
});


const ENABLE_LOGBOOK = true;

const logbook = {
  pastLocations: [],
  previousState: undefined,
};

function getIndexOfLastPath(location) {
  // eslint-disable-next-line no-plusplus
  for (let i = logbook.pastLocations.length - 1; i >= 0; i--) {
    if (location.pathname === logbook.pastLocations[i].pathname) return i;
  }
  return -1;
}

function updatePastLocations(location, action) {

  switch (action) {
    case 'PUSH': // history.push
      logbook.pastLocations.push(location);
      break;
    case 'REPLACE': // history.replace
    {
      const index = logbook.pastLocations.length - 1;
      if (index >= 0) {
        logbook.pastLocations[index] = location;
      }
    }
      break;
    case 'POP': // back/forward button or goBack
    {
      const goToIndex = getIndexOfLastPath(location);
      const removed = logbook.pastLocations.splice(goToIndex + 1);
      const previousLocation = removed[0];
      logbook.previousState = previousLocation ? previousLocation.state : undefined;

      // location according to pastLocations
      const appLocation = logbook.pastLocations[logbook.pastLocations.length - 1];
      if (!(appLocation && appLocation.key === location.key)) {
        // Clean up history if the location gets unsynced
        logbook.pastLocations = [location];
      }
    }
      break;
    default:
  }
}

if (ENABLE_LOGBOOK) {

  history.listen(updatePastLocations);
}

function isPreviousLocationWithinApp() {
  if (!ENABLE_LOGBOOK) return true;
  return logbook.pastLocations.length > 1;
}

export function getPastLocations() {
  return logbook.pastLocations;
}

export function getPreviousState() {
  if (!ENABLE_LOGBOOK) return undefined;
  return logbook.previousState;
}

/**
 * Go back in the history. Take into account that these methods are not (and cannot be)
 * 100% synced with the app history, so it won't work properly with hot reloads
 * or history.go(+X).
 * @param callback function to execute if there is no more steps to go back
 */
export function goBack(callback = undefined) {
  if (!ENABLE_LOGBOOK || isPreviousLocationWithinApp()) {
    history.goBack();
  } else if (typeof callback === 'function') {
    callback();
  } else {

    window.close();
  }
}

export function clearHistory(currentLocation = undefined) {
  logbook.pastLocations = [];
  if (currentLocation) {
    logbook.pastLocations.push({ pathname: currentLocation });
  }
  logbook.previousState = undefined;
}

export default history;
