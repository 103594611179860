export default {
  UPDATE_CHANNELS: 'UPDATE_CHANNELS',
  UPDATE_EPG: 'UPDATE_EPG',
  UPDATE_VOD: 'UPDATE_VOD',
  SET_CURRENT_CHANNEL: 'SET_CURRENT_CHANNEL',
  SET_COUNTRY: 'SET_COUNTRY',
  GET_SCREENSIZE:'GET_SCREENSIZE',
  GET_BURGER_MENU_EXTENDED:'GET_BURGER_MENU_EXTENDED',
  GET_DEVICE_TYPE:"GET_DEVICE_TYPE",
  UPDATE_PROMOTED:"UPDATE_PROMOTED",
  UPDATE_ROTATION:"UPDATE_ROTATION",
  TOGGLE_TABBAR_VISIBLE:"TOGGLE_TABBAR_VISIBLE"
};
