import React from 'react';
import './style.scss';
import Lottie from "react-lottie";
import spinnerData from "../../helpers/spinner.json";


const Spinner = () =>{
  const spinnerOptions = {
    loop: true,
    autoplay: true,
    animationData:spinnerData,
  };
  return (
    <div className="spinner">
      <Lottie
        options={spinnerOptions}
        width={60}
      />
    </div>
  )


}

export default Spinner;
