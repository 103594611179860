
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import axios from "axios";
// import MobileDetect from "mobile-detect";
import App from './App';
import reportWebVitals from './reportWebVitals';
import PlayerContext from './storage/contexts/PlayerContext';
import { initAppProperties } from './storage/app-properties';
import store from "./storage/store";
import Player from "./views/player";
import './index.scss';
import { mobileSize } from "./helpers/measurements";
import './views/error/style.scss';
import logo from "./assets/images/rlaxx_logo_mobile.svg";
import errorLogo from "./assets/images/error.png";
import logoLong from "./assets/images/rlaxx_logo_long.svg";
import history from "./storage/history";
import thirdSequence from './assets/images/third-sequence.svg';
import secondSequence from './assets/images/second-sequence.svg';
import progressFirst from "./assets/images/progress-first.svg";
import progressSecond from "./assets/images/progress-second.svg"
import progressThird from "./assets/images/progress-third.svg"




const PlayerRef = React.createRef();


// const md= new MobileDetect(window.navigator.userAgent)




initAppProperties();

const networkErrorPage=()=>(<div className={`error-wrapper ${window.innerWidth >= mobileSize && "desktop"}`}>
  <img className="logo" src={window.innerWidth >= mobileSize ? logoLong : logo} alt="rlaxx-logo" />
  <div style={{ margin: "auto" }}>
    <div className="error-image">
      <img src={errorLogo} alt="rlaxx-error" />
    </div>
    <div className="error-explanation">
      <p>
        <span>Sorry,</span> we are unable to connect you to rlaxx TV.
      </p>
    </div>
    <div className="error-button-group">
      <div>
        <div>
          <p>Please check your internet connection and try again.</p>
        </div>
        <div>
          {/* eslint-disable-next-line react/button-has-type */}
          <button
            onClick={()=>window.location.reload()}
          >
            Try Again
          </button>
        </div>
      </div>


    </div>
  </div>
  <div className="error-code">
    <p>Error Code: 408 | App Version: {process.env.REACT_APP_VERSION}</p>
    {/* eslint-disable-next-line max-len */}
    <p style={{color:"rgba(255,255,255,0.5)"}}>Contact us at <strong style={{color:"rgba(255,255,255,0.8)"}}>rlaxxtv.com/support</strong> for further help</p>
  </div>
</div>)



const initNetworkInterval = () => {
  let networkInterval;
  clearInterval(networkInterval);
  networkInterval = setInterval(() => {
    axios.get(`${process.env.PUBLIC_URL}/ping.json?rand=${Math.random()}`).catch(error => {
      console.error("Slow network", error);
      ReactDOM.render(React.createElement(networkErrorPage), document.getElementById('root'));
      history.push('/error/408');
      clearInterval(networkInterval);
    })
  }, 5000);
};

initNetworkInterval()


const Wrapper = props => (
    <Provider store={store}>
        <PlayerContext.Provider value={PlayerRef}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <App {...props} />
            <Player ref={PlayerRef} />
            <div style={{display:"none"}}>
              <img src={progressFirst} alt="progress" />
              <img src={progressSecond} alt="progress" />
              <img src={progressThird} alt="progress" />
              <img src={secondSequence} alt="rlaxx-startup" />
              <img src={thirdSequence} alt="rlaxx-startup" />
              <img src={errorLogo} alt="rlaxx-error" />

            </div>
        </PlayerContext.Provider>
    </Provider>
);





ReactDOM.render(React.createElement(Wrapper), document.getElementById('root'));




reportWebVitals();
