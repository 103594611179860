import React from 'react';
import history, { clearHistory } from '../../storage/history';
import PlayerContext from '../../storage/contexts/PlayerContext';
import {
  getAssetChannelTitle,
  getAssetFromID,
  getAssetTitle,
  getNextContent,
  trackAssetEvent
} from '../../helpers/assets';
import Localised from '../../components/localised';
import './style.scss';
import { EVENTS, PAGES, trackPageView } from '../../helpers/tracking';
import { images } from '../../helpers';
import store, { playerActions } from '../../storage/store';
import { partnerIdentifiers } from '../../helpers/channels';
import CONSTANTS from '../../storage/redux/app-data/constants';
import { getChannelFromSRCat2 } from '../../storage/redux/app-data/actions';
import { getEPGIdFromChannel, getStreamableAsset } from '../../helpers/video';
import { getSRAssetSerieData } from '../../helpers/partners/sportradar';

class Autoplay extends React.Component {
  static identifier = 'autoplay';

  constructor(props) {
    super(props);
    this.state = {
      nextAsset: null,
      serieInfo: {},
      nextAssetSeconds: 10,
      // eslint-disable-next-line react/no-unused-state
      skeleton: true,
      stopTime: false
    };
  }

  componentDidMount() {
    // eslint-disable-next-line react/prop-types,react/destructuring-assignment
    const { state } = this.props.location;

    // eslint-disable-next-line react/destructuring-assignment
    if (this.context.current) {
      // eslint-disable-next-line react/destructuring-assignment
      this.context.current.mute();
    }

    if (state?.currentVideo) {
      const currentVideo = state?.currentVideo;
      getNextContent(currentVideo)
        .then(nextContent => {
          if (nextContent && nextContent.id) this.getNextAsset(nextContent);
          else this.stopWatching();
        })
        .catch(() => this.stopWatching());
    } else {
      this.goHome();
    }

    document.addEventListener('keydown', this.controlKey);

    this.trackPageLoad();
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.controlKey);

    clearInterval(this.nextAssetInterval);
  }

  getNextAsset = media => {
    const mediaId = media.externalMatchIds?.SportRadar?.id || media.id;
    let nextAsset = [];

    getAssetFromID(mediaId, partnerIdentifiers.SPORTRADAR)
      .then(resp => resp?.data?.data)
      .then(assetData => {
        nextAsset = { ...assetData, content: assetData.content[0]};
        getStreamableAsset(nextAsset).then(streamableAsset => {
          const serieInfo = getSRAssetSerieData(streamableAsset);
          nextAsset = streamableAsset;
          this.setState({ nextAsset, serieInfo }, () => {
            this.startTimer();
          });
        });
      })
      .catch(e => {
        console.error(e);
        history.push('/error/501');
      });
  };

  controlKey = () => {
    clearInterval(this.nextAssetInterval);
    this.setState({ nextAssetSeconds: '' });
  };

  trackPageLoad = () => {
    // eslint-disable-next-line react/prop-types
    const { currentVideo } = this.props;
    const channelTitle = getAssetChannelTitle(currentVideo);
    const videoTitle = getAssetTitle(currentVideo);
    trackPageView(PAGES.ON_DEMAND, 'player-autoplay', channelTitle, videoTitle);
  };

  goHome = () => {
    clearHistory();
    history.replace('/home');
  };

  playNow = () => {
    const { nextAsset } = this.state;
    store
      .dispatch(
        getChannelFromSRCat2(
          nextAsset.category2?.id || nextAsset.event?.category2?.id || nextAsset.content?.event?.category2?.id
        )
      )
      .then(channel => {
        const epgId = getEPGIdFromChannel(channel);
        store.dispatch({
          type: CONSTANTS.SET_CURRENT_CHANNEL,
          payload: { epgId, id: channel.id }
        });
      })
      .catch(e => {
        console.error(e);
      });

    store.dispatch(playerActions.setPlayingVideo(nextAsset)).then(() => {
      // eslint-disable-next-line react/destructuring-assignment
      this.context.current.setCurrentTime(0);

      history.replace('/player');
      trackAssetEvent(EVENTS.VIDEO.ACTIONS.PLAY_INITIAL, nextAsset);
    });
  };

  startTimer = () => {
    let { nextAssetSeconds } = this.state;
    this.nextAssetInterval = setInterval(() => {
      if (nextAssetSeconds > 0) {
        nextAssetSeconds -= 1;
        this.setState({ nextAssetSeconds }, () => {
          if (nextAssetSeconds === 0) {
            clearInterval(this.nextAssetInterval);
            this.playNow();
          }
        });
      }
    }, 1000);
  };

  stopWatching = () => {
    clearHistory();
    history.go(-2);
  };

  stopTimer = () => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ stopTime: true });
    clearInterval(this.nextAssetInterval);
  };

  imageLoaded = () => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ skeleton: false });
  };

  renderAutoplay() {
    const { nextAsset, serieInfo, stopTime, nextAssetSeconds } = this.state;

    let title = serieInfo?.id ? serieInfo.title : getAssetTitle(nextAsset);
    let serieItemTitle = serieInfo?.id && `S${serieInfo.season} E${serieInfo.episode}: ${getAssetTitle(nextAsset)}`;

    if (serieItemTitle?.length > 80) {
      serieItemTitle = `${serieItemTitle.slice(0, 80)}...`;
    }
    if (title.length > 82) {
      title = `${title.slice(0, 82)}...`;
    }

    return (
      <div className="autoplay-info">
        {title && (
          <>
            <div className="timer">
              {!stopTime ?
              <Localised node="player.hint.next_up" variables={{ seconds: nextAssetSeconds }} dangerouslySetInnerHTML />
              :
              <Localised node="player.hint.next_up_countdown_finished" dangerouslySetInnerHTML />}
            </div>
            <div className="asset-title">{title}</div>
            {serieItemTitle && <div className="asset-subtitle">{serieItemTitle}</div>}
          </>
        )}
        <div className="buttons controls-center">
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div key="play-now" id="play_now" className="button button-text play-button" onClick={this.playNow}>
            <Localised node="buttons.play_now" />
          </div>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div key="stop-watching" id="stop_watching" className="button button-text stop-button" onClick={this.stopWatching}>
            <Localised node="buttons.stop_watching" />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { nextAsset } = this.state;
    let backgroundImage;
    if (nextAsset?.id)
      backgroundImage =
        nextAsset.thumbnail ||
        images.getSRContentImage(
          nextAsset.content?.editorial?.image?.path || nextAsset.editorial?.image?.path,
          '1920/1080'
        );
    return (
      // eslint-disable-next-line no-restricted-globals
      <div id={Autoplay.identifier} onMouseMove={this.stopTimer} className="view view--translucent animated animated--slide">
        <div className="viewWrapper">
          {nextAsset && (
            <div className="content">
              {backgroundImage && <img alt="" src={backgroundImage} className="background-placeholder" />}
              <div className="fade" />
              {this.renderAutoplay()}
            </div>
          )}
        </div>
      </div>
    );
  }
}

Autoplay.contextType = PlayerContext;

export default Autoplay;
