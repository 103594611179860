/* eslint-disable */
import qs from 'query-string';
import axios from 'axios';
import assets from '../../services/apis/sportradar_api';
import { appendObjectToAsset, standardizeStreamObject, standardizeVastObject } from '../video';
import store from '../../storage/store';
import { images } from '../index';
import appProperties from '../../storage/app-properties';
import {getPartnerIdentifierFromAsset, partnerIdentifiers} from "../channels";

const getSRAssetContent = asset => {
  if (!asset) return null;
  if (asset.teaserType === 'content') return asset;
  if (!asset.content) return null;
  let content = asset.content;
  if (content[0]) content = content[0];

  return content;
};

export const getSRAssetTitle = asset => {
  const content = getSRAssetContent(asset);
  if (
    content &&
    content.editorial &&
    content.editorial.translations &&
    content.editorial.translations.en &&
    content.editorial.translations.en.title
  ) {
    return content.editorial.translations.en.title;
  }
  return '';
};

export const getSRAdCategory = url => {
  const { query } = qs.parseUrl(url);
  return query?.iu?.split('/')[3];
};

export const getSRAssetDescription = asset => {
  const content = getSRAssetContent(asset);
  if (
    content &&
    content.editorial &&
    content.editorial.translations &&
    content.editorial.translations.en &&
    content.editorial.translations.en.description
  ) {
    return content.editorial.translations.en.description;
  }
  return '';
};

export const getSRAssetChannelTitle = asset => {
  const category = getSRAssetCategory(asset);
  return category?.name || '';
};

export const getSRAssetContentPartnerName = asset => {
  const category = getSRAssetContentPartner(asset);
  return category?.name || '';
};

export const getSRAssetCategory = asset => {
  const content = getSRAssetContent(asset);
  if (content && content.event && content.event.category2) {
    return content.event.category2;
  }
  if (asset && asset.category2) {
    return asset.category2;
  }
  return null;
};

export const getSRAssetContentPartner = asset => {
  const content = getSRAssetContent(asset);
  if (content && content.event && content.event.category1) {
    return content.event.category1;
  }
  if (asset && asset.category1) {
    return asset.category1;
  }
  return null;
};

export const getSRThumbnail = (asset, manipulation = 'original') => {
  const content = getSRAssetContent(asset);
  return images.getSRContentImage(content?.editorial?.image?.path, manipulation);
};

export const getSRAssetDuration = asset => {
  const content = getSRAssetContent(asset);
  return content?.duration || content?.playtime;
};

export const getSRAssetSerieTitle = asset => {
  const content = getSRAssetContent(asset);
  if (content && content.event && content.event.category3) {
    return content.event.category3.name;
  }
  if (asset && asset.category3) {
    return asset.category3.name;
  } else if (asset && asset.event && asset.event.category3) {
    return asset.event.category3.name;
  }
  return null;
};

export const parseSRTimeToGermanyZoneDate = date => {
  const parsedDate = new Date(new Date(date).toLocaleString('en-US', { timeZone: 'Europe/Berlin' }));

  /* const parsedDate = new Date(
      new Date(date).toLocaleString('en-US', {
        timeZone: 'Europe/Berlin',
        weekday: 'short',
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short'
      })
  ); */
  return parsedDate.toString() === 'Invalid Date' ? new Date(date) : parsedDate;
};
export const parseToUTCDate = date => {
  const parsedDate = new Date(new Date(date).toLocaleString('en-US', { timeZone: 'Europe/London' }));
  return parsedDate.toString() === 'Invalid Date' ? new Date(date) : parsedDate;
};
export const parseDate = (date, asset) => {
  if (getPartnerIdentifierFromAsset(asset) === partnerIdentifiers.WURL) {
    return parseToUTCDate(date);
  }
  return parseSRTimeToGermanyZoneDate(date);
};

export const getSRAssetSerieData = asset => {
  const currentSerieData = {};
  currentSerieData.id = getSRAssetMetadata(asset, 'seriesId');
  currentSerieData.season = getSRAssetMetadata(asset, 'seriesSeasonNumber');
  currentSerieData.episode = getSRAssetMetadata(asset, 'seriesEpisodeNumber');
  currentSerieData.title = getSRAssetSerieTitle(asset);
  return currentSerieData;
};

export const getSRAssetMetadata = (asset, metaId) => {
  const content = getSRAssetContent(asset);
  return (
    content?.clientMetadata?.[metaId] ||
    content?.clientMetadata?.find(e => e.type?.name === metaId)?.name ||
    content?.event?.clientMetadata?.[metaId] ||
    content?.event?.clientMetadata?.find(e => e.type?.name === metaId)?.name ||
    asset?.clientMetadata?.[metaId] ||
    asset?.clientMetadata?.find(e => e.type?.name === metaId)?.name ||
    undefined
  );
};



export const getMultipleSerieData = seriesIds =>
  axios
    .post(`${process.env.REACT_APP_RLAXX_BACKEND}api/v3/series?api_token=${appProperties.portfolioKey}`, { seriesIds })
    .then(resp => resp?.data)
    .then(resp => {
      const seriesInfo = [];
      for (const item in resp) {
        // eslint-disable-next-line no-prototype-builtins
        if (resp.hasOwnProperty(item)) {
          const serieInfo = {};
          serieInfo.serieTitle = resp[item]?.seriesData?.title;
          serieInfo.description = resp[item]?.seriesData?.description;
          serieInfo.id = resp[item]?.seriesData?.id;
          serieInfo.seasons = resp[item]?.seasons?.sort();
          serieInfo.thumbnail = resp[item]?.thumbnail?.image_path;
          serieInfo.episodes_counts = resp[item]?.seasons_episode_count;
          seriesInfo.push(serieInfo);
        }
      }
      return seriesInfo;
    });





export const getSerieData = serieId =>
  axios
    .get(`${process.env.REACT_APP_RLAXX_BACKEND}api/series/${serieId}?api_token=${appProperties.portfolioKey}`)
    .then(resp => resp?.data)
    .then(resp => {
      const serieInfo = {};
      serieInfo.serieTitle = resp?.seriesData?.title;
      serieInfo.description = resp?.seriesData?.description;
      serieInfo.id = resp?.seriesData?.id;
      serieInfo.seasons = resp?.seasons?.sort();
      serieInfo.thumbnail = resp?.thumbnail;
      return serieInfo;
    })
    .catch(e => {
      console.error(`Error getting serie data :: ${e}`);
      return null;
    });

export const getSRSerieEpisode = (serieId, season, episode = 0) => {
  axios
    .get(
      `${process.env.REACT_APP_RLAXX_BACKEND}api/series/${serieId}/season/${season}/episodes?api_token=${appProperties.portfolioKey}`
    )
    .then(resp => resp?.data)
    .then(data => data.filter(elm => elm?.episodeData?.episode_number === episode))
    .catch(e => {
      console.error(`Error getting serie data :: ${e}`);
      return null;
    });
};

export const getSRWatermark = (asset, channels = store.getState().app.liveChannels) => {
  const category = getSRAssetCategory(asset);
  if (!category) return null;
  for (let i = 0; i < channels.length; i++) {
    if(channels[i]){
      const { sr_cat_2 } = channels[i];
      if (  Array.isArray(sr_cat_2)) {
        const found = sr_cat_2.find(id => id == category.id);
        if (found) return channels[i].watermark;
      }
      // eslint-disable-next-line camelcase
      if (sr_cat_2 == category.id) {
        return channels[i].watermark;
      }
    }

    }

  return null;
};

export const getSREPGStartTime = asset => {
  if (asset && asset.start) {
    return asset.start;
  }
  return '';
};

export function getSRStream(asset) {
  let streamableAsset = asset;
  const content = getSRAssetContent(asset);
  return assets
    .getPlayerSettings(content?.id)
    .then(response => {
      // todo: remove this if possible and prepare the proper channel identification url manually
      if (
        response &&
        response.data &&
        response.data.advertisement &&
        response.data.advertisement.enabled &&
        response.data.advertisement.prerolls.length > 0 &&
        response.data.advertisement.prerolls[0].url
      ) {
        streamableAsset = appendObjectToAsset(streamableAsset, { playerSettings: response.data });
        streamableAsset = appendObjectToAsset(
          streamableAsset,
          standardizeVastObject(response.data.advertisement.prerolls[0].url)
        );
      }
    })
    .catch(response => {
      console.error('[SportRadar] There was an error retrieving the player settings.', response);
    })
    .then(() => assets.getRelatedElements(content.id))
    .then(response => {
      streamableAsset = appendObjectToAsset(streamableAsset, { related: response?.data?.data });
    })
    .catch(response => {
      console.error('[SportRadar] There was an error retrieving the related elements.', response);
    })
    .then(() => assets.getStreamURL(content.id))
    .then(response => standardizeStreamObject(response.data.data.stream, 'application/x-mpegurl'))
    .then(streamObject => {
      streamableAsset = appendObjectToAsset(streamableAsset, streamObject);
      return streamableAsset;
    });
}
