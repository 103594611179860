/* eslint-disable react/jsx-props-no-spreading */
import React, {Component,Suspense} from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import DeviceDetector from "device-detector-js";
import Spinner from "../spinner";
import {mobileSize} from "../../helpers/measurements";


const PlayerVast=React.lazy(()=>import("./Player"))
const HlsJsPlayerVast = React.lazy(()=>import("./HlsPlayer")) ;


const deviceDetector=new DeviceDetector();
const { userAgent } = window.navigator;
const device = deviceDetector.parse(userAgent);





class PlayerMounter extends Component {
  static identifier = 'playerMounter';


  // eslint-disable-next-line react/static-property-placement
  static propTypes = {

    mimeType: PropTypes.string,

    source: PropTypes.string,
    drmConfig: PropTypes.shape(),
    adsEnabled: PropTypes.bool,
    vastUrl: PropTypes.string,
    prerolls: PropTypes.number,
    midrolls: PropTypes.number,
    autoPlay: PropTypes.bool,
    style: PropTypes.shape({
      width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      height: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    }),
    onAbort: PropTypes.func,
    onCanPlay: PropTypes.func,
    onCanPlayThrough: PropTypes.func,
    onDurationChange: PropTypes.func,
    onEmptied: PropTypes.func,
    onEnded: PropTypes.func,
    onError: PropTypes.func,
    onLoadedData: PropTypes.func,
    onLoadedMetadata: PropTypes.func,
    onLoadStart: PropTypes.func,
    onPause: PropTypes.func,
    onPlay: PropTypes.func,
    onPlaying: PropTypes.func,
    onProgress: PropTypes.func,
    onRateChange: PropTypes.func,
    onSeeked: PropTypes.func,
    onSeeking: PropTypes.func,
    onStalled: PropTypes.func,
    onSuspend: PropTypes.func,
    onTimeUpdate: PropTypes.func,
    onVolumeChange: PropTypes.func,
    onWaiting: PropTypes.func,
    onAdStart: PropTypes.func,
    onAdEnded: PropTypes.func,
    onAdRemainingTimeChange: PropTypes.func
  };

  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    source: undefined,
    mimeType: 'video/mp4',
    drmConfig: undefined,
    adsEnabled: true,
    vastUrl: undefined,
    prerolls: 1,
    midrolls: 1,
    autoPlay: true,
    style: {
      width: 375,
      height: 210
    },
    onAbort: undefined,
    onCanPlay: undefined,
    onCanPlayThrough: undefined,
    onDurationChange: undefined,
    onEmptied: undefined,
    onEnded: undefined,
    onError: undefined,
    onLoadedData: undefined,
    onLoadedMetadata: undefined,
    onLoadStart: undefined,
    onPause: undefined,
    onPlay: undefined,
    onPlaying: undefined,
    onProgress: undefined,
    onRateChange: undefined,
    onSeeked: undefined,
    onSeeking: undefined,
    onStalled: undefined,
    onSuspend: undefined,
    onTimeUpdate: undefined,
    onVolumeChange: undefined,
    onWaiting: undefined,
    onAdStart: undefined,
    onAdEnded: undefined,
    onAdRemainingTimeChange: undefined
  };

  constructor(props) {
    super(props);
    this.refPlayer = React.createRef();

    this.playerEvents = {
      onAbort: props.onAbort,
      onCanPlay: props.onCanPlay,
      onCanPlayThrough: props.onCanPlayThrough,
      onDurationChange: props.onDurationChange,
      onEmptied: props.onEmptied,
      onEnded: props.onEnded,
      onError: props.onError,
      onLoadedData: props.onLoadedData,
      onLoadedMetadata: props.onLoadedMetadata,
      onLoadStart: props.onLoadStart,
      onPause: props.onPause,
      onPlay: props.onPlay,
      onPlaying: props.onPlaying,
      onProgress: props.onProgress,
      onRateChange: props.onRateChange,
      onSeeked: props.onSeeked,
      onSeeking: props.onSeeking,
      onStalled: props.onStalled,
      onSuspend: props.onSuspend,
      onTimeUpdate: props.onTimeUpdate,
      onVolumeChange: props.onVolumeChange,
      onWaiting: props.onWaiting
    };
    this.adEvents = {
      onAdStart: props.onAdStart,
      onAdEnded: props.onAdEnded,
      onAdRemainingTimeChange: props.onAdRemainingTimeChange
    };
  }

  componentDidMount() {


  }

  componentDidUpdate() {
    if (this.startTime) {
      this.setStartTime(this.startTime);
      this.startTime = undefined;
    }

  }

  componentWillUnmount() {
  }

  get isAd() {
    if (this.refPlayer.current) {
      return this.refPlayer.current.isAd;
    }
    return true;
  }

  get isPaused() {
    if (this.refPlayer.current) {
      return this.refPlayer.current.isPaused;
    }
    return true;
  }

  get readyState() {
    if (this.refPlayer.current) return this.refPlayer.current.readyState;
    return 0;
  }

  getDuration = () => {
    if (this.refPlayer.current) return this.refPlayer.current.duration;
    return undefined;
  };

  getRemainingTime = () => {
    if (this.refPlayer.current) return this.refPlayer.current.remainingTime;
    return undefined;
  };

  getCurrentTime = () => {
    if (this.refPlayer.current) return this.refPlayer.current.currentTime;
    return 0;
  };

  setCurrentTime = newVideoPosition => {
    if (this.refPlayer.current) this.refPlayer.current.currentTime = newVideoPosition;
  };

  setStartTime = startTimePosition => {
    if (this.refPlayer.current) {
      this.refPlayer.current.startTime = startTimePosition;
      this.startTime = undefined;
    } else {
      this.startTime = startTimePosition;
      this.startTimeInterval = setInterval(() => {
        if (this.refPlayer.current) {
          this.refPlayer.current.startTime = startTimePosition;
          this.startTime = undefined;
          clearInterval(this.startTimeInterval);
        }
      }, 300);
    }
  };

  play = () => {
    if (this.refPlayer.current) this.refPlayer.current.play();
  };

  pause = () => {
    if (this.refPlayer.current) this.refPlayer.current.pause();
  };

  rewind = (timeToChange = 15) => {
    if (this.refPlayer.current) this.refPlayer.current.rewind(timeToChange);
  };

  forward = (timeToChange = 15) => {
    if (this.refPlayer.current) this.refPlayer.current.forward(timeToChange);
  };

  mute = () => {
    if (this.refPlayer.current) this.refPlayer.current.mute();
  };

  unmute = () => {
    if (this.refPlayer.current) this.refPlayer.current.unmute();
  };

  // eslint-disable-next-line consistent-return
  getVolume=()=>{
    if (this.refPlayer.current) return this.refPlayer.current.getVolume();
  }

  setVolume=(value)=>{
    if (this.refPlayer.current) {
      this.refPlayer.current.unmute();
      this.refPlayer.current.setVolume(value);
    }
  }



  // eslint-disable-next-line consistent-return
  isMuted=()=>{
    if(this.refPlayer.current) return this.refPlayer.current.isMuted();
  }

  toggleVideo = () => {
    if (this.isPaused && this.readyState > 0) this.play();
    else this.pause();
  };

  fullScreen(){
    if (this.refPlayer.current) {
      this.refPlayer.current.fullScreen();
    }
  }

  renderPlayer() {
    const {
      source,
      mimeType,
      adsEnabled,
      autoPlay,
      style,
      vastUrl,
      prerolls,
      midrolls,
      drmConfig,
      // eslint-disable-next-line react/prop-types
      screenSize,
      deviceType
    } = this.props;


    if(device?.client.name.includes("Chrome")
      && ((screenSize>mobileSize && deviceType==="desktop")||(device?.device.brand.indexOf("Apple")===-1 &&  deviceType!=="desktop"))) return (
      <HlsJsPlayerVast
        ref={this.refPlayer}
        source={source}
        adsEnabled={adsEnabled}
        mimeType={mimeType}
        vastUrl={vastUrl}
        autoPlay={autoPlay}
        style={style}
        prerolls={prerolls}
        midrolls={midrolls}
        {...this.playerEvents}
        {...this.adEvents}
        screenSize={screenSize}
        deviceType={deviceType}
      />
    )

    return (
      <PlayerVast
        ref={this.refPlayer}
        source={source}
        adsEnabled={adsEnabled}
        mimeType={mimeType}
        vastUrl={vastUrl}
        autoPlay={autoPlay}
        drmConfig={drmConfig}
        style={style}
        prerolls={prerolls}
        midrolls={midrolls}
        {...this.playerEvents}
        {...this.adEvents}
        screenSize={screenSize}
        deviceType={deviceType}
      />
    );

  }

  render() {
    return (
      <div className="wrapper-player">
        <Suspense fallback={<Spinner/>}> {this.renderPlayer()}</Suspense>
      </div>
    );
  }
}

export default PlayerMounter;
