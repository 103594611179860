/* eslint-disable */

// export function isDevelopment() {
//   return process.env.REACT_APP_BUILD_MODE === 'development';
// }
//
// export function isPreProduction() {
//   return process.env.REACT_APP_BUILD_MODE === 'preproduction';
// }
//
// export function isProduction() {
//   return process.env.REACT_APP_BUILD_MODE === 'production';
// }

export function jsonsAreEqual(firstJson, secondJson) {
  const firstStringify = JSON.stringify(firstJson);
  const secondStringify = JSON.stringify(secondJson);
  return firstStringify === secondStringify;
}

export function deviceLanguage() {
  return navigator.language.substr(0, 2) || 'en';
}

/**
 * Returns a text substring divided by full words taking into a maximum length or the end of the string
 * If the text is longer than the maxLength specified it will come with "..." on its end.
 *
 * @param text string to reduce
 * @param maxLength maximum number of characters that the text can have
 * @returns {*} shortened or original text depending on its length
 */
export const reduceLongText = (text, maxLength) => {
  if (text.length <= maxLength) return text;
  const regexSplitter = new RegExp(`\\S[\\s\\S]{0,${maxLength - 2}}\\S(?=\\s|$)`, 'g');
  const splitString = regexSplitter.exec(text);
  if (splitString !== null) return `${splitString}...`;
  return text;
};

/**
 * Check if the string is a valid email or not
 *
 * Example:
 *  "test@foo.com" is a valid email
 *  "footest.com" is not a valid email
 *
 * @param string string to test
 * @returns {boolean}
 */
export const isValidEmail = string => {
  const validator = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return validator.test(string);
};

export const isValidHttpUrl = url => {
  try {
    const { protocol } = new URL(url);
    return protocol === 'https:' || protocol === 'http:';
  } catch (e) {
    return false;
  }
};

export const isValidPass = string => string.length >= 6;

export const getDeviceName = (deviceBrand, deviceModel) => `${deviceBrand}-${deviceModel}`;

/**
 * Get the styled new position of an element using transform or absolute position depending on the settings of the project.
 * @param direction
 * @param movement
 * @returns {{direction: movement}|{transform: string}|{}}
 */
export const getElementNewPosition = (direction, movement) => {
  if (process.env.REACT_APP_ANIMATION_TYPE === 'transform') {
    let tempString = movement;

    if (!isNaN(movement)) {
      tempString += 'px';
    }

    if (direction === 'left') {
      return { transform: `translateX(${tempString})` };
    } else if (direction === 'top') {
      return { transform: `translateY(${tempString})` };
    }

    if (tempString[0] !== '-') {
      tempString = `-${tempString}`;
    } else {
      tempString = tempString.slice(1);
    }

    if (direction === 'right') {
      return { transform: `translateX(${tempString})` };
    } else if (direction === 'bottom') {
      return { transform: `translateY(${tempString})` };
    }
  } else {
    return { [direction]: movement };
  }

  return {};
};

/**
 * Convert the requested value string to milliseconds. The string have to be of "5000min"
 *
 * Example:
 *  "25ms" => 25
 *  "50seconds" => 50000
 *  "5min" => 300000
 *
 * @param value
 * @returns {null|number}
 */
export const toMilliseconds = value => {
  switch (typeof value) {
    case 'number':
      return Number(value);
    case 'string': {
      const matches = /^(\d+)(ms|millisecond|milliseconds|s|second|seconds|m|min|minute|minutes)$/.exec(value);
      if (matches) {
        const matchedValue = matches[1];
        const unit = matches[2];
        return (
          {
            ms: 1,
            millisecond: 1,
            milliseconds: 1,
            s: 1000,
            second: 1000,
            seconds: 1000,
            m: 60000,
            min: 60000,
            minute: 60000,
            minutes: 60000
          }[unit] * matchedValue
        );
      }
      return null;
    }
    default:
      return null;
  }
};

/**
 * Simulate a key event.
 * @param {Number} keyCode The keyCode of the key to simulate
 * @param {String} type (optional) The type of event : keydown, keyup or keypress. The default is down
 * @param {Object} modifiers (optional) An object which contains modifiers keys { ctrlKey: true, altKey: false, ...}
 */
export const simulateKey = (keyCode, type = 'keydown', modifiers = {}) => {
  const evtName = typeof type === 'string' && type.indexOf('key') !== -1 ? `${type}` : 'keydown';
  // eslint-disable-next-line no-var
  const modifier = typeof modifiers === 'object' ? modifiers : {};

  const event = document.createEvent('HTMLEvents');
  event.initEvent(evtName, true, false);
  event.keyCode = keyCode;

  for (const i in modifier) {
    if (Object.prototype.hasOwnProperty.call(modifier, i)) {
      event[i] = modifier[i];
    }
  }

  document.dispatchEvent(event);
};



/**
 * Adds 0 if smaller than 10
 * @param {Number} number The number to edit
 * @returns {string}
 */
export const pad2 = number => (number < 10 ? '0' : '') + number;

/**
 * @param {String} text
 * @param {Number} originalFontSize
 * @returns {{}|{fontSize: string}}
 */
export const fontSizeChanger = (text, originalFontSize) => {
  if (!text) return {};
  let fontSize = `${originalFontSize}rem`;
  if (parseInt(text.length, 10) >= 57) {
    fontSize = `${originalFontSize * 0.63}rem`;
  } else if (parseInt(text.length, 10) >= 52) {
    fontSize = `${originalFontSize * 0.69}rem`;
  } else if (parseInt(text.length, 10) >= 47) {
    fontSize = `${originalFontSize * 0.73}rem`;
  } else if (parseInt(text.length, 10) >= 35) {
    fontSize = `${originalFontSize * 0.8}rem`;
  }
  return { fontSize };
};

export const isOpaque = process.env.REACT_APP_DEVICE_SCRIPTS === 'arcelik' || true;
