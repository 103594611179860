/* eslint-disable react/destructuring-assignment */
import React, {Suspense} from "react";
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import history from "../../storage/history";
import Header from "../../components/header";
import PlayerContext from "../../storage/contexts/PlayerContext";
import './style.scss'
import ContentDetail from "../content-detail/single-item";
import { PAGES, trackPageView } from '../../helpers/tracking'
import Spinner from "../../components/spinner";
import {changeHeaderTransition, isPortrait, mobileSize} from "../../helpers/measurements";
import {getAssetFromEPG} from "../../helpers/channels";

const ProgramGuide=React.lazy(()=>import("../../components/program-guide"))


class ProgramGuideView extends React.Component {
  constructor(props) {
    super(props);
    this.refContentDetail = React.createRef();
    this.refProgramGuideView = React.createRef();
    this.selectItemToShow = this.selectItemToShow.bind(this);
    this.closeItem = this.closeItem.bind(this);
    this.state = {
      selectedItem: { assetType: undefined, partnerId: undefined, assetId: undefined },
      loaded: false,
    };
  }

  componentDidMount() {
    const { current } = this.context;
    const { deviceType, assetFromLandscape } = this.props;

    if (deviceType === "desktop") {
      this.getAssetIdFromPathname().catch(() => this.closeItem());
    }
    this.loadTo = setTimeout(() => {
      this.setState({ loaded: true });
    }, 3000);
    if (deviceType === "desktop" && current && !current.isMuted()) current.mute();

    if (assetFromLandscape && deviceType !== "desktop" && isPortrait() === "portrait") {
      this.selectItemToShow(assetFromLandscape);
    }
    window.addEventListener("resize", this.resizingWindowFromEPG);

    trackPageView(PAGES.PROGRAM_GUIDE);
  }

  componentDidUpdate(prevProps) {

    const { assetFromLandscape, deviceType } = this.props;
    if (
      deviceType === "desktop" &&
      ((!prevProps?.match?.params?.assetId && this.props?.match?.params?.assetId) ||
        (prevProps?.match?.params?.assetId &&
          prevProps?.match?.params?.assetId !== this.props.match.params.assetId))
    ) {
      this.getAssetIdFromPathname().catch(() => this.closeItem());
    }
    if (
      (!prevProps.assetFromLandscape && assetFromLandscape) ||
      prevProps.assetFromLandscape !== assetFromLandscape
    ) {
      if (deviceType !== "desktop" && isPortrait() === "portrait") {
        this.selectItemToShow(assetFromLandscape);
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizingWindowFromEPG);
  }

  getAssetIdFromPathname() {
    const { match } = this.props;
    const { selectedItem } = this.state;
    if (Number(match?.params?.assetId) === Number(selectedItem.assetId)) Promise.resolve();
    return new Promise((resolve) => {
      this.selectedItemTo = setTimeout(() => {
        const asset = getAssetFromEPG(Number(match?.params?.assetId), Number(match?.params?.epgId));
        if (asset?.id) {
          const assetToShow = {
            ...asset,
            assetType: "epg",
            assetId: asset.id,
            partnerId: match?.params?.epgId,
          };
          this.selectItemToShow(assetToShow);
          this.forceUpdate(resolve);
        }
      }, 150);
    });
  }

  resizingWindowFromEPG = () => {
    if (window.innerWidth <= 1024) {
      history.push("/home");
    }
  };

  selectItemToShow(asset) {
    const { carryAssetFromLandscapeToPortrait, deviceType } = this.props;
    this.setState({ selectedItem: asset }, () => {
      if (deviceType !== "desktop") {
        if (typeof carryAssetFromLandscapeToPortrait === "function") {
          carryAssetFromLandscapeToPortrait(asset);
        }
      }
      const { selectedItem } = this.state;
      if (selectedItem?.partnerId && selectedItem?.assetId) {
        if (history.location.pathname.indexOf("epg") === -1) {
          history.push(
            `${history.location.pathname}/epg/${selectedItem?.partnerId}/${selectedItem?.assetId}`
          );
        }
      }
    });
  }

  closeItem() {
    const { carryAssetFromLandscapeToPortrait, deviceType } = this.props;
    this.setState(
      { selectedItem: { assetType: undefined, partnerId: undefined, assetId: undefined } },
      () => {
        if (typeof carryAssetFromLandscapeToPortrait === "function") {
          carryAssetFromLandscapeToPortrait(undefined);
        }

        if (deviceType !== "desktop") {
          // eslint-disable-next-line import/no-named-as-default-member
          history.push("/home");
        } else {
          history.push("/program-guide");
        }
      }
    );
  }

  renderSpinner = () => {
    const { loaded } = this.state;
    return (
      <div className={`spinner-wrapper ${loaded && "loaded"}`}>
        <Spinner />
      </div>
    );
  };

  render() {
    const { screenSize, deviceType, changeChannel, currentFocusedChannel } = this.props;
    const { selectedItem, loaded } = this.state;
    return (
      <div
        ref={this.refProgramGuideView}
        id="programGuideWrapper"
        className={`program-guide-view ${
          deviceType !== "desktop" || screenSize < mobileSize ? "mobile" : "desktop"
        }
           ${deviceType === "tablet" && "tablet "}  ${loaded && "loaded"}
           ${deviceType !== "desktop" && selectedItem?.assetId && "detailed"}
           `}
      >
        {deviceType === "desktop" && (
          <div className="program-guide-header">
            <Header screenSize={screenSize} deviceType={deviceType} />
          </div>
        )}
        <Suspense fallback={deviceType === "desktop" ? this.renderSpinner() : <Spinner />}>
          <ProgramGuide
            currentFocusedChannel={currentFocusedChannel}
            selectItem={this.selectItemToShow}
            changeChannel={changeChannel}
            closeItem={this.closeItem}
          />
        </Suspense>
        {selectedItem?.assetType && selectedItem?.assetId && selectedItem?.partnerId && (
          <div className="detailed-item-wrapper">
            <div
              ref={this.refContentDetail}
              onScroll={() =>
                changeHeaderTransition(
                  this.refContentDetail,
                  "detail-header",
                  deviceType === "desktop"
                    ? "linear-gradient(180deg, #0A1320 0%, rgba(10, 19, 32, 0.75) 59.9%, rgba(10, 19, 32, 0) 100%)"
                    : "#0a2244"
                )
              }
              className={`selected-item-detail-page  ${deviceType}`}
            >
              <ContentDetail
                assetType={selectedItem?.assetType}
                selectItem={this.selectItemToShow}
                assetId={selectedItem?.assetId}
                partnerId={selectedItem?.partnerId}
                closeItem={this.closeItem}
                pageView={PAGES.PROGRAM_GUIDE}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

ProgramGuideView.propTypes={
  screenSize:PropTypes.number.isRequired,
  changeChannel:PropTypes.func
}

ProgramGuideView.defaultProps={
  changeChannel:undefined
}

ProgramGuideView.contextType=PlayerContext

const mapStateToProps = state => ({
  screenSize:state.app.screenSize,
  deviceType:state.app.deviceType,
  epg:state.app.epg
});



export default connect(mapStateToProps, null, null, )(ProgramGuideView);

