/* eslint-disable import/prefer-default-export */

import { isWithinInterval } from 'date-fns';
// eslint-disable-next-line import/no-cycle
import {
  getCurrentDate,
  getPartnerIdentifierFromAsset,
  getPartnerIdentifierFromChannel,
  partnerIdentifiers
} from './channels';
// eslint-disable-next-line import/no-cycle
import { getSRStream, parseDate, parseSRTimeToGermanyZoneDate } from './partners/sportradar';
import { getVevoStream } from './partners/vevo';
// eslint-disable-next-line import/no-cycle
import { getPantaflixStream } from './partners/pantaflix';
// eslint-disable-next-line import/no-cycle
import { getWurlStream } from './partners/wurl';

export const isGoingToPlay = (video, clockTime = getCurrentDate()) => {
  if (!video) return false;
  const start = parseDate(video.start, video);
  const end = parseDate(video.end, video);

  if (getPartnerIdentifierFromAsset(video) === partnerIdentifiers.WURL)
    return isWithinInterval(parseDate(clockTime, video), { start, end });
  return isWithinInterval(clockTime, { start, end });
};

export const isCurrentlyPlaying = video => isGoingToPlay(video, getCurrentDate());

export const getPlayingMediaPositionFromChannel = (epg, epgId, clockTime = getCurrentDate()) => {
  if (epg[epgId] && epg[epgId].assets) {
    return epg[epgId].assets.findIndex(asset => isCurrentlyPlaying(asset, clockTime));
  }
  return -1;
};

export const getPlayingMediaPositionFromEPGGroups = (epgGroup, clockTime) => {
  if (epgGroup && epgGroup.assets) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < epgGroup.assets.length; i++) {
      const asset = epgGroup.assets[i];
      if (isCurrentlyPlaying(asset, clockTime)) {
        return i;
      }
    }
  }
  return -1;
};

/**
 * Retrieve playing element for a specified time
 * @param epg Array with all the EPG Items
 * @param epgId Channel ID to look for
 * @param clockTime Time in which the element shall be playing (Default: current time)
 * @param home Specific parameter to return epg group info and show on home info-component
 * @returns {*}
 */
export const getPlayingVideoFromChannel = (epg, epgId, clockTime, home = false) =>
  new Promise((resolve, reject) => {
    if (epg[epgId] && epg[epgId].assets) {
      // console.info(`Current EPG Data for channel ${epgId} >>`, epg[epgId]);
      const mediaPos = getPlayingMediaPositionFromChannel(epg, epgId, clockTime);
      if (mediaPos !== -1) {
        if (epg[epgId].assets[mediaPos]?.assets && !home) {
          const groupIndex = getPlayingMediaPositionFromEPGGroups(epg[epgId].assets[mediaPos]);
          resolve(epg[epgId].assets[mediaPos].assets[groupIndex]);
        } else {
          resolve(epg[epgId].assets[mediaPos]);
        }
      } else {
        reject(
          `No element playing for channel ${epgId} (${epg[epgId]?.displayName}) at ${(
            clockTime || getCurrentDate()
          ).toLocaleString()}.`
        );
      }
    } else {
      reject(`Channel ${epgId} not found.`);
    }
  });

export const getStreamVideoFromChannel = (epg, epgId) =>
  // eslint-disable-next-line no-use-before-define
  getPlayingVideoFromChannel(epg, epgId).then(asset => getStreamableAsset(asset));

export const appendObjectToAsset = (asset, object) => ({ ...asset, ...object});

export const standardizeStreamObject = (source, mimeType) => ({ stream: { source, mimeType } });

export const standardizeVastObject = url => {
  // eslint-disable-next-line max-len
  // https://pubads.g.doubleclick.net/gampad/ads?iu=/33100160/RLAXXTV/nyx_nyxchannel&sz=640x360&description_url=[PAGE_URL]&env=vp&gdfp_req=1&output=vast&unviewed_position_start=1&cust_params=videoposition%3Dpreroll1&url=&correlator=
  const vastUrl = url
    .replace('[PAGE_URL]', '%DESCRIPTION_URL%')
    .replace('preroll1', '%AD_TYPE%')
    .replace('&url=', '&url=%URL%')
    .replace('&correlator=', '&correlator=%CORRELATOR%');
  return { vastUrl };
};

export const getCurrentExoManifest = () =>
  new Promise((resolve, reject) => {
    if (!window.ExoPlayer) reject('not android device');

    const listener = manifestEvent => {
      document.removeEventListener('manifestavailable', listener);
      resolve(manifestEvent);
    };
    document.addEventListener('manifestavailable', listener);
    try {
      window.ExoPlayer.getCurrentManifest();
    } catch (e) {
      reject('error getting current exoplayer manifest');
    }
  });

export const getCurrentExoAvailableCaptions = () =>
  new Promise((resolve, reject) => {
    if (!window.ExoPlayer) reject('not android device');

    const listener = captionsEvent => {
      document.removeEventListener('captionsavailable', listener);
      resolve(captionsEvent.detail);
    };
    document.addEventListener('captionsavailable', listener);
    try {
      getCurrentExoManifest()
        .then(() => {
          window.ExoPlayer.availableCaptions();
        })
        .catch(() => reject('error getting current exoplayer manifest'));
    } catch (e) {
      reject('error getting current exoplayer captions');
    }
  });

export function getStreamableAsset(asset) {
  const partnerIdentifier = getPartnerIdentifierFromAsset(asset);
  switch (partnerIdentifier) {
    case partnerIdentifiers.VEVO:
      return getVevoStream(asset);
    case partnerIdentifiers.PANTAFLIX:
      return getPantaflixStream(asset);
    case partnerIdentifiers.WURL:
      return getWurlStream(asset);
    case partnerIdentifiers.SPORTRADAR:
    default:
      return getSRStream(asset);
  }
}

export function getEPGIdFromChannel(channel) {
  const partnerIdentifier = getPartnerIdentifierFromChannel(channel);
  switch (partnerIdentifier) {
    case partnerIdentifiers.VEVO:
      return channel.externalData.Vevo.apiData.epg.slug;
    case partnerIdentifiers.PANTAFLIX:
      return channel.id;
    case partnerIdentifiers.SPORTRADAR:
      return channel.externalData.SportRadar.apiData.epg.channel_guid;
    case partnerIdentifiers.WURL:
      return channel.externalData.WURL.apiData.epg.channel_guid;
    default:
      return -1;
  }
}

export function getCategoryIDFromChannel(channel) {
  if (
    channel &&
    channel.externalData &&
    channel.externalData.SportRadar &&
    channel.externalData.SportRadar.apiData &&
    channel.externalData.SportRadar.apiData.categories &&
    channel.externalData.SportRadar.apiData.categories.length > 0
  ) {
    return channel.externalData.SportRadar.apiData.categories[0].category_id;
  }
  return -1;
}
export function liveVideoTotalProgress(startDate) {
  const parsedStart = parseSRTimeToGermanyZoneDate(startDate);
  const time = getCurrentDate();

  const current = time.getTime() / 1000;
  return current - parsedStart.getTime() / 1000;
}

/**
 *
 * @param {String} text
 * @returns {String} className
 */
export const getTitleSizeClassname = (text) => {
  if (!text) return undefined;
  const textLength = parseInt(text.length, 10);
  let className = 'title--size-xxl';
  if (textLength >= 57) {
    className = 'title--size-s';
  } else if (textLength >= 52) {
    className = 'title--size-m';
  } else if (textLength >= 47) {
    className = 'title--size-l';
  } else if (textLength >= 35) {
    className = 'title--size-xl';
  }
  return className;
};

/**
 *
 * @param timeInSeconds
 * @returns {string}
 */
export const parseTime = timeInSeconds => {
  if (!timeInSeconds) return '00:00';
  const hours = Math.floor(timeInSeconds / (60 * 60));
  const minutes = Math.floor((timeInSeconds % (60 * 60)) / 60);
  const seconds = Math.floor(timeInSeconds) % 60;
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(seconds)) return '00:00';

  // eslint-disable-next-line no-use-before-define
  let parsed = [minutes, seconds].map(pad2);
  if (hours) parsed = [hours, ...parsed];
  return parsed.join(':');
};

const pad2 = number => (number < 10 ? '0' : '') + number;

export default {
  isCurrentlyPlaying,
  getPlayingVideoFromChannel,
  getStreamVideoFromChannel,
  getStreamableAsset,
  liveVideoTotalProgress,
  getEPGIdFromChannel,
  getCategoryIDFromChannel,
  getPlayingMediaPositionFromChannel,
  getTitleSizeClassname,
  parseTime
};
