/* eslint-disable import/no-cycle */
import axios from 'axios';
import appProperties from "../storage/app-properties";





export const isAccessible = () =>
  axios.get(`https://api.rlaxxtv.com/api/access-webclient?api_token=${appProperties.portfolioKey}`)


export const confirmBetaGateCode=(code)=>
  axios.post(`https://api.rlaxxtv.com/api/access-webclient?code=${code}&api_token=${appProperties.portfolioKey}`);





export const getChannels = () =>
  axios.get(
    `https://api.rlaxxtv.com/api/channels?api_token=${appProperties.portfolioKey}${
        appProperties.excluded_ids ? `&exclude_ids=${appProperties.excluded_ids}` : ''}`);

export const getPromoted=()=>
   axios.get(`https://api.rlaxxtv.com/api/channels/promoted?api_token=${appProperties.portfolioKey}`)



export const getLanguages = () =>
  axios.get(`https://api.rlaxxtv.com/api/languages/v2/app/${process.env.REACT_APP_VERSION}?api_token=${appProperties.portfolioKey}`);
