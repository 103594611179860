// eslint-disable-next-line max-len
/* eslint-disable jsx-a11y/interactive-supports-focus,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,react/jsx-boolean-value */
import React from 'react'
import {Link} from "react-router-dom";
import * as PropTypes from "prop-types";
import {Swiper, SwiperSlide} from "swiper/react/swiper-react";
import SwiperCore, {Navigation, Pagination, Mousewheel, FreeMode} from 'swiper'
import 'swiper/swiper-bundle.css'
import 'swiper/modules/navigation/navigation.scss'
import 'swiper/swiper.scss'
import "swiper/modules/free-mode/free-mode.scss"
import VanillaTilt from "vanilla-tilt";
import history from "../../storage/history";
import Localised, {getTranslation} from "../localised";
import {
  getAssetChannelTitle,
  getAssetTitle,
  getAssetWatermark, trackAssetEvent,
} from '../../helpers/assets'
import {images} from '../../helpers'
import './style.scss'
import  {getEPGIdFromChannel} from "../../helpers/video";
import {getSRAssetContentPartner, getSRAssetSerieData} from "../../helpers/partners/sportradar";
import info from "../../assets/rlaxxTV_Icons_SVG_220114/icon/info/white.svg";
import play from '../../assets/images/icon/collection_play.png'
// import {mobileSize} from "../../helpers/measurements";
import seriesImg from "../../assets/rlaxxTV_Icons_SVG_220114/icon/series/white.svg";
import playlistImg from "../../assets/rlaxxTV_Icons_SVG_220114/icon/playlist/white.svg"
import Spinner from "../spinner";
import rightArrow from "../../assets/rlaxxTV_Icons_SVG_220114/icon/indicator-arrow-right/white.svg";
import leftArrow from "../../assets/rlaxxTV_Icons_SVG_220114/icon/indicator-arrow-left/white.svg";

import store, {playerActions} from "../../storage/store";


import {EVENTS, PAGES, trackPageView} from "../../helpers/tracking";
import PlayerContext from "../../storage/contexts/PlayerContext";






SwiperCore.use([Mousewheel,Navigation, Pagination,FreeMode]);



class SwiperSlider extends React.Component {
  constructor(props) {
    super(props);
    this.itemRef = React.createRef();
    this.refSliderWrapper = React.createRef();
    const { data } = this.props;
    this.dataSource = data.related || data.content;
    // eslint-disable-next-line react/no-unused-state
    this.state = { sliderActive: false, loading:false,sliderMoving:false };
  }

  componentDidMount() {
    VanillaTilt.init(document.querySelectorAll('.item-div '), {
      max: 0,
      speed: 400,
      scale:1.03,
      glare: true,
      gyroscope:false,
      transition:true,
      'max-glare': 0.4
    })
    if(this.refSliderWrapper.current){
      this.refSliderWrapper.current.addEventListener('mouseenter',()=>{
        this.setState({sliderActive:true})
      })
      this.refSliderWrapper.current.addEventListener('mouseleave',()=>{
        this.setState({sliderActive:false})
      })
    }


  }



  selectMethod = (item) => {
    const {sliderMoving}=this.state;
    if(!sliderMoving){
      history.push(`/on-demand/channel-page/${item.page_id}`);
    }

  };



  backgroundImage = (item) => {
    const path=item?.content?.editorial?.image?.path||item?.editorial?.image?.path
    if(path){
      const img = images.getSRContentImage(path, "400x225");
      if (img) {
        return { backgroundImage: `url(${img})` };
      }
    }

    return {};
  };


  generateSelectedItem = (item) => {
    const partner = getSRAssetContentPartner(item);
    let selectedItem;
    if (item.teaserType !== "collection") {
      selectedItem = {
        ...item,
        assetType: "vod",
        partnerId: getEPGIdFromChannel(partner),
        assetId: item.id,
        teaserType: undefined,
        pageId: undefined,
      };
    } else {
      selectedItem = {
        ...item,
        assetType: "vod",
        partnerId: -1,
        assetId: item.id,
        teaserType: item.teaserType,
        pageId: item.page_id,
      };
    }

    return selectedItem;
  };


  sliderStopped = () => {
    const { sliderMoving } = this.state;
    if (sliderMoving === true) {
      this.sliderStoppedTO = setTimeout(() => {
        this.setState({ sliderMoving: false }, () => {
          clearTimeout(this.sliderStoppedTO);
        });
      }, 1);
    }
  };

  sliderStart = () => {
    const { sliderMoving } = this.state;
    if (sliderMoving === false) {
      this.setState({ sliderMoving: true });
    }
  };




  playContinueToWatchItem = (continueVideo) => {
    const {current}=this.context;
 store.dispatch(playerActions.setPlayingVideo(continueVideo))
    .then(()=>current.setStartTime(continueVideo.savedTime)).then(() => {

        trackAssetEvent(EVENTS.VIDEO.ACTIONS.PLAY_INITIAL,continueVideo,'on-demand');

      trackPageView(PAGES.ON_DEMAND, `${PAGES.PLAYER}`, getAssetChannelTitle(continueVideo),
        getAssetTitle(continueVideo))


        history.push('/player');


    }).catch(e => {
      console.error(e);
      history.push({ pathname: '/error/501', state: { clearStorage: true } });

    });

    }

  generateMoreSlide(){
    const {moreContent, data}=this.props;

    const slideAmount=this.dataSource;
    if (typeof moreContent ==='function'){
      if(this.channelsSwiper.activeIndex>=2){
        // eslint-disable-next-line react/no-unused-state
        this.setState({loading:true},()=>{
          moreContent(data)
          this.dataSource = data.related || data.content;
          if(this.dataSource.length>slideAmount) this.channelsSwiper.update();
          // eslint-disable-next-line react/no-unused-state
          this.setState({loading:false})
        })
      }
    }
  }




  renderChannelSlide = (item) => {
    const {  deviceType } = this.props;
    return (
          <div  className={`item-wrapper on-demand ${deviceType==="tablet" && "tablet"}  ${deviceType==="desktop" && "desktop"}`}>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <div
            key={item.id}
            className="item-div"
            role="button"
            style={item.image && { backgroundImage: `url(${item.image})` }}
            onClick={() => {
              this.selectMethod(item);
            }}
          />

        </div >



    );
  };

  selectContentFromSlide(item) {
    const { selectItem, type } = this.props;
    const {sliderMoving}=this.state;
    if(item.savedTime){
      this.playContinueToWatchItem(item)
    }else{
      // eslint-disable-next-line no-lonely-if
      if(!sliderMoving){
        if (type === 3) {
          const activeIndex=this.dataSource.indexOf(item)
          selectItem(activeIndex);
          this.channelsSwiper.slideTo(activeIndex,500)
        } else {
          selectItem(this.generateSelectedItem(item));
        }
      }
    }
  }










  renderContentSlide = (item) => {
    const { deviceType, type, currentAsset, playAsset } = this.props;
    const watermark = getAssetWatermark(item) || undefined;
    let title=getAssetTitle(item)||undefined;
    const serieData = getSRAssetSerieData(item);
    const seasons=getTranslation("general.season_short")
    const episodes=getTranslation("general.episode_short")
    const continueWatchingItem=item.savedTime
    let subtitle=serieData.id?serieData.title:undefined
    const seasonTitle = (item.serieData?.seasons && item.serieData.seasons.length > 1)
    ? getTranslation("general.seasons") :
      getTranslation("general.season");
    if (serieData && serieData.episode) {
      title = `${seasons}${serieData.season}
      ${episodes}${serieData.episode}: ${title}`;
    }
    const duration=item?.content?.playtime||item?.playtime;


    if(type===1 && item.teaserType==="collection"){
      subtitle=(item.serieId && item.serieData) ?`${item.serieData.seasons.length} ${seasonTitle}`
        :
        `${getTranslation("general.playlist")}`

    }

    return (
      <div
        className={`item-wrapper ${deviceType==="tablet" && "tablet"} ${deviceType==="desktop" && "desktop"}
                                                ${type === 3 && "collection-item"}
                                                ${type === 3 && item.id===currentAsset.id && "active-content"}
                                              ${type === 1 && "on-demand"} `}
        onClick={() => {
          this.selectContentFromSlide(item);
        }}
      >
        <div
          className={`item-div ${type === 3 && "collection-item"}`}
          role="button"
          style={this.backgroundImage(item)}
        >
          <div className={`item-div-filter ${type===3 && (item.id===currentAsset.id ? 'faded':'normal')}`}>

            {type === 3 && item.id !== currentAsset.id && (
              <div role="button" className="info-icon">
                <img src={info} alt="info"/>
              </div>

            )}

          {type !== 3 && watermark && (
            <img className="watermark" src={watermark} alt="channel-logo" />
            )}

          {type === 3 && (
            <div
            role="button"
            className="play-icon"
            onClick={() => {
            playAsset(this.dataSource.indexOf(item));
          }}
            >
            <img src={play} alt="play" />
            </div>
            )}
            {type!==3 && (
              <div className={`item-desc ${item.savedTime && "continue-to-watch"}`}>
                {subtitle &&
                  <div className="item-subtitle">
                    {continueWatchingItem ? <img src={(serieData && serieData.episode)?seriesImg:playlistImg} alt="series"/> :
                      <img src={item.serieId?seriesImg:playlistImg} alt="series"/>}
                  <p>{subtitle}</p>
                </div>
                }
                <div className="item-title">
                  <p className={type === 3 && "collection"}>{title?.en||title}</p>
                </div>
              </div>
            )}

            {!!continueWatchingItem &&
              <div className="continue-watching-item">
              <div className="duration">
                <div style={{ width:`${(item.savedTime/duration)*100}%`}}/>
              </div>
              </div>
            }
          </div>
        </div>
        {type===3 && item?.editorial?.translations?.en && (
          <div className="item-title">
            <p className={type === 3 && "collection"}>{title?.en||title}</p>
          </div>
        )}


      </div>
    );
  };

  swiperSettingsValues() {
    const { screenSize,deviceType, type, data } = this.props;
    const slideSetting = { slidesPerView: 3.5, navigation: false, spaceBetween: 7 , watchSlidesVisibility:false};
    if (deviceType==="desktop") {
      if (type === 1) {
        slideSetting.slidesPerView = 5;
        slideSetting.spaceBetween = 7;
        slideSetting.navigation = {
          nextEl: `#next_${data.id}`,
          prevEl: `#prev_${data.id}`,
          disabledClass:"disabled"
        }
        slideSetting.watchSlidesVisibility=true;
        }


    }else if(deviceType==="tablet"){
        slideSetting.slidesPerView = (screenSize - 25) / 220;
        slideSetting.spaceBetween = 7;
      slideSetting.watchSlidesVisibility = true;
    } else {
      slideSetting.slidesPerView = (screenSize-15)/170;
      slideSetting.navigation=false;
    }

    return slideSetting;
  }

  renderSlides(){
    const{data}=this.props;
    if(this.dataSource.length>0){
      return this.dataSource.map((item) =>
        <SwiperSlide key={item.id}>
          {data.type === "vertical-promotion"
            ? this.renderChannelSlide(item)
            : this.renderContentSlide(item)}
        </SwiperSlide>
      )
    }
    return <Spinner/>

  }



  render() {
    const { screenSize, data, type, deviceType } = this.props;
    const {sliderActive}=this.state;
    const { related, title } = data;
    const itemTitle = !related ? title.en : "";
    // eslint-disable-next-line no-unused-vars
    const { slidesPerView, navigation, spaceBetween, watchSlidesVisibility } = this.swiperSettingsValues();
    return (
      data &&
      screenSize &&
      this.dataSource.length > 0 && (
        <div className={`on-demand-wrapper ${deviceType!=="mobile" && "desktop"}`}>
          {type !== 3 && (
            <div className="section-title">
              <p>
                <Localised node={`${itemTitle}`} />
              </p>
              {type === 1 && <Link to={`/on-demand/vod/${data.id}`}><Localised node="buttons.more" /></Link>}
            </div>
          )}

          <div ref={this.refSliderWrapper} className={deviceType!=="mobile" && "slider-wrapper"}>
            {type===1 && deviceType==="desktop" && <div id={`prev_${data.id}`}
                                              className={`swiper-button-prev
                                              ${data.type !== "vertical-promotion"&& "withTitle"}
                                ${sliderActive &&this.channelsSwiper&&this.channelsSwiper.activeIndex>0 && 'active'}`}>
              <img src={leftArrow} className="left-arrow-image"  alt="left-arrow"/>
            </div>}

            <Swiper
              modules={[FreeMode]}
              navigation={navigation}
              onSwiper={(instance)=>{this.channelsSwiper=instance;}}
              onSlideChange={()=>{this.generateMoreSlide()}}
              onTouchMove={() => this.sliderStart()}
              onTouchEnd={() => this.sliderStopped()}
              onResize={()=>this.channelsSwiper.update()}
              slidesPerView={slidesPerView}
              spaceBetween={spaceBetween}
              mousewheel={{forceToAxis:true}}
              simulateTouch
              className="items"
              edgeSwipeDetection={true}
              watchSlidesProgress={watchSlidesVisibility}
              freeMode={{
                enabled:true,
                momentumVelocityRatio:0.5,
                momentumRatio:0.8,
                momentumBounce:true,
                momentumBounceRatio:1,
                sticky:false
              }}
            >
              {this.renderSlides()}
            </Swiper>

            {type===1 && deviceType==="desktop" && <div id={`next_${data.id}`} className={`swiper-button-next
             ${data.type !== "vertical-promotion"&& "withTitle"}
                               ${sliderActive &&this.channelsSwiper&&this.channelsSwiper.activeIndex< this.dataSource.length-5 && 'active'}`}>
              <img src={rightArrow} className="right-arrow-image"  alt="right-arrow"/>
            </div>}
          </div>
        </div>
      )
    );
  }
}

SwiperSlider.contextType = PlayerContext;

SwiperSlider.propTypes={
  screenSize:PropTypes.number.isRequired,
  type:PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  currentAsset:PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  data:PropTypes.object.isRequired,
  selectItem:PropTypes.func,
  playAsset:PropTypes.func,
  moreContent:PropTypes.func

}

SwiperSlider.defaultProps={
  type:1,
  currentAsset:undefined,
  selectItem:undefined,
  playAsset:undefined,
  moreContent:undefined
}

export default SwiperSlider;
