/* eslint-disable */
/* eslint-disable react/button-has-type,
import/no-named-as-default-member,jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus,eqeqeq */
import React from 'react'
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import axios from "axios";
import Lottie from "react-lottie";
import {CSSTransition} from "react-transition-group";
import {Swiper, SwiperSlide} from "swiper/react/swiper-react";
import video from "../../helpers/video";
import store, {appActions, playerActions} from "../../storage/store";
import {
  getAssetChannelTitle,
  getAssetDuration,
  getAssetFromID, getAssetMetadata,
  getAssetTitle, getAssetWatermark, trackAssetEvent
} from "../../helpers/assets";
import {
  getCurrentDate, getPartnerIdentifierFromAsset,
  partnerIdentifiers
} from "../../helpers/channels";
import PlayerContext from "../../storage/contexts/PlayerContext";
import {images} from "../../helpers";
import history from "../../storage/history";
import back from "../../assets/rlaxxTV_Icons_SVG_220114/icon/back/white.svg";
import logo from "../../assets/images/rlaxx_logo_mobile.svg";
import bottom from '../../assets/rlaxxTV_Icons_SVG_220114/icon/indicator-arrow-down/white.svg'
import up from '../../assets/rlaxxTV_Icons_SVG_220114/icon/indicator-arrow-up/white.svg'
import Localised, {getTranslation} from "../../components/localised";
import assets from '../../services/apis/sportradar_api';
import {pad2} from "../../utils";
import {differenceBetweenDays} from "../../components/channelEpg/helpers";
import {appendSRParams, getFromSRAPI, srProperties} from "../../storage/sr-properties";
import {trackPageView, EVENTS, PAGES} from '../../helpers/tracking'
import RotateWarning from "../rotate-warning";
// eslint-disable-next-line no-unused-vars
import {mobileSize} from "../../helpers/measurements";
import spinnerData from "../../helpers/spinner.json";
import PlayListDetailItem from "../../components/playlist-detail-item";
import {
  getMultipleSerieData,
  getSRAssetChannelTitle,
  getSRAssetSerieData, parseSRTimeToGermanyZoneDate
} from "../../helpers/partners/sportradar";
import appProperties from "../../storage/app-properties";
import playIcon from "../../assets/rlaxxTV_Icons_SVG_220114/icon/play/white.svg"
import CollectionItemMoreDetail from "./collection-item-more-detail";
import {getChannelFromSRCat2} from "../../storage/redux/app-data/actions";
import collectionItemMoreDetail from "./collection-item-more-detail";


class CollectionDetail extends React.Component {
  constructor(props) {
    super(props);
    this.refContentWrapper=React.createRef();
    this.state = {
      loadedChannel: store.getState().app.currentChannel,
      collection: undefined,
      collectionData: [],
      serieInfo: undefined,
      nonPlayable: false,
      asset: undefined,
      seasons: undefined,
      seasonsTab:undefined,
      descriptionCollapsed:false,
      assetMoreDetailVisible:false,
      loading:false,
      loadingMore: true,
      scrollPos:0,
      scrolledValue:0,
    }

    this.refDescription = React.createRef();
    this.refMoreButton = React.createRef();
    this.refContentText = React.createRef();
    this.changeContentFromRelated = this.changeContentFromRelated.bind(this)
    this.getCollectionElement = this.getCollectionElement.bind(this);
    this.playAssetFromCollection = this.playAssetFromCollection.bind(this);
    this.showAssetMoreDetail=this.showAssetMoreDetail.bind(this)


  }

  componentDidMount() {
    const {deviceType}=this.props;
    this.getCollectionData()
    const {current}=this.context
    current.setStartTime(0);

    window.addEventListener('resize', ()=>{
        this.forceUpdate();
      }
    )
    if(deviceType!=="desktop"){
      if(document.fullscreenElement || document.msFullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement){

        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullScreen) { /* Safari */
          document.webkitExitFullScreen();
        } else if (document.msExitFullscreen) { /* IE11 */
          document.msExitFullscreen();
        }else if(document.mozCancelFullScreen){
          document.mozCancelFullScreen();
        }
        else{
          document.requestFullScreen();
        }
      }
    }
  }


  componentDidUpdate(prevProps) {
    const {scrolled}=this.props;
    const {serieInfo}=this.state;
    if(prevProps.scrolled!==scrolled && !serieInfo){
     this.requestNextPage();
    }
  }

  componentWillUnmount() {
    this.setState({asset: undefined})
  }

  set assetDetails(asset) {
    const {pageView} = this.props
    const {collection} = this.state
      trackPageView(pageView, 'playlist', collection.title.en)
    // eslint-disable-next-line import/no-named-as-default-member
    video.getStreamableAsset(asset)
      .then(streamableAsset => {
        this.setState({asset: streamableAsset});
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error('There was an error while retrieving a Streamable Asset:', error);
        // eslint-disable-next-line react/no-unused-state
        this.setState({asset, nonPlayable: true});
      });
  }

  // eslint-disable-next-line class-methods-use-this
  getAsset(assetType, partnerId, assetId) {
    if (assetType === 'epg') {
      return store.dispatch(appActions.getAssetFromEPGChannel(partnerId, assetId));
    }
    if (assetType === 'vod') {
      return getAssetFromID(assetId, partnerIdentifiers.SPORTRADAR)
        .then(resp => resp?.data?.data)
        // eslint-disable-next-line prefer-object-spread
        .then(vodAsset => Object.assign({}, vodAsset, {content: vodAsset.content[0]}));
    }
    return Promise.reject(`${assetType} is not a valid asset type.`);
  }


  getCollectionElement(assetIndex) {
    const {teaserType} = this.props
    const {collection} = this.state;
    if (teaserType === 'collection') {
      this.assetDetails = collection.content[assetIndex]

    }

  }


  getCollectionData = () => {
    const {pageId}=this.props;
    const navItem = srProperties.navigationItems[pageId];
    this.setState({loading:true})
      return new Promise(resolve => {
        if (!navItem || !navItem.target || !navItem.target.url) {
          history.push( '/error/404')
            resolve();
        }
          getFromSRAPI(navItem.target.url)
            .then(resp => resp?.data?.data?.modules
            )
            .then(modules => modules?.[0])
            .then(playlist => {
              const playlistContent = playlist?.content;
              const asset = playlistContent[0];

              this.setState({collection: playlist, asset})
              const serieId = getAssetMetadata(asset, 'seriesId');
              assets
                .getModuleData(playlist.id)
              if (serieId) {
                this.getSeasons(serieId)
                  .then(serieInfo => {
                    if (serieInfo.seasons?.length > 0) {
                      serieInfo.seasons.sort((a,b)=>a.season-b.season)
                      // eslint-disable-next-line react/no-unused-state
                      this.setState({
                        serieInfo,
                        asset: serieInfo.seasons[0].episodes[0],
                        seasonTab: (sessionStorage.getItem('seasonTab') !== 'undefined') ? serieInfo.seasons[sessionStorage.getItem('seasonTab') - 1]
                        :serieInfo.seasons[0],
                        loading:false
                      }, () => {
                        trackPageView(PAGES.ON_DEMAND, serieInfo?.serieTitle || 'missing-playlist-title')
                        // eslint-disable-next-line react/destructuring-assignment
                        resolve();
                      });
                    } else {
                      throw new Error();
                    }
                  })
                  .catch(e => {
                    console.error(e);
                    console.warn('Loading simple playlist');
                    this.getSimplePlaylist(playlist).then(() => {
                      this.setState({loading:false})
                      return resolve()

                    });
                  });
              } else {
                this.getSimplePlaylist(playlist).then(() => {
                  this.setState({loading:false})
                  return resolve()

                });
              }
            })
            .catch(e => {
              console.error(`Failing getting playlist module::: ${e}`);
              this.setState({loading:false})
              history.replace('/error/404');
            })
            // .finally(()=>{
            //   this.setState({loading:false})
            // })


      });
      // eslint-disable-next-line no-else-return

  };



  getSeasons = serieId =>
    getMultipleSerieData({ serieId })
      .then(serie =>serie[0])
      .then(serieInfo => {
        const seasonPromises = [];
        let scrollValue = parseInt(sessionStorage.getItem('scrollValue'), 10)
        const requestUrl = url =>
          axios.get(url).catch(error => {
            if(sessionStorage.getItem('scrollValue') && sessionStorage.getItem('scrollValue') !== 'undefined' && document.getElementById("contentDetailWrapper")){
              setTimeout(() => {document.getElementById("contentDetailWrapper").scrollTop = scrollValue}, 250);
              sessionStorage.setItem('scrollValue', undefined);
            }
            console.error(`[VOD] Error getting serie episodes response (${url}):`, error);
            return undefined;
          });

        serieInfo.seasons.forEach(season => {
          seasonPromises.push(
            requestUrl(
              `${process.env.REACT_APP_RLAXX_BACKEND}api/v3/series/${serieId}/seasons/${season}/episodes?api_token=${appProperties.portfolioKey}`
            )
          );
          if(sessionStorage.getItem('scrollValue') && sessionStorage.getItem('scrollValue') !== 'undefined' && document.getElementById("contentDetailWrapper")){
            setTimeout(() => {document.getElementById("contentDetailWrapper").scrollTop = scrollValue}, 250);
            sessionStorage.setItem('scrollValue', undefined);
          }
        });

        return Promise.allSettled(seasonPromises)
          .then(responses =>  responses.filter(Boolean))
          .then(res => res.map(elm => elm?.value?.data))
          .then(episodesArray =>{
            console.log(serieInfo)
            return serieInfo.seasons.sort((a,b)=>a.seasons-b.seasons).map((season, i) => {
              const sortedEpisodes = episodesArray[i]
                .sort((a, b) => a?.episodeData?.episode_number - b?.episodeData?.episode_number)
                .filter(Boolean);
              return { season, episodes: sortedEpisodes };
            })
        }
          )
          .then(parsedSeasons => {
            // eslint-disable-next-line no-param-reassign
            serieInfo.seasons = parsedSeasons;
            return serieInfo;
          });
      });

  getSimplePlaylist = playlist =>
    assets
      .getModuleData(playlist.id)
      .then(resp => {
        let scrollValue = parseInt(sessionStorage.getItem('scrollValue'), 10)
        const collection = resp.data.data;
        collection.content = collection.content
          .sort(
            (a, b) =>
              getSRAssetSerieData(a).season - getSRAssetSerieData(b).season ||
              getSRAssetSerieData(a).episode - getSRAssetSerieData(b).episode
          )
          .filter(content => getAssetDuration(content));

        const collectionData = { meta: resp.data?.meta, links: resp.data?.links };
        if(sessionStorage.getItem('scrollValue') && sessionStorage.getItem('scrollValue') !== 'undefined' && document.getElementById("selectedItem")){
          setTimeout(() => {document.getElementById("contentDetailWrapper").scrollTop = scrollValue}, 50);
          sessionStorage.setItem('scrollValue', undefined);
        }
        this.setState({ collection, collectionData }, () => {
          trackPageView(PAGES.ON_DEMAND, playlist?.title?.en || 'missing-playlist-title');
        });
      }).then(()=>{
        const {collection}=this.state;
        this.setState({asset:collection.content[0]})
    })
      .catch(e => {
        console.error(e);
        this.setState({ collection: playlist, collectionData: [] }, () => {
          trackPageView(PAGES.ON_DEMAND, playlist?.title?.en || 'missing-playlist-title');
          // eslint-disable-next-line no-unused-expressions
          // this.playlistRef?.current?.openCurrentSlide();
        });
      });



  getTiming() {
    const {assetType} = this.props;
    if (assetType === 'epg') {
      return this.renderProgress()
    }
    return null;
  }


  playCollectionItemFromList=(item)=>{
    const {seasonTab} = this.state;
    if(document.getElementById("contentDetailWrapper")){
      sessionStorage.setItem('scrollValue', document.getElementById("contentDetailWrapper").scrollTop);
    }
    sessionStorage.setItem('seasonTab', seasonTab?.season);
    this.setState({asset:item},()=>{
      this.watchFromStart();
    })
  }


  fontSizeChanger = (text,expectedSize,originalFontSize=50.52 ) => {
    if (!text) return {};
    let fontSize = `${originalFontSize}px`;
    if (parseInt(text.length, 10) >= 10) {
      fontSize = `${expectedSize}px`;
    }
    return  fontSize ;
  };

  generateButtons = () => {
    const {assetType, partnerId, assetId} = this.props;
    const {asset} = this.state;
    if (assetType && partnerId && assetId && asset) {
      if (assetType === 'vod') {
        return <div className="content-detail-button-group">
          <div className="target-wrapper" role="button" onClick={() => {
            this.watchFromStart()
          }}>
            <button >
              <Localised node="buttons.play_now" />
            </button>
          </div>
        </div>
      }
    }

    return Error;
  }




  watchFromStart = () => {
    const {asset, nonPlayable} = this.state;
    const {pageView, assetType,partnerId, currentTab}=this.props;
    if(currentTab){
      sessionStorage.setItem('currentTab', currentTab);
    }
    if(nonPlayable){
      history.push({ pathname: '/error/501', state: { clearStorage: true } });
    }else{
      this.setState({loading:true, loadingMore:true},()=>{
        this.getAsset(assetType,partnerId, asset.id).then(realAsset=> video.getStreamableAsset(realAsset))
          .then(streamableAsset => {console.log(streamableAsset)
            store.dispatch(playerActions.setPlayingVideo(streamableAsset)).then(() => {
              trackAssetEvent(EVENTS.VIDEO.ACTIONS.PLAY_INITIAL,asset,'on-demand');
              trackPageView(`${pageView}`, `${PAGES.PLAYER}`, getAssetChannelTitle(asset),
                getAssetTitle(asset))
                this.setState({loading:false, loadingMore:true})
              history.push({pathname:"/player", state:{currentTab: currentTab}})
            });
          });
      })

    }




  };

  calculateProgress = (toDate, duration) =>
    Math.ceil(((duration - this.calculateSecRemaining(toDate)) * 100) / duration);

  calculateSecRemaining=(toDate)=>{
    const currentTime = getCurrentDate();
    const endTime = toDate;
    if (currentTime > endTime) return 0;

    const remaining = Math.ceil((endTime - currentTime) / 1000);

    return remaining >= 0 ? remaining : 0;
  };

  requestNextPage = () => {
    const { collectionData, collection } = this.state;
    const {
      meta,
      links
    } = collectionData;

    const currentPage = meta?.current_page;

    // todo: concat the content
    // todo: override meta and links
    if (links?.next!==null && collection && !this.requesting) {
      this.requesting = true;

      axios
        .get(appendSRParams(links.next))
        .then(({ data }) => {
          if (data.meta.current_page > currentPage) {
            collectionData.meta = data.meta;
            collectionData.links = data.links;
            collection.content = [...collection.content, ...data.data.content];
            collection.content = collection.content
              .sort(
                (a, b) =>
                  getSRAssetSerieData(a).season - getSRAssetSerieData(b).season ||
                  getSRAssetSerieData(a).episode - getSRAssetSerieData(b).episode
              )
              .filter(content => getAssetDuration(content));

          }
        })
        .catch(error => {
          console.error("Couldn't load the next page:", error);
        })
        .then(() => {
          this.requesting = false;
          this.forceUpdate();
        });
    }
  };


  changeSelectedSeason=(item)=>{
    const {scrollTop}=this.props;
    if(typeof scrollTop==="function") scrollTop();
    const {serieInfo}=this.state;
    const selectedTab=serieInfo.seasons.find(season=>season.season===item.season)
    this.setState({seasonTab:selectedTab})
  }



  backgroundImage=(item)=>{
    const{collectionAsset}=this.props;
    const itemToShow=item||collectionAsset
    if(itemToShow) {
      const source= images.getSRContentImage(itemToShow?.editorial?.image?.path);

      return `url(${source})`
    }
    return null;
  }


  changeContentFromRelated(asset) {
    const {selectItem} = this.props;
    selectItem(asset)
  }

  showAssetMoreDetail(item){
    if(document.getElementById("contentDetailWrapper")){
      this.setState({scrollPos:document.getElementById("contentDetailWrapper").scrollTop})
      sessionStorage.setItem('scrollValue', document.getElementById("contentDetailWrapper").scrollTop);
    }
    setTimeout(() => this.setState({loadingMore:false}), 500);
    const {assetMoreDetailVisible, serieInfo, collection}=this.state
    const expectedAsset=()=>{
      if(!assetMoreDetailVisible){
        return item;
      } else{
        if(serieInfo) return serieInfo.seasons[0]?.episodes[0]
      }

      return collection?.content[0]
    }
    this.setState({asset:expectedAsset()},()=>{
      this.setState({assetMoreDetailVisible:!assetMoreDetailVisible})
    })
  }


  playAssetFromCollection(assetIndex) {
    const {collection} = this.state;
    const playingAsset = collection.content[assetIndex]
    this.setState({asset: undefined}, () => {
      video.getStreamableAsset(playingAsset)
        .then(streamableAsset => {
          this.setState({asset: streamableAsset});
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error('There was an error while retrieving a Streamable Asset:', error);
          // eslint-disable-next-line react/no-unused-state
          this.setState({playingAsset, nonPlayable: true});
        }).then(() => {
        this.watchFromStart()
      })
    })


  }




  showMore() {
    const { teaserType, deviceType} = this.props;
    const {descriptionCollapsed} = this.state;

    if (this.refMoreButton.current && this.refContentText.current) {
      this.setState({descriptionCollapsed: !descriptionCollapsed}, () => {
        if(descriptionCollapsed) {
          if (teaserType === 'collection') {
            this.refContentText.current.style.webkitLineClamp = '3'
          } else {
            // eslint-disable-next-line no-lonely-if
            if (deviceType!=="mobile") {
              this.refContentText.current.style.webkitLineClamp = '7'
            } else {
              this.refContentText.current.style.webkitLineClamp = '9'
            }
          }

          this.refDescription.current.style.paddingBottom = '0'
        } else {
          this.refContentText.current.style.webkitLineClamp = 'unset'
          this.refDescription.current.style.paddingBottom = '5px'
        }
      })
    }
  }


  addMaskedClass() {
    const { collectionAsset} = this.props;
    const descriptionLength=collectionAsset?.editorial?.translations?.en?.description.split("#")[0].length;
    const lineWidth=Math.floor((window.innerHeight*0.77)/16);
    const lineNum=descriptionLength/lineWidth
    if (lineNum >= 3) return 'masked';

    return '';

  }





  renderProgress() {
    const {asset} = this.state
    const {deviceType}=this.props;
    const time = getCurrentDate();

    let translationNode;
    let replacer;
    if (!asset) return null;
    let startTime;
    let endTime;

    if (getPartnerIdentifierFromAsset(asset) === partnerIdentifiers.WURL) {
      startTime = new Date(asset.startUnix * 1000);
      endTime = new Date(asset.endUnix * 1000);
    }
    else {
      startTime = parseSRTimeToGermanyZoneDate(asset.startUnix * 1000);
      endTime = parseSRTimeToGermanyZoneDate(asset.endUnix * 1000);
    }

    const startHhMm = `${pad2(startTime.getHours())}:${pad2(startTime.getMinutes())}`;
    const endHhMm = `${pad2(endTime.getHours())}:${pad2(endTime.getMinutes())}`;


    const differenceDays = differenceBetweenDays(asset.startUnix * 1000);
    if (differenceDays === 0) {
      translationNode = 'general.timing.today';
    } else if (differenceDays === 1) {
      translationNode = 'general.timing.tomorrow';
    } else if (differenceDays > 1) {
      translationNode = 'general.timing.in_days';
      replacer = {days: differenceDays};
    } else if (differenceDays === -1) {
      translationNode = 'general.timing.yesterday';
    } else if (differenceDays < -1) {
      translationNode = 'general.timing.days_ago';
      replacer = {days: Math.abs(differenceDays)};
    }

    return (
      <div className="content-info-progress">
        <div className="content-info-interval">
          {translationNode && !video.isCurrentlyPlaying(asset) && (
            <>
              <Localised node={translationNode} variables={replacer}/>,{' '}
            </>
          )}
          {`${startHhMm} – ${endHhMm}`}
        </div>
        {video.isCurrentlyPlaying(asset) && (
          <div className={`progress ${deviceType==="tablet" && "tablet"}`}>
            <div className="progressed" style={{
              width: `${(time.getTime() / 1000 - asset.startUnix) * 100 / (asset.endUnix - asset.startUnix)}%`,
              backgroundColor: "#FFFFFF"
            }}/>
          </div>
        )}
      </div>
    );
  }


  renderCollectionElements() {
    const {collection, asset, seasonTab} = this.state;
    const {scrolled, deviceType, currentTab}=this.props;

    if (collection && collection.content && asset) {
     if(seasonTab){
       return <div className={`playlist-details-wrapper ${(scrolled) && "mobile-scrolled"}`}>
         {seasonTab?.episodes.map((item)=>
           <PlayListDetailItem key={item} item={item}
                               showMoreDetail={this.showAssetMoreDetail}
                               deviceType={deviceType}
                               playCollectionItemFromList={this.playCollectionItemFromList}


         />)}
       </div>
       // eslint-disable-next-line no-else-return
     } else{
       return <div className={`playlist-details-wrapper ${(scrolled) && "mobile-scrolled"}`}>
         {collection.content.map((item)=> <PlayListDetailItem key={item}
                                                              type="playlist"
                                                              item={item}
                                                              showMoreDetail={this.showAssetMoreDetail}
                                                              playCollectionItemFromList={this.playCollectionItemFromList}/>)

         }
       </div>

     }
    }
    return null;

  }


  renderMoreDescButton() {
    const {collectionAsset} = this.props;
    const {descriptionCollapsed} = this.state;
    const descriptionLength=collectionAsset?.editorial?.translations?.en?.description.split("#")[0].length;
    const lineWidth=Math.floor((window.innerHeight*0.77)/16);
    const lineNum=descriptionLength/lineWidth
    if ( lineNum >= 3) {
      return (
        <div className="target-wrapper more" role="button" onClick={() => {
          this.showMore()
        }}>
          <button ref={this.refMoreButton} id="more-description" >
            <img src={descriptionCollapsed ? up : bottom} alt="vertical-arrow"/>
            <Localised node={`buttons.${descriptionCollapsed ?'close':'more'}`}/>
          </button>
        </div>
      );
    }
    return null;
  }

  moreBackgroundImage=(asset)=>{
    const path=asset?.content?.editorial?.image.path||asset?.editorial?.image.path||asset?.images?.image_path
    return `url(${images.getSRContentImage(path,'800x450')})`
  }



  render() {
    // eslint-disable-next-line no-unused-vars
    const {screenSize, closeItem, liveChannels,teaserType, collectionAsset, deviceType, rotation, scrolled, collectionData} = this.props;
    const { descriptionCollapsed, collection, serieInfo, asset, seasonTab,assetMoreDetailVisible, loading, scrollPos, scrolledValue, loadingMore } = this.state;
    const moreImagePath=this.moreBackgroundImage(asset);
    const contentName=`${getTranslation("general.season_short")}${asset?.episodeData?.season_number}
    ${getTranslation("general.episode_short")}${asset?.episodeData?.episode_number}`
    const spinnerOptions = {
      loop: true,
      autoplay: true,
      animationData:spinnerData,
    };
    const channel=liveChannels.find(item=>(item?.title.toLowerCase()==getAssetChannelTitle(collection).toLowerCase()))||undefined
    const watermark=channel?.watermark||getAssetWatermark(asset);
    return !assetMoreDetailVisible?
      (
      <div className={`content-item ${loading && 'loading-item'}` }>
        <div id="contentDetailWrapper" ref={this.refContentWrapper} onScroll={()=>
        {
          this.setState({scrolledValue:this.refContentWrapper.current.scrollTop, loadingMore: true})
        }} className={`content-detail-wrapper ${deviceType==="desktop" && 'desktop'}` }
            >
          {deviceType==="mobile" && rotation==='landscape'&& <RotateWarning closeItem={closeItem} type='portrait'/>}
          {(!asset||loading)&&
            <div
                className="content-detail-wrapper loading" >
              <Lottie
                options={spinnerOptions}
                width={60}
                height={60}
              />
            </div>}

          <div id="detail-header" className="content-header">
            <div className="target-wrapper" role="button" onClick={() => {closeItem()}}>
              <button >
                <img src={back} alt="back-button"
                     className={`back ${screenSize<mobileSize && 'mobile'}`}/>
                <Localised node={`buttons.${screenSize >= mobileSize ? 'close' : 'back'}`} />
              </button>
            </div>
            {screenSize < mobileSize && <img className="logo" src={logo} alt="rlaxx-logo"/> }
          </div>


              <div className={`content-background-item ${collection && "collection"} ${!serieInfo && "playlist"} ${loading && "hide"}
               ${deviceType==="tablet" && "tablet"} ${rotation}`}
                   style={{backgroundImage:this.backgroundImage()}}
              >
                <div className={`background-fade-effect ${(scrolledValue && scrolledValue > 10) && "bg"}`}></div>
                {(watermark) && <img className={`watermark ${(scrolledValue && scrolledValue > 10 && serieInfo) && "scrolled"}`} src={watermark} alt={channel?.title}/>}
                <div className={`content-info-wrapper ${deviceType==="desktop" && "desktop"} ${deviceType==="tablet" && "tablet"}`}>
                  <div className="blur"/>
                  <div className={`content-detail-title ${teaserType === 'collection' ? 'collection' : ''} ${(scrolledValue && scrolledValue > 10) && "scrolled"}`}>
                    {teaserType === 'collection' && collection &&
                      <>
                      <div className="content-detail-collection-title">{collection.title.en}</div>
                         {!serieInfo && <p className={`season-amount ${(scrolledValue && scrolledValue > 10) && "scrolled"} `}><Localised node="general.playlist"/> </p>}
                      </>

                    }
                    { serieInfo &&
                      <CSSTransition in={scrolledValue === 0}
                                     timeout={250}
                                     classNames="detail-info-transition">
                        <>
                      <p className={`season-amount ${(scrolledValue && scrolledValue > 10) && "scrolled"} `}>{serieInfo && serieInfo.seasons.length>0 &&
                      <Localised node={`general.season${serieInfo.seasons.length>1?"s":""}_amount`}
                                 variables={{amount:serieInfo.seasons.length}}/>}
                      </p>
                    <div className={`content-detail-button-group ${(scrolledValue && scrolledValue > 10) && "scrolled"}`}>
                      <div className="target-wrapper" role="button" onClick={() => {
                        this.watchFromStart()
                      }}>
                        <button>
                          <img src= {playIcon} alt="play-icon"/>
                          <Localised node="buttons.play_content" variables={{content:contentName}} />
                        </button>
                      </div>
                    </div>
                        </>
                      </CSSTransition>}
                    {serieInfo  && <div className={`content-more-info ${deviceType==="tablet" && "tablet"}
                     ${teaserType === 'collection'&& 'collection'}`}>
                      <CSSTransition in={!scrolled}
                                     timeout={250}
                                     classNames="detail-info-transition">
                        <>
                      <div ref={this.refDescription} className={`content-description
                             ${!descriptionCollapsed && this.addMaskedClass()} ${(scrolledValue && scrolledValue > 10) && "scrolled"}`} >
                        <p className={`${teaserType === 'collection' && 'collection'} ${deviceType==="mobile" && "mobile"}`}
                           ref={this.refContentText}>{serieInfo?.description}</p>
                      </div>
                      </>
                      </CSSTransition>
                      {this.renderMoreDescButton()}
                      {serieInfo &&
                        <div className="content-detail-button-group season">
                          <Swiper
                            slidesPerView="auto"
                            mousewheel={{ forceToAxis: true }}
                            spaceBetween={0}
                            className="button-group"
                          >
                          {
                            serieInfo.seasons.sort((a, b) => a.season-b.season)
                              .map((season)=><SwiperSlide className="swiper-season-button">
                                <div key={season} className= "target-wrapper" role="button">
                                  <button className={seasonTab?.season===season.season && "active"}
                                  onClick={()=>this.changeSelectedSeason(season)}
                                  >
                                    <Localised node="general.season"/>
                                    {" "}
                                    {season.season}
                                  </button>
                                </div>
                              </SwiperSlide>  )
                          }
                          </Swiper>


                        </div>}

                    </div>}
                    <hr className={`line ${(scrolledValue && scrolledValue > 0) && "scrolled"} `}/>
                  </div>

                </div>
              </div>


            <div className={`content-related ${teaserType === 'collection' &&'collection'} ${!serieInfo && "playList"} ${deviceType}`}>
              {this.renderCollectionElements()}
            </div>


        </div>
      </div>
    ):(
        <div ref={this.refContentDetail} className={`content-detail-wrapper more-item ${deviceType==="desktop" && 'desktop'}` }
        >
          {deviceType==="mobile" && rotation==='landscape'&& <RotateWarning closeItem={closeItem} type='portrait'/>}
          {!collectionAsset&&
            <div className="content-detail-wrapper loading" >
              <Lottie
                options={spinnerOptions}
                width={60}
              />
            </div>}
          <CollectionItemMoreDetail asset={asset}
                                    loadingMore={loadingMore}
                                    moreImagePath={moreImagePath}
                                    deviceType={deviceType}
                                    rotation={rotation}
                                    screenSize={screenSize}
                                    isSerie={serieInfo}
                                    collectionTitle={collection.title.en}
                                    watchFromStart={this.watchFromStart}
                                    closeItem={this.showAssetMoreDetail}
                                    backgroundImage={this.backgroundImage}
                                    scrolled={scrolled}
                                    scrollPos={scrollPos}
                                    setState={this.setState}
          />

        </div>
      )
  }
}

CollectionDetail.contextType = PlayerContext;

CollectionDetail.propTypes={
  teaserType: PropTypes.string,
  pageId:PropTypes.string,
  assetId:PropTypes.number,
  assetType:PropTypes.string,
  partnerId:PropTypes.number,
  selectItem:PropTypes.func,
  closeItem:PropTypes.func,
  screenSize:PropTypes.number.isRequired,
  pageView:PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types,react/no-unused-prop-types
  epg:PropTypes.object,

}


CollectionDetail.defaultProps = ({
  teaserType: undefined,
  pageId: undefined,
  assetId:undefined,
  assetType:undefined,
  partnerId:undefined,
  selectItem:undefined,
  closeItem:undefined,
  pageView:undefined,
  epg:undefined

})

const mapStateToProps = state => ({
  screenSize: state.app.screenSize,
  epg:state.app.epg,
  deviceType:state.app.deviceType,
  rotation:state.app.rotation,
  liveChannels:state.app.liveChannels,
})


export default connect(mapStateToProps,)(CollectionDetail)
