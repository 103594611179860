/* eslint-disable arrow-body-style */
import axios from 'axios';


const getFileContent = pathToFile => {

  return axios.get(`${process.env.PUBLIC_URL}/${pathToFile}?version=${process.env.REACT_APP_VERSION}`);
};

export default { getFileContent };
