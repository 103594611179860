/* eslint-disable react/button-has-type,jsx-a11y/click-events-have-key-events */
import React from 'react';
import './style.scss'
import Lottie from "react-lottie";
import store from "../../storage/store"
import closeIcon from "../../assets/rlaxxTV_Icons_SVG_220114/icon/close/whitee.svg"
import playIcon from "../../assets/rlaxxTV_Icons_SVG_220114/icon/play/white.svg"
import backIcon from "../../assets/rlaxxTV_Icons_SVG_220114/icon/back/white.svg"
import Localised, {getTranslation} from "../../components/localised";
import { getAssetDescription, getAssetTitle} from "../../helpers/assets";
import {toggleTabBar} from "../../storage/redux/app-data/actions";
import spinnerData from "../../helpers/spinner.json";

function CollectionItemMoreDetail(props) {
  const {asset, deviceType,rotation, collectionTitle,watchFromStart, closeItem, isSerie, scrollPos, moreImagePath, loadingMore}=props;
  const duration=asset?.content?.playtime||asset?.playtime||asset?.runtime;
  const durationToShow=<Localised node="general.timing.minutes" variables={{minutes:Math.round(duration/60)}} />
  const contentName=`${getTranslation("general.season_short")}${asset?.episodeData?.season_number}
  ${getTranslation("general.episode_short")}${asset?.episodeData?.episode_number}:`
  const spinnerOptions = {
    loop: true,
    autoplay: true,
    animationData:spinnerData,
  };
  const setScroll=()=>{
    setTimeout(() => {document.getElementById("contentDetailWrapper").scrollTop = scrollPos}, 25);
  }

  return  (
    <div className={` more-detail-item-wrapper ${deviceType==="desktop" && 'desktop'}`}>
      {(loadingMore)&&
        <div
          className="content-detail-wrapper loading loading-more">
          <Lottie
            options={spinnerOptions}
            width={60}
            height={60}
          />
        </div>}
      <div className="more-detail-item">
          <div className={`more-detail-background ${deviceType==="tablet" && "tablet"} ${rotation}`}
               style={{backgroundImage:moreImagePath}}>
            <div className="content-info-wrapper">
              {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
              <div className={`target-wrapper close ${deviceType}`} role="button" onClick={() => {
                store.dispatch(toggleTabBar())
                closeItem()
                setScroll()
              }}>
               <button >
                  <img src={ deviceType!=="desktop" ?closeIcon:backIcon} alt="close"/>
                 {deviceType==="desktop" && <Localised node="buttons.back"/>}
                </button>
              </div>
              <div className="blur"/>
              <div className="content-detail-title more-detail">
                  <div className="content-detail-collection-title ">{collectionTitle}</div>
                <div className="content-detail-collection-title subtitle">{isSerie && contentName} { getAssetTitle(asset)}</div>
                <div className="content-detail-button-group">
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
                  <div className="target-wrapper" role="button" onClick={() => {
                    watchFromStart()
                  }}>
                    <button className="watch-button" >
                      <img src={playIcon} alt="play"/>
                      <Localised node="buttons.play_now" />
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </div>

      <div className={`content-more-info ${deviceType==="tablet" && "tablet"}`}>
            <div  className="content-description more-detail" >
              <p className="duration">{ durationToShow}</p>
              <p className={` ${deviceType==="mobile" && "mobile"}`}>{getAssetDescription(asset)}</p>
            </div>
      </div>
      </div>
    </div>
  );
}

export default CollectionItemMoreDetail;
