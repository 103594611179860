/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { throttle } from 'lodash';
import CONSTANTS from  '../../storage/redux/app-data/constants';
import history from '../../storage/history';
import { playing, buffering, advertisement, loading, muted } from '../../storage/redux/player-data/actions';
import PlayerMounter from '../../components/video-player';
import store, { playerActions } from '../../storage/store';
import { video } from '../../helpers';
import { getCurrentDate, getParsedEpgId, isCurrentChannelDisplayedInEpg } from '../../helpers/channels';


import './style.scss';
import { EVENTS } from '../../helpers/tracking';
import {checkContinueWatching, trackAssetEvent} from '../../helpers/assets';
import { getPlayingMediaPositionFromChannel, getStreamableAsset } from '../../helpers/video';
import adsTimer from '../../helpers/ads/ads-timer';
import { isTimerAds, videoEnded } from '../../helpers/ads/ads';
import Error from '../error';
import {aspectRatio, isPortrait, mobileSize} from "../../helpers/measurements";
import {parseSRTimeToGermanyZoneDate} from "../../helpers/partners/sportradar";
// import {detectAdBlocker} from "../../helpers/ads/detectAdBlocker";

export const PLAYER_LIVE_STATUS_CHANGE = 'player-live-change';

const TIME_MOVEMENT = parseInt(process.env.REACT_APP_PLAYER_TIME_TO_CHANGE_LIVE, 10);

const isValidVideo = streamable => streamable && streamable.stream && !!streamable.stream.source;

const isVod = () => history.location.pathname === '/player';

class Player extends React.Component {
  static identifier = 'player';

  static translations = {};



  constructor(props) {
    super(props);

    this.state = { errorMsg: '' };

    this.refPlayerMounter = React.createRef();

    this.setLive(true);

    this.throttledOnTimeUpdate = throttle(this.onTimeUpdate, 100);

    this.errorMessages = [
      'Playback issue: Unknown error',
      "Playback issue: The fetching of the associated resource was aborted by the user's request.",
      // eslint-disable-next-line max-len
      'Playback issue: Some kind of network error occurred which prevented the media from being successfully fetched, despite having previously been available.',
      // eslint-disable-next-line max-len
      'Playback issue: Despite having previously been determined to be usable, an error occurred while trying to decode the media resource, resulting in an error.',
      'Playback issue: The associated resource or media provider object has been found to be unsuitable.'
    ];
  }



  componentDidUpdate(prevProps) {

    const { currentVideo } = this.props;
    if (this.startTime) {
      this.setStartTime(this.startTime);
      this.startTime = undefined;
    }

    if (
      prevProps &&
      isValidVideo(prevProps.currentVideo) &&
      isValidVideo(currentVideo) &&
      currentVideo.stream.source !== prevProps.currentVideo.stream.source &&
      // eslint-disable-next-line react/destructuring-assignment
      this.props.loading
    ) {
      // eslint-disable-next-line react/destructuring-assignment
      this.props.loading(true);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.bufferTO);
    document.removeEventListener('resize',()=>{
      this.setWidth();
    })
  }

  onPause = () => {
    clearTimeout(this.bufferTO);

    if (this.props.playing) this.props.playing(false);
  };

  onPlay = () => {
    clearTimeout(this.bufferTO);
    clearTimeout(this.endedTimeout);

    this.setState({ errorMsg: '' });
    if (this.props.playing) this.props.playing(true);
  };

  onTimeUpdate = () => {
    const { isBuffering, isPlaying } = this.props;
    clearTimeout(this.bufferTO);
    if (!isBuffering && isPlaying) {
      this.bufferTO = setTimeout(() => {
        if (this.props.buffering) this.props.buffering(true);
      }, 750);
    } else if (isBuffering && isPlaying && this.getCurrentTime() > 0) {
      if (this.props.buffering) this.props.buffering(false);
    }
  };

  onPlaying = () => {
    this.setState({ errorMsg: '' });

  };

  onWaiting =() => {
    this.setState({ errorMsg: '' });

  };

  onLoadedMetadata = () => {
    this.setState({ errorMsg: '' });
    if (this.props.advertisement && !this.isAd) this.props.advertisement(this.isAd);
    if (this.props.loading) this.props.loading(false);
  };

  onAbort = () => {

  };

  onError = () => {
    if (this.refPlayerMounter?.current?.refPlayer?.current?.refPlayer?.current?.error) {
      const { error } = this.refPlayerMounter.current.refPlayer.current.refPlayer.current;
      if (error && error.code)
        this.setState({ errorMsg: this.errorMessages[error.code] }, () => {
          // eslint-disable-next-line no-console
          console.error(`[${error.code}] ${this.errorMessages[error.code]}`);
          if (isVod()) {
            history.replace('/error/501');
          }
        });
    }
  };

  onEndedError = (error, reloadLiveChannels = false) => {
    // eslint-disable-next-line no-console
    console.info('player::onEnded', 'There was an error getting the next video.', error);
    if (reloadLiveChannels) {
      document.dispatchEvent(new CustomEvent('INIT_APP_DATA', { bubbles: true }));
    }
  };

  onEnded = () => {
    const { isAd, currentVideo } = this.props;
    if (this.isAd || isAd) return;
    if (history.location.pathname.indexOf('/player') !== -1) {
      // eslint-disable-next-line no-console
      console.log('player::onEnded::VOD', 'Go back to previous screen');
      trackAssetEvent(EVENTS.VIDEO.ACTIONS.COMPLETED, currentVideo);
      checkContinueWatching(this.getCurrentTime(), this.getDuration(), currentVideo).then(() =>
      {history.replace({ pathname: '/autoplay', state: { currentVideo } })}
      );
    } else if (!isCurrentChannelDisplayedInEpg()) {
      this.setCurrentTime(0);
    } else {
      // eslint-disable-next-line no-console
      console.log('player::onEnded::Live', 'Jump to next EPG video');

      const {
        epg,
        currentChannel: { epgId }
      } = store.getState().app;

      const parsedEpgId = getParsedEpgId(epgId);

      const currentPlayingPosition = getPlayingMediaPositionFromChannel(epg, parsedEpgId);
      if (currentPlayingPosition !== -1) {
        let media = epg[parsedEpgId].assets[currentPlayingPosition];

        // eslint-disable-next-line eqeqeq
        if (media.id != currentVideo.id) {
          videoEnded(); // if next media is different, count the video as ended
        }
        // eslint-disable-next-line no-console
        console.info('player::onEnded', 'Play next media:', media.title);
        this.playMediaFromChannel(epg, epgId, media, true).catch(currentError => {
          if (currentPlayingPosition < epg[parsedEpgId].assets.length - 1) {
            console.info('Player::onEnded', 'Cannot play current media, play next one.', currentError);

            media = epg[parsedEpgId].assets[currentPlayingPosition + 1];

            // eslint-disable-next-line eqeqeq
            if (media.id != currentVideo.id) {
              videoEnded(); // if next media is different, count the video as ended
            }

            this.playMediaFromChannel(epg, epgId, media, true).catch(nextError => {
              this.onEndedError(nextError);
            });
          } else {
            this.onEndedError('There is no available media after the current one.', true);
          }
        });
      } else {
        this.onEndedError('There is no available media right now.', true);
      }

    }
  };

  onAdRemainingTimeChange = (remainingTime, totalDuration) => {
    if (this.props.advertisement) {
      this.props.advertisement(this.isAd, remainingTime, totalDuration);
    }
  };

  get isAd() {
    if (this.refPlayerMounter.current) {
      return this.refPlayerMounter.current.isAd;
    }
    return true;
  }

  get isPaused() {
    if (this.refPlayerMounter.current) {
      return this.refPlayerMounter.current.isPaused;
    }
    return true;
  }

  get readyState() {
    if (this.refPlayerMounter.current) return this.refPlayerMounter.current.readyState;
    return 0;
  }

  setLive = value => {
    this.isLive = value;
    document.dispatchEvent(new CustomEvent(PLAYER_LIVE_STATUS_CHANGE, { detail: value, bubbles: true }));
  };

  getDuration = () => {
    if (this.refPlayerMounter.current) return this.refPlayerMounter.current.getDuration();
    return undefined;
  };

  getRemainingTime = () => {
    if (this.refPlayerMounter.current) return this.refPlayerMounter.current.getRemainingTime();
    return undefined;
  };

  getCurrentTime = () => {
    if (this.refPlayerMounter.current) return this.refPlayerMounter.current.getCurrentTime();
    return 0;
  };

  setCurrentTime = newVideoPosition => {
    if (this.refPlayerMounter.current) this.refPlayerMounter.current.setCurrentTime(newVideoPosition);
  };

  setStartTime = startTimePosition => {
    if (this.refPlayerMounter.current) {

      this.refPlayerMounter.current.setStartTime(startTimePosition);
    } else {

      this.startTime = startTimePosition;
    }
  };

  setWidth(width='100%', height='100%'){
    const{screenSize, deviceType}=this.props;
    const sizes = { width, height}
    if ((deviceType!=="desktop" || screenSize<mobileSize) && history.location.pathname !== "/player" && isPortrait()==='portrait') {
      sizes.height = `${screenSize * aspectRatio }px`
    }
    return sizes;
  }


  play = () => {
    if (this.refPlayerMounter.current && !this.isAd) {

      const { currentVideo } = this.props;
      if (!this.isAd) trackAssetEvent(EVENTS.VIDEO.ACTIONS.PLAY, currentVideo);

      this.refPlayerMounter.current.play();
      this.setLive(false);
      if (isTimerAds()) {
        adsTimer.resume();
      }
    }
  }

  pause = () => {
    if (this.refPlayerMounter.current && !this.isAd) {

      const { currentVideo } = this.props;
      if (!this.isAd) trackAssetEvent(EVENTS.VIDEO.ACTIONS.PAUSE, currentVideo);

      this.refPlayerMounter.current.pause();
      this.setLive(false);
      if (isTimerAds()) {
        adsTimer.pause();
      }
    }
  }

  rewind = (amount) => {
    if (this.refPlayerMounter.current && !this.isAd) {
      this.refPlayerMounter.current.rewind(amount);
      this.setLive(false);
    }
  }

  forward = (type, toEnd = false) => {
    if (this.refPlayerMounter.current && !this.isAd) {
      let amount = TIME_MOVEMENT;
      if (type === 'live') {
        // eslint-disable-next-line react/prop-types
        const { currentVideo } = this.props;
        let startTime = currentVideo.start;
        startTime = parseSRTimeToGermanyZoneDate(startTime);

        const time = getCurrentDate();

        const currentMaxTime = time.getTime() / 1000 - startTime.getTime() / 1000;
        const currentTime = this.getCurrentTime();

        if (toEnd || currentTime + amount > currentMaxTime) {
          amount = currentMaxTime - currentTime;
          this.setLive(true);
        }
      }

      this.refPlayerMounter.current.forward(amount);
    }
  };

  mute = () => {

    if (this.refPlayerMounter.current) this.refPlayerMounter.current.mute();

    if (this.props.muted) this.props.muted(true);


  }

  unmute = () => {

    if (this.refPlayerMounter.current) this.refPlayerMounter.current.unmute();

    if (this.props.muted) this.props.muted(false);

  };



  getVolume=()=>{
    if (this.refPlayerMounter.current) return this.refPlayerMounter.current.getVolume();
    return null
  }

  setVolume=(value)=>{
    if(this.refPlayerMounter.current) this.refPlayerMounter.current.setVolume(value)
    return null
  }

  toggleVideo = () => {
    if (this.isPaused && this.readyState > 0) this.play();
    else this.pause();
  };

  isMuted=()=>{
    if(this.refPlayerMounter.current) return this.refPlayerMounter.current.isMuted()
    return null
  }

  fullScreen=()=>{
    if(this.refPlayerMounter.current) {
      this.refPlayerMounter.current.fullScreen();
    }
  }


  playMediaFromChannel = (epg, epgId, media, fromStart = false) => {

    const { currentVideo } = this.props;

    return new Promise((resolve, reject) => {
      if (media.id !== currentVideo.id) {
        getStreamableAsset(media)
          .then(assetToPlay => {
            const { id } = epg[epgId].channelInfo;
            store.dispatch({
              type: CONSTANTS.SET_CURRENT_CHANNEL,
              payload: { epgId, id }
            });
            store.dispatch(playerActions.setPlayingVideo(assetToPlay));
            trackAssetEvent(EVENTS.VIDEO.ACTIONS.PLAY_INITIAL, media);
          })
          .then(() => {
            if (!fromStart) {
              this.setStartTime(video.liveVideoTotalProgress(media.start));
            }
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      } else {
        reject('Media is already playing');
      }
    });
  };



  render() {
    const {errorMsg} = this.state;
    const {currentVideo, screenSize, deviceType } = this.props;

    return (
      <div id="playerView" style={{position:"fixed", margin:"auto"}}>
        <div className="viewWrapper" style={{ display:  'block' }}>
          {currentVideo && (
            <PlayerMounter
              ref={this.refPlayerMounter}
              source={currentVideo.stream?.source}
              drmConfig={currentVideo.drm_stream}
              mimeType={currentVideo.stream?.mimeType}
              vastUrl={currentVideo.vastUrl}
              prerolls={1}
              midrolls={3}
              onPause={this.onPause}
              onPlay={this.onPlay}
              onPlaying={this.onPlaying}
              onWaiting={this.throttledOnTimeUpdate}
              onEnded={this.onEnded}
              onError={this.onError}
              onAbort={this.onAbort}
              onLoadedMetadata={this.onLoadedMetadata}
              onAdRemainingTimeChange={this.onAdRemainingTimeChange}
              onTimeUpdate={this.throttledOnTimeUpdate}
              autoPlay
              screenSize={screenSize}
              deviceType={deviceType}
            />
          )}
        </div>
        {errorMsg && <Error match={{ params: { code: '502' } }} hideButtons />}
      </div>
    );
  }
}

Player.propTypes = {
  playing: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
  buffering: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
  advertisement: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
  loading: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
  muted: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
  isBuffering: PropTypes.bool,
  isPlaying: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  currentVideo:PropTypes.object,
  isAd:PropTypes.bool,
  screenSize:PropTypes.number,
};


Player.defaultProps = {
  playing: undefined,
  buffering: undefined,
  advertisement: undefined,
  muted: undefined,
  loading: undefined,
  isBuffering: true,
  isPlaying: false,
  currentVideo:undefined,
  isAd:undefined,
  screenSize:undefined
};

const mapStateToProps = state => ({
  currentVideo: state.player.currentVideo,
  isAd: state.player.isAd,
  isPlaying: state.player.isPlaying,
  isBuffering: state.player.isBuffering,
  screenSize:state.app.screenSize,
  deviceType:state.app.deviceType
});

const actionCreators = { playing, buffering, advertisement, loading, muted };

export default connect(mapStateToProps, actionCreators, null, { forwardRef: true })(Player);
