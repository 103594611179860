/* eslint-disable no-param-reassign,react/button-has-type */
import React from 'react'
import {connect} from "react-redux";
import './style.scss'
import liveTv from '../../assets/rlaxxTV_Icons_SVG_220114/icon/livetv/white.svg'
import vod from '../../assets/rlaxxTV_Icons_SVG_220114/icon/vod/white.svg'
import settings from "../../assets/rlaxxTV_Icons_SVG_220114/icon/settings/white.svg"
import history from "../../storage/history";
import Localised from "../localised";





class TabBar extends React.Component {
    constructor(props) {
        super(props);
        this.refLiveTV=React.createRef()
        this.refOnDemand=React.createRef()
        this.refSettings=React.createRef()

    }

    componentDidMount() {
        if(this.refLiveTV.current&&this.refOnDemand.current) {
            if(history.location.pathname==='/') this.refLiveTV.current.style.opacity= "1";
            if(history.location.pathname.includes("/home")) this.refLiveTV.current.style.opacity= "1";
            if(history.location.pathname.includes("/on-demand")) this.refOnDemand.current.style.opacity= "1";
          if(history.location.pathname.includes("/settings") && this.refSettings.current ) this.refSettings.current.style.opacity= "1";
        }
    }

    selectedTab(button,path){
        if(this.refLiveTV.current&&this.refOnDemand.current){
            const buttonArr=[this.refLiveTV,this.refOnDemand, this.refSettings];
            buttonArr.forEach(btn=>{
              if(btn.current){
                btn.current.style.opacity= "0.5";
              }
            })
            button.current.style.opacity="1";
            if(history.location.pathname!==path) history.push(path)

        }



    }

    render() {
      const {deviceType}=this.props;
        return (
            <div className={`tab-bar-wrapper ${deviceType}`} >
                <div className="tab-bar">
                    <button ref={this.refLiveTV } className="section" onClick={()=>{
                        this.selectedTab(this.refLiveTV,'/home')
                    }}>
                        <img src={liveTv} alt="live-tv"/>
                        <p><Localised node="navigation.live" /></p>
                    </button>
                    <button ref={this.refOnDemand } className="section"
                           onClick={()=>{this.selectedTab(this.refOnDemand,'/on-demand')}}
                    >
                        <img src={vod} alt="on-demand-video"/>
                        <p><Localised node="navigation.on_demand" /></p>
                    </button>
                  { deviceType==="tablet" &&
                  <button ref={this.refSettings } className="section" onClick={()=>{
                    this.selectedTab(this.refSettings,'/settings')
                  }}>
                    <img src={settings} alt="settings"/>
                    <p><Localised node="navigation.settings" /></p>
                  </button>

                  }

                </div>
            </div>)
    }
}


const mapStateToProps=state=>({

  tabBarVisible:state.app.tabBarVisible

})

export default connect(mapStateToProps,)(TabBar);


