/* eslint-disable react/button-has-type,jsx-a11y/control-has-associated-label */
import React from "react";
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import PlayerContext from "../../storage/contexts/PlayerContext";
import history, {clearHistory} from "../../storage/history";
import error from '../../assets/images/error.png'
import logo from '../../assets/images/rlaxx_logo_mobile.svg'
import logoLong from '../../assets/images/rlaxx_logo_long.svg'
import './style.scss'
import { PAGES, trackPageView } from '../../helpers/tracking'
import Localised from "../../components/localised";


class ErrorPage extends React.Component {
  constructor(props) {
    super(props);

    const {
      match: {
        params: { code },
      },
      location
    } = this.props;
    this.errorCode = code || 404;
    this.errorCode = Number(this.errorCode);
    this.maintenanceDate=location && location.state && location.state.maintenanceDate ? location.state.maintenanceDate : '';
  }

  componentDidMount() {
    const {
      match: {
        params: { type },
      }
    } = this.props;

    trackPageView(PAGES.ERROR, `${this.errorCode}${type?`/${type}`:"" }`);
  }


  confirm = () => {
    // eslint-disable-next-line react/prop-types,react/destructuring-assignment
    if (this.props.location.state && this.props.location.state.clearStorage) {
      localStorage.clear();
    }

    document.dispatchEvent(new CustomEvent('initfailed', { bubbles: true }));
    clearHistory();

  };


  renderErrorSuggestion() {

    switch (this.errorCode) {
      case 400:
        return <button onClick={()=>this.confirm()}><Localised node="buttons.try_again"/></button>;

      case 403:
        return (
          <p>
            <Localised node="error.code_403.geoblocking.description" dangerouslySetInnerHTML/>
          </p>
        );

      case 404:
        return (
          <div>
            <div>
              <p className="error-code-description"> <Localised node="error.code_404.description" dangerouslySetInnerHTML/></p>
            </div>
            <div className="multiple-button">
              {/* eslint-disable-next-line import/no-named-as-default-member */}
              <button onClick={() => history.goBack()}><Localised node="buttons.back"/></button>
              <button onClick={()=>this.confirm()}>
                <Localised node="buttons.try_again"/>
              </button>
            </div>
          </div>
        );

      case 408:
        return (
          <div>
            <div>
              <p className="error-code-description"> <Localised node="error.code_408.description" dangerouslySetInnerHTML/></p>
            </div>
            <div>
              <button
                onClick={()=>this.confirm()}
              >
                <Localised node="buttons.try_again"/>
              </button>
            </div>
          </div>
        );

      case 500:
        return (
          <div>
            <div>
              <p className="error-code-description"> <Localised node="error.code_500.description" dangerouslySetInnerHTML/></p>
            </div>
            <div>
              <button
                onClick={()=>this.confirm()}
              >
                <Localised node="buttons.try_again"/>
              </button>
            </div>
          </div>
        );

      case 501:
        return (
          <div>
            <div>
              <p className="error-code-description"> <Localised node="error.code_501.description" dangerouslySetInnerHTML/></p>
            </div>
            <div className="multiple-button">
              <button
                onClick={()=>this.confirm()}
              >
                <Localised node="buttons.try_again"/>
              </button>
              {/* eslint-disable-next-line import/no-named-as-default-member */}
              <button onClick={() => history.goBack()}><Localised node="buttons.back"/></button>
            </div>
          </div>
        );

      case 502:
        return <p className="error-code-description"> <Localised node="error.code_502.description" dangerouslySetInnerHTML/></p>;

      case 503:
        return <p className="error-code-description maintenance">
          <Localised node="error.code_503.maintenance.description" dangerouslySetInnerHTML variables={{time_of_service_return:this.maintenanceDate}}/>
        </p>;

      default:
        return <button onClick={()=>this.confirm()}><Localised node="buttons.try_again"/></button>;
    }
  }

  renderError() {
    switch (this.errorCode) {
      case 400:
        return  <Localised node="error.code_400.title" dangerouslySetInnerHTML/>;

      case 403:
        return  <Localised node="error.code_403.geoblocking.title" dangerouslySetInnerHTML/>;

      case 404:
        return  <Localised node="error.code_404.title" dangerouslySetInnerHTML/>;

      case 408:
        return  <Localised node="error.code_408.title" dangerouslySetInnerHTML/>;

      case 500:
        return  <Localised node="error.code_500.title" dangerouslySetInnerHTML/>;

      case 501:
        return  <Localised node="error.code_501.title" dangerouslySetInnerHTML/>;

      case 502:
        return  <Localised node="error.code_502.title" dangerouslySetInnerHTML/>;

      case 503:
        return  <Localised node="error.code_503.maintenance.title" dangerouslySetInnerHTML/>;

      default:
        return  <Localised node="error.code_400.title" dangerouslySetInnerHTML/>;
    }
  }

  render() {
    const { deviceType } = this.props;
    return (
      deviceType && (
        <div className={`error-wrapper ${deviceType!=="mobile" && "desktop"}`}>
          <img className="logo" src={deviceType==="desktop" ? logoLong : logo} alt="rlaxx-logo" />
          <div className="error-detail-wrapper">
            <div className="error-image">
              <img src={error} alt="rlaxx-error" />
            </div>
            <div className="error-explanation">
              <p>
                {this.renderError()}
              </p>
            </div>
            <div className="error-button-group">{this.renderErrorSuggestion()}</div>
          </div>

          <div className="error-code">
            <p><Localised node="error.error_code"/>: {this.errorCode} | <Localised node="general.app_version"/>: {process.env.REACT_APP_VERSION} </p>
            <p><Localised node="error.contact_support_hint" dangerouslySetInnerHTML/></p>
          </div>
        </div>
      )
    );
  }
}

ErrorPage.propTypes={
  deviceType:PropTypes.number.isRequired
}

const mapStateToProps = state => ({
  deviceType:state.app.deviceType
});

ErrorPage.contextType = PlayerContext;

export default connect(mapStateToProps, null, null,)(ErrorPage);
