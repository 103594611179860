import React from "react";
import './style.scss'
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import rlaxxLogo from '../../assets/images/logo.png'
import Localised, {getTranslation} from "../../components/localised";
import {PAGES, trackPageView} from "../../helpers/tracking";
import {mobileSize} from "../../helpers/measurements";
import {confirmBetaGateCode} from "../../services/epg_api";




class BetaGate extends React.Component{
  constructor(props) {
    super(props);
    this.refAccessCode=React.createRef();
    this.refStatusLabel=React.createRef();
    this.refSubmit=React.createRef();
    this.refDescription=React.createRef();
    this.refBeta=React.createRef();
    this.refDummySpan=React.createRef();
    this.passwordEntry='';
    this.currentInputLength=0
    this.availableCountries=['DE', 'AT', 'CH', 'UK', 'FR', 'ES', 'PT']
    this.state={
      status:undefined,
      accepted:false,
    }
  }

  componentDidMount() {
    this.secondSequence=setTimeout(()=>{
        if(this.refSubmit.current&&this.refBeta.current) {
          this.refBeta.current.addEventListener('keyup', (e) => {
            this.keyUp(e)
          })
        }
      })

    trackPageView(PAGES.BETAGATE);
  }

  componentWillUnmount() {
    const {accepted}=this.state;
    localStorage.setItem('betagate', JSON.stringify(accepted));
    clearTimeout(this.secondSequence)
    if(this.refSubmit.current&&this.refBeta.current) {
      this.refBeta.current.removeEventListener('keyup', (e) => {
        this.keyUp(e)
      })
    }

  }



  footer=()=>{
    const description= getTranslation("beta_gate.website_hint")
    return {
      __html:description
    }
  }


  keyUp(e){
    if (e.keyCode === 13) {
      e.preventDefault();
      this.refSubmit.current.click();
    }
  }

  checkPassword() {
    const { accepted } = this.state;
    const { displayBeta } = this.props;
    if (accepted) {
      localStorage.setItem("betagate", JSON.stringify(true));
      displayBeta();
    } else {
      const enteredCode=this.refAccessCode.current.value;
      confirmBetaGateCode(enteredCode).then(()=>{
        this.setState({accepted:true},()=>{
          displayBeta()
        })
      }).catch((error)=> this.setState({ status: 0 }, () => {
        console.log(error)
        this.refAccessCode.current.value = "";
        this.passwordEntry = "";
      }))

    }
  }


  generateTextNode(){
    const{accepted, status}=this.state;

    let textNode = "buttons.continue";
    if(!accepted && status !== undefined) {
      textNode = "buttons.try_again";
    }else if(accepted) {
      textNode = "buttons.understood";
    }
    return textNode
  }

  // generateDescription(){
  //
  //
  //
  //    const description = getTranslation("beta_gate.description");
  //
  //   return description
  // }

  // eslint-disable-next-line class-methods-use-this
  isBetaShown(){
    const isBetaAccepted=localStorage.getItem('betagate')
    return isBetaAccepted?JSON.parse(isBetaAccepted):false;


  }

  renderButton(){
    // eslint-disable-next-line react/button-has-type
    return (<button  ref={this.refSubmit} onClick={()=>{this.checkPassword()}}>
        <Localised node={this.generateTextNode()} />
      </button>
    )
  }

  renderBetaContentPart=()=>
    // eslint-disable-next-line react/no-danger
    (<div className="beta-content-part" dangerouslySetInnerHTML={{
      __html:getTranslation("beta_gate.description")
    }} />)



  render(){
    const {screenSize}=this.props;
    const {status,accepted}=this.state
    const placeHolder=getTranslation("beta_gate.access_code")

    return(
      <div ref={this.refBeta} className={`beta-gate-wrapper ${screenSize<mobileSize&&'mobile'}`}>
        <div className="beta">
          <div className="beta-header"><img src={rlaxxLogo} alt="rlaxx-logo"/></div>
          <div className="beta-content">
            <div className="beta-content-part">
              {!accepted?
                <h2><Localised node="beta_gate.title" /></h2>:
                <h3><Localised node="beta_gate.portfolio_notice.title"/></h3>}
            </div>
            {this.renderBetaContentPart()}
            {!accepted&& <div className="beta-content-part">
              <input ref={this.refAccessCode}
                     className={`${status===0&&'wrong-code'}`}
                     type="password"
                     placeholder={`${placeHolder }`}
              />
              {status===0 && <p className="error" ref={this.refStatusLabel}><Localised node="beta_gate.wrong_code"/> </p>}
            </div>}

            <div className="beta-content-part">
              {this.renderButton()}
            </div>
          </div>
          {/* eslint-disable-next-line react/no-danger */}
          <div className="beta-footer"><p className="web-site-hint" dangerouslySetInnerHTML={this.footer()}/> </div>

        </div>
      </div>
    )
  }
}


BetaGate.propTypes={
  screenSize: PropTypes.number.isRequired,
  displayBeta: PropTypes.func.isRequired



}

const mapStateToProps=state=>({
  screenSize:state.app.screenSize
})


export default connect(mapStateToProps,)(BetaGate);





