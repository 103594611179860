/* eslint-disable prefer-object-spread */
import CONSTANTS from './constants';
// eslint-disable-next-line import/no-cycle
import {getOrientation} from "../../../helpers/measurements";



const initialState = {
  liveChannels: [],
  vodChannels: [],
  epg: {},
  vod: [],
  currentChannel: {},
  countryCode: undefined,
  screenSize:window.innerWidth,
  extended:0,
  deviceType:"",
  promoted: [],
  rotation: getOrientation(),
  tabBarVisible:true
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CONSTANTS.UPDATE_CHANNELS: {
      if (action.payload.type === 'live' && action.payload.data) {
        return Object.assign({}, state, { liveChannels: action.payload.data });
        // eslint-disable-next-line no-else-return
      } else if (action.payload.type === 'vod' && action.payload.data) {
        return Object.assign({}, state, { vodChannels: action.payload.data });
      }
      return state;
    }
    case CONSTANTS.UPDATE_EPG: {
      return Object.assign({}, state, { epg: action.payload });
    }
    case CONSTANTS.UPDATE_VOD: {
      return Object.assign({}, state, { vod: action.payload });
    }
    case CONSTANTS.UPDATE_PROMOTED: {
      return Object.assign({}, state, { promoted: action.payload });
    }
    case CONSTANTS.SET_CURRENT_CHANNEL: {
      return Object.assign({}, state, { currentChannel: action.payload });
    }
    case CONSTANTS.SET_COUNTRY: {
      return Object.assign({}, state, { countryCode: action.payload });
    }
    case CONSTANTS.GET_SCREENSIZE:{
      return Object.assign({},state,{screenSize: action.payload})
    }
    case CONSTANTS.UPDATE_ROTATION:{
      return Object.assign({},state,{rotation: action.payload})
    }
    case CONSTANTS.GET_BURGER_MENU_EXTENDED:{
      return Object.assign({},state,{extended: action.payload})
    }
    case CONSTANTS.GET_DEVICE_TYPE:{
      return Object.assign({},state,{deviceType: action.payload})
    }
    case CONSTANTS.TOGGLE_TABBAR_VISIBLE:{
      return Object.assign({},state,{tabBarVisible:!state.tabBarVisible})
    }
    default:
      return state;
  }
}
