const images = {
  BASE_URL: `${process.env.REACT_APP_RLAXX_IMAGES}channels`,
  WHITE: 'white.png',
  COLOUR: 'colour.png',
  LOGO: 'logo.jpg',
  WATERMARK: 'watermark.png',
  PROMOGRAPHIC: 'promographic.jpg',

  getPath(type, identifier) {
    return identifier ? `${this.BASE_URL}/${identifier}_${type}` : null;
  },
  getSRContentImage(path, manipulation = 'original') {
    return `${process.env.REACT_APP_SR_IMAGES_LINK + manipulation}/${path}`;
  }
};



export default images;
