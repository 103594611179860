import React from 'react';


class ProgressRing extends React.Component {
  constructor(props) {
    super(props);

    // eslint-disable-next-line react/prop-types
    const { radius, stroke } = this.props;

    this.normalizedRadius = radius - stroke / 2;
    this.circumference = this.normalizedRadius * 2 * Math.PI;
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { radius, stroke, progress } = this.props;

    const strokeDashoffset = this.circumference - (progress / 100) * this.circumference;

    return (
      <svg
        height={radius * 2 }
        width={radius * 2 }
        style={{ transform: 'rotate(-90deg)' }}
      >
        <circle
          stroke="white"
          fill="transparent"
          strokeWidth={(stroke || 0) }
          strokeDasharray={`${this.circumference } ${this.circumference}`}
          style={{
            strokeDashoffset,
            transition: 'all 300ms linear'
          }}
          // strokeWidth={stroke * helpers.horizontalScale}
          r={this.normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
    );
  }
}

export default ProgressRing;
