import axios from 'axios';

export default () =>
  axios.create({
    baseURL: process.env.REACT_APP_SR_API_URL,
    withCredentials: false,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });

