/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import appProperties from '../../storage/app-properties';

// eslint-disable-next-line import/no-cycle
import { appendObjectToAsset } from '../video';

export function getPantaflixStream(asset) {
  let streamableAsset = asset;
  // eslint-disable-next-line no-unused-vars
  const { drm, content } = asset;
  return axios
    .get(`${drm}?api_token=${appProperties.portfolioKey}`)
    .catch(response => {
      // eslint-disable-next-line no-console
      console.error('[Pantaflix] There was an error retrieving the player settings.', response);
    })
    .then(response => ({ drm_stream: response.data }))
    .then(streamObject => {
      streamableAsset = appendObjectToAsset(streamableAsset, streamObject);
      return streamableAsset;
    });
}

export const getPANTAFLIXAdCategory = () => '';
