import React from "react";
import './style.scss'
import Localised, {getTranslation} from "../localised";
import {
  getAssetDescription,
  getAssetTitle
} from "../../helpers/assets";
import store from "../../storage/store";
import {images} from "../../helpers";
import playButton from "../../assets/rlaxxTV_Icons_SVG_220114/icon/play/white.svg"
import {toggleTabBar} from "../../storage/redux/app-data/actions";






function PlayListDetailItem(props){
const {item, deviceType,showMoreDetail, type,playCollectionItemFromList, }=props;
  const title=getAssetTitle(item)||undefined;
  const duration=item?.content?.playtime||item?.playtime||item?.runtime;
  const durationToShow=<Localised node="general.timing.minutes" variables={{minutes:Math.round(duration/60)}} />



  const description=getAssetDescription(item)
  const backgroundImage=()=>{
    const path=item?.content?.editorial?.image.path||item?.editorial?.image.path||item?.images?.image_path
    return `url(${images.getSRContentImage(path,'400x225')})`
  }
  const contentName=`${getTranslation("general.episode_short")}${item?.episodeData?.episode_number}`


  return (
    <div className={`playlist-detail-wrapper ${deviceType}`}>
      <div className="playlist-item-info">
        <div className="playlist-item-image" style={{backgroundImage:backgroundImage()}}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div className="playlist-item-filter" onClick={()=>playCollectionItemFromList(item)}>
          <img src={playButton} alt={title}/>
          </div>
        </div>
        <div className="playlist-item-info-detail">
          {type==="playlist"?`${title}`: `${contentName}: ${title}`  }
           <div className="info-detail-duration">{durationToShow}</div>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
           <button className="content-more-info" type="button" onClick={()=>{
             showMoreDetail(item)
             store.dispatch(toggleTabBar())
           }}><Localised node="buttons.more"/></button>
        </div>
      </div>
      <div className="playlist-item-description">
        {description}
      </div>

    </div>
        )


}


export default PlayListDetailItem;
