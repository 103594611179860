



// eslint-disable-next-line import/no-cycle
// import store from "../storage/store";

export const mobileSize = 992
export const aspectRatio = 0.5625
export const mobileEpgSliderSize = 220
export const desktopEpgSliderSize = 325
export const mobileEpgSlideHeight=60
export const desktopEpgSlideHeight=70
export const channelPageMobileHeaderSize = 272
export const DISPLAY_HIDE_UI_DURATION=process.env.REACT_APP_HIDE_UI_DURATION;

export const getMobileDevicePlatform = () => {
  let devicePlatform = ''
  if (navigator.userAgent.match(
    /Android/i)) {devicePlatform = 'Android'} else if (navigator.userAgent.match(
    /iPhone|iPad|iPod/i)) {devicePlatform = 'IOS'}
  return devicePlatform
}


export const  SVS_B=(eAmt, where)=> {
  if(where === "center" || where === "")
    window.scrollBy(0, eAmt / 2);
  if (where === "top")
    window.scrollBy(0, eAmt);
}

export const SmoothVerticalScrolling=(e, time, where)=> {
  const eTop = e.getBoundingClientRect().top;
  const eAmt = eTop / 100;
  let curTime = 0;
  while (curTime <= time) {
    window.setTimeout(SVS_B, curTime, eAmt, where);
    curTime += time / 100;
  }
}





export const changeHeaderTransition = (scrolledElement, headerId, background='#0a2244') => {

  const headerDefault = document.getElementById('header')
  const headerElement = document.getElementById(headerId) || headerDefault;
  if (scrolledElement.current && headerElement) {
    if (scrolledElement.current.scrollTop > 50) {
      headerElement.style.transition = 'background-color 150ms ease-in-out'
      headerElement.style.background = background;
    } else {
      headerElement.style.transition = 'background-color 150ms ease-in-out'
      headerElement.style.background = 'transparent'

    }
  }
}

export const getOrientation = () => {
  const orient=  window?.screen?.orientation?.angle || window.orientation ;
  if (orient === 90 || orient === -90 || orient === 270 ||
    orient === -270) {
    return 'landscape'
  }
  return 'portrait'
}



export const isPortrait = () => {
  const orient=  window?.screen?.orientation?.angle || window.orientation ;
  if (orient === 90 || orient === -90 || orient === 270 ||
    orient === -270) {
    return 'landscape'
  }
  return 'portrait'
}





export const epgSlideSize = (screenSize,size, id) => {
  const parameters = {
    slideWidth: mobileEpgSliderSize,
    slidesPerView: (screenSize-15)/(mobileEpgSliderSize+10),
    navigation: false,
    spaceBetween: 10,
  }
  if (size==="desktop") {
    parameters.slideWidth = desktopEpgSliderSize
    parameters.slidesPerView = (screenSize*0.883)/(desktopEpgSliderSize+10)
    parameters.navigation = {
      nextEl: `#next_${id}`,
      prevEl: `#prev_${id}`,
    }

  }

  return parameters
}

