/* eslint-disable react/button-has-type */
import React from 'react'
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import axios from "axios";
import history from "../../storage/history";
import Header from "../../components/header";
import TabBar from "../../components/tab-bar";
import up from "../../assets/rlaxxTV_Icons_SVG_220114/icon/indicator-arrow-up/white.svg";
import Spinner from "../../components/spinner";
import ContentDetail from "../content-detail/single-item";
import './style.scss'
import { PAGES, trackPageView } from '../../helpers/tracking'
import RotateWarning from "../rotate-warning";
import PlayerContext from "../../storage/contexts/PlayerContext";
import {changeHeaderTransition, isPortrait, mobileSize} from "../../helpers/measurements";
import SwiperSlider from '../../components/OnDemandSlider';
import Localised from "../../components/localised";
import {appendSRParams} from "../../storage/sr-properties";
import {getPartnerIdentifierFromAsset} from "../../helpers/channels";
import {getAssetFromID} from "../../helpers/assets";
import {getMultipleSerieData, getSRAssetMetadata} from "../../helpers/partners/sportradar";
import store from "../../storage/store";
import {updateVODData} from "../../storage/redux/app-data/actions";
import CollectionDetail from "../content-detail/collection-item";




class OnDemandPage extends React.Component {
  constructor(props) {
    super(props);
    const {vod} =this.props;
    this.sliders=vod;
    this.refContentDetail=React.createRef();
    this.refSection = React.createRef();
    this.refDemandPage = React.createRef();
    this.renderRows = this.renderRows.bind(this);
    this.selectItemToShow = this.selectItemToShow.bind(this);
    this.closeItem = this.closeItem.bind(this);
    this.state = {
      loading:true,
      selectedItem: {
        assetType: undefined,
        partnerId: undefined,
        assetId: undefined,
        teaserType: undefined,
        pageId: undefined,
      },
    };
  }

  componentDidMount() {
    sessionStorage.removeItem('onDemandChannelUrl');
    // eslint-disable-next-line react/prop-types
      this.generateContinueWatchingModule()
          const {
            match: {
              params: {assetId},
            },
          } = this.props;


          const {current} = this.context;
          if (current) current.mute();
          if (this.refDemandPage.current)
            this.refDemandPage.current.addEventListener("scroll", () => {
              changeHeaderTransition(this.refDemandPage, "on-demand-header");
            });
          trackPageView(PAGES.ON_DEMAND);

          if (assetId) this.getAssetIdFromPathname().catch(() => this.closeItem());


        }







  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { assetId },
      },
    } = this.props;

    if (prevProps.match.params.assetId !== assetId && assetId){
      this.getAssetIdFromPathname().catch(()=>{
        this.closeItem()});
    }
  }

  componentWillUnmount() {
    if (this.refDemandPage.current)
      this.refDemandPage.current.removeEventListener("scroll", () => {
        changeHeaderTransition(this.refDemandPage, "on-demand-header");
      });
    clearTimeout(this.selectedItemTo)
  }



  getAssetFromAssetId=assetId=> {
    const { vod } = this.props;
    let asset;
    return new Promise(resolve=>{
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < vod.length; i++) {
        // eslint-disable-next-line eqeqeq
        asset = vod[i].content.find((item) => Number(item.id) == Number(assetId));
        if (asset){
          resolve(asset);
        }
      }
      if(!asset){
        getAssetFromID(assetId,'SportRadar').then(res=>{
          // eslint-disable-next-line prefer-destructuring
          asset = res.data.data.content[0]
        }).catch(()=>asset=undefined).then(()=>resolve(asset))
      }
    })
  }





  getAssetIdFromPathname() {
    const {
      match: {
        params: { assetId },
      },
    } = this.props;
    const {selectedItem}=this.state;
    if(Number(assetId)===Number(selectedItem.assetId) ) Promise.resolve();
    return new Promise((resolve) => {
      this.selectedItemTo = setTimeout(() => {
        this.getAssetFromAssetId(Number(assetId)).then(asset=>{
          if(!asset){throw new Error()}
          if(asset?.id){ const assetToShow = {...asset,
            assetType: "vod",
            assetId: asset.id,
            partnerId: getPartnerIdentifierFromAsset(asset),
            pageId:asset?.page_id
          };
            this.selectItemToShow(assetToShow)
            this.forceUpdate(resolve)

        }
       }).catch(()=>{
         this.closeItem() })
      },150)})
  }


  generateContinueWatchingModule = () => {
    const continueWatching = JSON.parse(localStorage.getItem('continue_watching'));
    if (continueWatching) {
      const { vod } = this.props;
      const continueWatchingModule = {};
      continueWatchingModule.content = continueWatching;
      continueWatchingModule.id=100;
      continueWatchingModule.layout = 'Content Box Horizontal Text Below';
      continueWatchingModule.title = { en: 'on_demand.content_lane.continue_watching' };
      continueWatchingModule.description = { en: 'Continue Watching' };
      continueWatchingModule.type = 'horizontal-box';

      if (!vod.some(e => e.title.en === continueWatchingModule.title.en)) {
        vod.splice(2, 0, continueWatchingModule);
      } else {
        vod[2] = continueWatchingModule;
      }

      store.dispatch(updateVODData(vod)).then(() => {
          this.sliders = vod;
          this.setState({
            loading: false
          })
        }
      );
    }else{
      this.setState({loading:false})
    }
    // eslint-disable-next-line react/destructuring-assignment

  };


  requestNextPage = (row) => {
    const { vod } = this.props;
    const currentRow = vod.indexOf(row);
    const module = vod[currentRow];
    const {
      meta,
      links: { next },
    } = module;

    const currentPage = meta?.current_page;

    // todo: concat the content
    // todo: override meta and links

    if (next && !this.requesting) {
      this.requesting = true;

      axios
        .get(appendSRParams(next))
        .then(
          ({ data }) =>
            new Promise(res => {
              if (data.meta.current_page > currentPage)
                if (currentRow === 0) {
                  res(data);
                } else {
                  const seriesIds = [];
                  // eslint-disable-next-line no-param-reassign
                  data.data.content = data.data.content.map(content => {
                    const serieId = !content.page_id
                      ? getSRAssetMetadata(content, 'seriesId')
                      : content.editorial?.translations?.en?.description?.split('#')[1];
                    if (serieId) seriesIds.push(serieId);
                    return { ...content, serieId };
                  });

                  if (seriesIds && seriesIds.length > 0) {
                    getMultipleSerieData(seriesIds)
                      .then(resp => {
                        // eslint-disable-next-line no-param-reassign
                        data.data.content = data.data.content.map(content => {
                          if (content?.serieId) {
                            // eslint-disable-next-line no-param-reassign,eqeqeq
                            content.serieData = resp.find(serieInfo => serieInfo?.id == content?.serieId);
                            return content;
                          }
                          return content;
                        });
                        res(data);
                      })
                      .catch(() => {
                        console.error('Failed loading seriesData');
                        res(data);
                      });
                  } else {
                    res(data);
                  }
                }
            })
        )
        .then(parsedData => {
          module.meta = parsedData.meta;
          module.links = parsedData.links;

          vod[currentRow].content = [...vod[currentRow].content, ...parsedData.data.content];
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error("Couldn't load the next page:", error);
        })
        .then(() => {
          this.requesting = false;
          this.forceUpdate();
        });
    }
  };


  closeItem() {
    sessionStorage.setItem('seasonTab', undefined);
    this.setState({scrolled:0})
    this.setState(
      {
        selectedItem: {
          assetType: undefined,
          partnerId: undefined,
          assetId: undefined,
          teaserType: undefined,
          pageId: undefined,
        },
      },
      () => {
        history.push("/on-demand");
      }
    );
  }

  scrollTop() {
    if (this.refSection.current) {
      this.refDemandPage.current.scrollTop = this.refSection.current.offsetTop;
    }
  }

  selectItemToShow(asset) {
    this.setState({ selectedItem: asset }, () => {
      const { selectedItem } = this.state;
      history.push(`/on-demand/asset/${selectedItem.assetId}`);
    });
  }




  renderRows() {
    const { screenSize, deviceType } = this.props;
    if (this.sliders.length > 0) {
      return this.sliders.map((row) => (
        <SwiperSlider
          key={row.id}
          data={row}
          deviceType={deviceType}
          screenSize={screenSize}
          selectItem={this.selectItemToShow}
          closeItem={this.closeItem}
          moreContent={this.requestNextPage}
        />
      ));
    }
    return <Spinner />;
  }

  renderContentDetail() {
    const { selectedItem,scrolled } = this.state;
    if(selectedItem.teaserType==="collection") return <CollectionDetail
      assetType={selectedItem.assetType}
      assetId={selectedItem.assetId}
      partnerId={selectedItem.partnerId}
      closeItem={this.closeItem}
      selectItem={this.selectItemToShow}
      collectionAsset={selectedItem}
      teaserType={selectedItem.teaserType}
      pageId={selectedItem.pageId}
      pageView={PAGES.ON_DEMAND}
      scrolled={scrolled}
      scrollTop={this.scrollTopDetailPage}
    />
    return (
      <ContentDetail
        assetType={selectedItem.assetType}
        assetId={selectedItem.assetId}
        partnerId={selectedItem.partnerId}
        closeItem={this.closeItem}
        selectItem={this.selectItemToShow}
        teaserType={selectedItem.teaserType}
        pageId={selectedItem.pageId}
        pageView={PAGES.ON_DEMAND}
      />
    );
  }




  render() {
    const { screenSize, deviceType } = this.props;
    const { selectedItem, loading, tabBarVisible } = this.state;
    return (
      <div
        id="on-demand-page"
        ref={this.refDemandPage}
        className={`on-demand-page ${deviceType==="desktop" && "desktop"}`}
      >
        {(deviceType==="mobile") && isPortrait() === "landscape" && (
          <RotateWarning type="portrait" />
        )}
        <div id="on-demand-header" className="on-demand-header">
          <Header screenSize={screenSize} deviceType={deviceType} />
        </div>
        <div ref={this.refSection} className={`on-demand-sections ${deviceType==="tablet" && "tablet"} ${isPortrait()}`}>
          {loading?<div className="loading"><Spinner/></div>:this.renderRows()}
        </div>
        <div className={`back-to-top ${deviceType==="desktop" && "desktop"}`}>
          <button
            onClick={() => {
              this.scrollTop();
            }}
          >
            <img src={up} alt="up-arrow" /> <Localised node="buttons.back_to_top" />
          </button>
        </div>

        {(deviceType!=="desktop"||screenSize<mobileSize)  &&
          <TabBar visible={tabBarVisible}  deviceType={deviceType}/>}
        {selectedItem.assetType && selectedItem.assetId && selectedItem.partnerId && (
          <div className="detailed-item-wrapper">
            <div id="selectedItem" ref={this.refContentDetail}
                 onScroll={()=>
                 {
                   if(selectedItem.teaserType==="collection"){
                       this.setState({scrolled:this.refContentDetail.current.scrollTop})
                     }
                   else{changeHeaderTransition(this.refContentDetail,"detail-header",
                     deviceType==="desktop"?"linear-gradient(180deg, #0A1320 0%, rgba(10, 19, 32, 0.75) 59.9%, rgba(10, 19, 32, 0) 100%)":"#0a2244")
                 }  }

                 }
                 className={`selected-item-detail-page  ${deviceType}`}>
              {this.renderContentDetail()}
            </div>
          </div>
        )}
      </div>
    );
  }
}

OnDemandPage.propTypes={
  screenSize:PropTypes.number.isRequired,
  vod:PropTypes.arrayOf('object')

}


OnDemandPage.defaultProps={
  vod:undefined
}

OnDemandPage.contextType=PlayerContext;

const mapStateToProps = state => ({
  screenSize:state.app.screenSize,
  vod:state.app.vod,
  channels:state.app.liveChannels,
  deviceType:state.app.deviceType
});


export default connect(mapStateToProps, null, null)(OnDemandPage);
