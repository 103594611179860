import React from 'react'
import ProgressRing from "../../progress-ring";
import Localised from "../../localised";
import './style.scss'
// import history from "../../../storage/history";
import {isPortrait} from "../../../helpers/measurements";



// eslint-disable-next-line react/prop-types
const AdCounterRing = React.memo(({ adRemaining, adTotal, UIActive, screenSize, deviceType }) => {
    const position=()=>{
      if(deviceType==="desktop"){
        return UIActive?{}:{top:"5.6%"}
        // eslint-disable-next-line no-else-return
      }else if(deviceType!=="desktop"&& isPortrait()==="landscape"){
        return {top:'84%', left:'81%', bottom:"16%", right:'19%', position:"fixed"}
      }
      else{
        return {top:`${screenSize*0.5625-25}px`}
      }
    }

    const progressRingSettings= (deviceType==="desktop"||(deviceType==="tablet"&&isPortrait()==="landscape"))
      ?
      { radius:17.5, stroke:2}:{radius:10, stroke:1 }

    return(
      <div className={`ads-countdown  ${deviceType}` } style={position()}>
        <div className="ads-countdown-ring">
          {adRemaining>=0 && adTotal && adTotal > 0&&
          <div className="ads-countdown-remaining">{Math.floor(adRemaining)}</div>}

          {adRemaining>=0 && adTotal && adTotal > 0 &&
          <div className="ads-countdown-progress-ring">
            <ProgressRing radius={progressRingSettings.radius}
                          stroke={progressRingSettings.stroke}
                          progress={adTotal ? (adRemaining * 100) / adTotal : 100}
            />
          </div>
          }
        </div>
        <div className="ads-countdown-text">
          { adRemaining>=0 && <Localised node="general.ads.advertisement"/>}
        </div>
      </div>)
  }
)

export  default  AdCounterRing;
