/* eslint-disable import/no-cycle */
import qs from 'query-string';
import { v4 as uuidv4 } from "uuid";
import history from './history';


const APP_PROPERTIES_STORE = 'RLX_APP_PROPERTIES';



const appProperties = {
  fallback_language: 'en',
  video: 'videojs',
  brand: 'Web',
  gdprAccepted: false,
  targetedAds: true,
  homeHintAccepted: false,
  showPromoted: true,
  portfolioKey:"",
  sessionId: uuidv4(),
  excluded_ids: undefined,
  returningUser: false
};

export const setAppProperty = (property, value) => {
  appProperties[property] = value;

  // eslint-disable-next-line no-use-before-define
  saveAppProperties();
};



export const saveAppProperties = () => {
  localStorage.setItem(APP_PROPERTIES_STORE, JSON.stringify(appProperties));
};

export const initAppProperties = () => {
  let savedProperties = {};
  try {
    const serializedProperties = localStorage.getItem(APP_PROPERTIES_STORE);
    if (serializedProperties) {
      savedProperties = JSON.parse(serializedProperties);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error trying to load the rlaxx properties:', e);
    localStorage.removeItem(APP_PROPERTIES_STORE);
  }

  const parsed = qs.parse(window.location.search || history.location.search);

  // this.fallback_language =
  //   parsed.lng && parsed.lng.length === 2
  //     ? parsed.lng
  //     : localStorage.getItem(CONSTANTS.STORAGE_SAVED_LANGUAGE) || 'en';

  appProperties.video = parsed.video || savedProperties.video || 'videojs';
  appProperties.brand =
    parsed.brand || parsed.customer || savedProperties.brand || savedProperties.customer || undefined;
  appProperties.excluded_ids = parsed.excluded_ids || savedProperties.excluded_ids || undefined;
  appProperties.homeHintAccepted = savedProperties.homeHintAccepted || false;
  appProperties.portfolioKey = parsed.portfolioKey || process.env.REACT_APP_RLAXX_BACKEND_TOKEN;


  appProperties.gdprAccepted = savedProperties.gdprAccepted || false;
  appProperties.showPromoted =
    savedProperties.showPromoted !== undefined ? savedProperties.showPromoted : appProperties.showPromoted;
  appProperties.returningUser = savedProperties.returningUser || false;
  appProperties.targetedAds = savedProperties.targetedAds !== undefined ? savedProperties.targetedAds : true;

  saveAppProperties();


  return appProperties;
};

export default appProperties;
