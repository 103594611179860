/* eslint-disable */
import axios from 'axios';
import store from '../storage/store';
import appProperties from '../storage/app-properties';
import {getSRAdCategory, getSRAssetSerieTitle} from './partners/sportradar';
import { getPANTAFLIXAdCategory } from './partners/pantaflix';
import { getVEVOAdCategory } from './partners/vevo';
import { EVENTS, trackAdEvent } from './tracking';
import {getAssetEventLabel, getAssetMetadata} from './assets';
import { AD_TYPE_MIDROLL } from './ads/ads';
import { v4 as uuidv4 } from 'uuid';
import {getChannelFromID, getChannelFromSRCat2} from "../storage/redux/app-data/actions";



export const getCurrentDate = () => new Date();

export const partnerIdentifiers = {
  PANTAFLIX: 'Pantaflix',
  SPORTRADAR: 'SportRadar',
  WURL: 'WURL',
  VEVO: 'Vevo'
};

export const getPartnerIdentifierFromChannel = rlaxxChannel => {
  if (rlaxxChannel && rlaxxChannel.externalData) {
    if (rlaxxChannel.externalData[partnerIdentifiers.VEVO]?.apiData?.epg) {
      return partnerIdentifiers.VEVO;
    }
    if (rlaxxChannel.externalData[partnerIdentifiers.SPORTRADAR]?.apiData?.epg) {
      return partnerIdentifiers.SPORTRADAR;
    }
    if (rlaxxChannel.externalData[partnerIdentifiers.WURL]?.apiData?.epg) {
      return partnerIdentifiers.WURL;
    }
    if (rlaxxChannel.externalData[partnerIdentifiers.PANTAFLIX]?.apiData?.epg) {
      return partnerIdentifiers.PANTAFLIX;
    }
  }
  return null;
};

export const useFunkemediaAds = partnerIdentifier => {
  switch (partnerIdentifier) {
    case partnerIdentifiers.VEVO:
      return false;
    default:
      return true;
  }
};

export const getParsedEpgId = epgId => {
  let parsedEpgId = epgId;
  if (epgId?.startsWith('vevo')) {
    // VEVO Block
    parsedEpgId = `${epgId}-content`
  }
  // if (epgId == '43409') parsedEpgId = '43407';
  return parsedEpgId;
};

export const getAdCategory = (partnerIdentifier, ...params) => {
  switch (partnerIdentifier) {
    case partnerIdentifiers.VEVO:
      return getVEVOAdCategory();
    case partnerIdentifiers.PANTAFLIX:
      return getPANTAFLIXAdCategory();
    case partnerIdentifiers.SPORTRADAR:
      return getSRAdCategory(params[0]);
    default:
      return '';
  }
};

export const getPartnerIdentifierFromAsset = asset => {
  if (asset) {
    if (asset.partner) {
      if (asset.partner.startsWith('vevo')) return partnerIdentifiers.VEVO;
      else if (asset.partner.startsWith('WURL')) return partnerIdentifiers.WURL;
      else if (asset.partner === 'pantaflix') return partnerIdentifiers.PANTAFLIX;
    } else if (asset.type == 2) {
      return partnerIdentifiers.SPORTRADAR;
    }
  }
  return partnerIdentifiers.SPORTRADAR;
};

export const isCurrentChannelDisplayedInEpg = () => {
  const { currentChannel, liveChannels, epg } = store.getState().app;
  for (let i = 0; i < liveChannels.length; i++) {
    if (liveChannels[i]?.id == currentChannel?.id && !liveChannels[i]?.displayInEpg) {
      return false;
    }
  }
  return true;
};

export const getAssetFromEPG=(assetId, epgId)=>{
  const { epg } = store.getState().app
  if(epg[epgId]){
    return epg[epgId].assets.filter(asset=>Number(asset.id)===Number(assetId))[0]
  }
  return new Error("Asset is not found")
}


export const getCurrentLiveChannelData = () => {
  const { currentChannel, liveChannels } = store.getState().app;
  for (let i = 0; i < liveChannels.length; i++) {
    if (liveChannels[i].id == currentChannel.id) return liveChannels[i];
  }
  return undefined;
};

export const getRequestedAdCount = (adsData, adPartnerId = null, adType) => {
  const { count_mid_rolls, count_pre_rolls } = adsData;

  if (adPartnerId == 1) return 0;
  // eslint-disable-next-line camelcase
  return adType === AD_TYPE_MIDROLL ? count_mid_rolls : count_pre_rolls;
};

export const getVastRequestBody = (adCategory, adType, asset) => {
  const body = {};
  const uuid=uuidv4();
  body.platform = 'web';
  body.useragent = navigator.userAgent;
  body.websiteUrl = window.location.href;
  body.adCategory = adCategory;
  body.adType = adType;
  body.deviceBrand =  appProperties.brand;
  body.deviceOS = getOS();
  body.uiLanguage = store.getState().locales.language;
  body.deviceLanguage = navigator.language || navigator.userLanguage;

  body.ifa = uuid;
  body.sessionId = appProperties.sessionId;
  body.uuid = uuid;

  body.videoWidth = window.innerWidth;
  body.videoHeight = window.innerHeight;

  const consent=localStorage.getItem('euconsent-v2')
  body.gdprConsent = consent?consent:0;

  if (appProperties.targetedAds) {
    body.gdprOptin = 1;
    body.gdprOptout = 0;
  } else {
    body.gdprOptin = 0;
    body.gdprOptout = 1;
  }



  if (!asset.partner || asset.partner != 'vevo-content') {
    body.contentId = asset.playerSettings?.videoid;
    body.contentTitle = asset.playerSettings?.title;
    body.contentChannel =
      asset.category2?.name || asset.content?.event?.category2?.name || asset.event?.category2?.name;
    body.contentNetwork =
      asset.category1?.name || asset.content?.event?.category1?.name || asset.event?.category1?.name;
    body.contentSeries = getSRAssetSerieTitle(asset);
    body.contentLength = asset.playtime || asset.duration || asset.content.playtime;
    if (asset.clientMetadata || asset.event?.clientMetadata || asset.content.event?.clientMetadata) {
      body.contentEpisode = getAssetMetadata(asset, 'seriesEpisodeNumber');
      body.contentSeason = getAssetMetadata(asset, 'seriesSeasonNumber');
      body.contentLanguage = getAssetMetadata(asset, 'language');
      body.contentRating = getAssetMetadata(asset, 'rating');
    }
  }
  return body;
};

export const getOS=()=>{
  const userAgent = window.navigator?.userAgent;
  const platform = window.navigator?.userAgentData?.platform || window.navigator.platform;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'macOS'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'macOS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (/Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
}

export const CUSTOM_VAST_ERRORS = {
  NO_PARTNER: 'No valid ad partner was found.',
  NO_ADS_ADPOD: 'There are no ads on the sorted pod.',
  RESPONSE_EMPTY: 'No Content'
};

const vastParser = new window.VAST.VASTParser();

export const findVastPartner = (adsData, vastPartners, adType, asset) => {
  const vastPartner = vastPartners.shift();
  const { adPartner, adPartnerId, vastUrl } = vastPartner;




  if (!vastPartner) {
    return Promise.reject(CUSTOM_VAST_ERRORS.NO_PARTNER);
  }

  // Request Ads
  trackAdEvent(
    EVENTS.AD.CATEGORY,
    EVENTS.AD.ACTIONS.REQUEST,
    `${adPartner};${getAssetEventLabel(asset)};${adType}`,
    getRequestedAdCount(adsData, adPartnerId, adType)
  );

  return vastParser
    .getAndParseVAST(vastUrl)
    .then(vast => {
      if (!vast.ads || vast.ads.length <= 0) {
        throw CUSTOM_VAST_ERRORS.NO_ADS_ADPOD;
      }

      return { vast, vastPartner };
    })
    .catch(error => {
      console.error(`[VAST Error] Error getting ads for AP#${adPartnerId}:`, error);
      if (
        error === CUSTOM_VAST_ERRORS.NO_ADS_ADPOD ||
        error.response?.status === 204 ||
        error.message.includes(CUSTOM_VAST_ERRORS.RESPONSE_EMPTY)
      ) {
        // Empty Ads
        trackAdEvent(
          EVENTS.AD.CATEGORY,
          EVENTS.AD.ACTIONS.RESPONSE_EMPTY,
          `${adPartner};${getAssetEventLabel(asset)};${adType}`
        );
      } else {
        // Failed Request
        trackAdEvent(
          EVENTS.AD.CATEGORY,
          EVENTS.AD.ACTIONS.REQUEST_FAILED,
          `${adPartner};${getAssetEventLabel(asset)};${adType}`
        );
      }
      return findVastPartner(adsData, vastPartners, adType, asset);
    });
};

export const getVastAds = (channelId, adCategory, adType, asset) => {
  const body = getVastRequestBody(adCategory, adType, asset);
  return axios
    .post(
      `${process.env.REACT_APP_RLAXX_BACKEND}api/channels/${channelId}/vastv2?api_token=${appProperties.portfolioKey}`,
      body
    )
    .then(response => {
      const { data, partners } = response.data;
      trackAdEvent(
        EVENTS.AD.CATEGORY,
        EVENTS.AD.ACTIONS.BLOCK_START,
        `${getAssetEventLabel(asset)};${adType}`,
        getRequestedAdCount(data, response.data.partners.adPartnerId, adType)
      );
      return response.data;
    })
    .then(res => {
      const { data, partners } = res;
      return findVastPartner(data, partners, adType, asset);
    })
    .catch(e => {
      console.error(e);
      trackAdEvent(EVENTS.AD.CATEGORY, EVENTS.AD.ACTIONS.BLOCK_FAILED, `${getAssetEventLabel(asset)};${adType}`);
    });
};

export const getChannelFromAsset = currentVideo =>
  new Promise((resolve, reject) => {
    if (currentVideo?.channelId) {
      store
        .dispatch(getChannelFromID(currentVideo.channelId))
        .then(channel => {
          resolve(channel);
        })
        .catch(e => {
          console.error(e);
          const { currentChannel } = store.getState().app;
          reject(currentChannel);
        });
    } else {
      store
        .dispatch(
          getChannelFromSRCat2(
            currentVideo?.category2?.id ||
            currentVideo?.event?.category2?.id ||
            currentVideo?.content?.event?.category2?.id ||
            currentVideo?.sport_radar_categories?.category2?.id
          )
        )
        .then(channel => {
          resolve(channel);
        })
        .catch(e => {
          console.error(e);
          const { currentChannel } = store.getState().app;
          reject(currentChannel);
        });
    }
  });
