/* eslint-disable react/jsx-boolean-value */
import React from 'react'
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import {Swiper, SwiperSlide} from "swiper/react/swiper-react";
import  './style.scss'
import 'swiper/swiper-bundle.css'
import 'swiper/swiper.scss'
import 'swiper/modules/navigation/navigation.scss'
import SwiperCore, {Mousewheel, Navigation} from "swiper";
import history from "../../storage/history";
import leftArrow from "../../assets/rlaxxTV_Icons_SVG_220114/icon/indicator-arrow-left/white.svg"
import rightArrow from "../../assets/rlaxxTV_Icons_SVG_220114/icon/indicator-arrow-right/white.svg"
import {mobileSize} from "../../helpers/measurements";



SwiperCore.use([Mousewheel, Navigation]);



class ChannelBarSlider extends React.Component {
  constructor(props) {
    super(props);
    this.refChannelButton = React.createRef();
    const { currentFocusedChannel } = this.props;
    this.state = {
      focusedChannel: currentFocusedChannel,
      sliderMoving: false,
    };
    const{channels}=this.props;
    // eslint-disable-next-line no-restricted-globals
    this.channelArray=channels.filter(ch=>ch.displayInLiveTV !== false && ch.teaserType !=='hidden')

    this.slideSetting = {
      // slidesPerView: 13,
      // navigation: { nextEl: "#next-arrow", prevEl: "#prev-arrow" },
      // spaceBetween: 15,
    };
  }

  componentDidMount() {
    const{currentFocusedChannel, refChannelBar}=this.props;
    this.channelsSwiper.updateSlides();
    if (refChannelBar.current) {
      this.channelsSwiper.slideTo(this.findCurrentChannelIndex(currentFocusedChannel)+this.channelArray.length)
      refChannelBar.current.addEventListener("mouseenter", () => {
        this.setState({ active: true });
      });
      refChannelBar.current.addEventListener("mouseleave", () => {
        this.setState({ active: false });
      });
    }

    window.addEventListener('resize',()=>{
      const {screenSize}=this.props;

        if(this.channelsSwiper?.activeIndex && screenSize>=mobileSize) this.channelsSwiper.update()
    })

  }



  componentDidUpdate(prevProps) {
    // eslint-disable-next-line no-unused-expressions

    const { currentLiveChannel, findChannelPositionFromID } = this.props;
    // eslint-disable-next-line eqeqeq
    if (prevProps.currentLiveChannel != currentLiveChannel && this.channelArray.length>0) {
      const channel = this.channelArray[findChannelPositionFromID(currentLiveChannel.id)];
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ focusedChannel: channel },()=>{
        this.channelsSwiper.slideTo(this.findCurrentChannelIndex(currentLiveChannel)+this.channelArray.length)
      });
    }
  }


  componentWillUnmount() {
    const {refChannelBar}=this.props;
    if(refChannelBar.current){
      refChannelBar.current.removeEventListener("mouseenter", () => {
        this.setState({ active: true });
      });
      refChannelBar.current.removeEventListener("mouseleave", () => {
        this.setState({ active: false })})

    }

    this.channelsSwiper.destroy();


  }



  getFocusedChannel(channel) {
    const { onFocusedChannel } = this.props;
    if (typeof onFocusedChannel === "function") {
      this.setState({focusedChannel:channel},()=>{
        onFocusedChannel(channel);
      })

    }
  }



  setNewChannel(channel) {
    const { focusedChannel, sliderMoving } = this.state;
    const {currentLiveChannel}=this.props;
    if (Number(focusedChannel.id) !== Number(currentLiveChannel.id)  && !sliderMoving) {
      this.setState({focusedChannel: channel}, () => {
       // this.sliderCentering(channel)
       history.push(`/home/${focusedChannel.id}`)
      });
    }
  }

  // sliderCentering(channel){
  //   if(this.channelsSwiper){
  //     const index=this.findCurrentChannelIndex(channel)-6
  //     if(index<0){
  //       this.channelsSwiper.slideToLoop(this.channelArray.length+index,500)
  //     }
  //     else{
  //       this.channelsSwiper.slideToLoop(index,500)
  //     }
  //
  //   }
  // }

  sliderStopped = () => {
    const { sliderMoving } = this.state;
    if (sliderMoving === true) {
      this.sliderStoppedTO = setTimeout(() => {
        this.setState({ sliderMoving: false }, () => {
          clearTimeout(this.sliderStoppedTO);
        });
      }, 1);
    }
  };

  sliderStart = () => {
    const { sliderMoving } = this.state;
    if (sliderMoving === false) {
      this.setState({ sliderMoving: true });
    }
  };

  findCurrentChannelIndex(channel){
    const channelElement=this.channelArray.find(ch=>Number(ch.id)===Number(channel.id))
    return this.channelArray.indexOf(channelElement)
  }

  resetChannel() {
    const { resetFocusedChannel, currentFocusedChannel } = this.props;
    if (typeof resetFocusedChannel === "function"){
      this.setState({focusedChannel:currentFocusedChannel},()=>{
        resetFocusedChannel();
      })

    }
  }

  displayUI(){
    const { displayUI}=this.props;
    if(typeof displayUI ==="function"){
      displayUI();
    }
  }


  renderChannelIcon(ch){

    const {currentLiveChannel}=this.props;
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
          ref={this.refChannelButton}
          key={ch.id}
          onMouseLeave={() => {
            this.resetChannel();
          }}
          onMouseEnter={() => {
            this.getFocusedChannel(ch);
          }}
          onClick={() => {
            this.setNewChannel(ch);
          }}
          className="channel-button"
        >
          <img
            src={Number(currentLiveChannel.id) === Number(ch.id) ? ch.colour : ch.white}
            className={`channel-icon ${
              Number(currentLiveChannel.id) === Number(ch.id) && " focus"
            }`}
            alt="rlaxx-channel-logo"
          />
        </div>

      )
  }

  render() {
    const { active, focusedChannel } = this.state;
    const { currentLiveChannel, refChannelBar } = this.props;
    return (
      currentLiveChannel &&
      this.channelArray.length > 0 &&
      focusedChannel && (
        <div className="channel-bar-wrapper" ref={refChannelBar}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
          <div id="prev-arrow" role="button" onClick={()=>{
            if(this.channelsSwiper) this.channelsSwiper.slideToLoop(this.channelsSwiper.realIndex-1,500)
          }} className={`swiper-button-prev  ${active && "active"}`}>
            <img src={leftArrow} className="left-arrow-image" alt="left-arrow" />
          </div>
          <Swiper
            slidesPerView="auto"
            onSwiper={(instance) => {this.channelsSwiper = instance;}}
            onSlideChange={() => {this.displayUI()}}
            onTouchMove={() => this.sliderStart()}
            onTouchEnd={() => this.sliderStopped()}
            slideToClickedSlide
            speed={500}
            loop={true}
            observer={true}
            resizeObserver
            watchSlidesProgress={true}
            simulateTouch={true}
            momentumRatio={1}
            preventClicks={true}
            freeMode={{
              enabled: true,
              momentumRatio: 1,
            }}
            centeredSlides
            spaceBetween={50}
            mousewheel={{ forceToAxis: true }}
            edgeSwipeDetection
            className="swiper channels"

          >
            <div className="channel-bar-blur-left" />
            {this.channelArray.map((ch) => (
              <SwiperSlide key={ch.id} className="channel-slider">
                {this.renderChannelIcon(ch)}
              </SwiperSlide>
            ))}
            <div className="channel-bar-blur-right" />
          </Swiper>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
          <div id="next-arrow" role="button" onClick={()=>{
            if(this.channelsSwiper) this.channelsSwiper.slideToLoop(this.channelsSwiper.realIndex+1,500)
          }} className={`swiper-button-next  ${active && "active"}`}>
            <img src={rightArrow} className="right-arrow-image" alt="right-arrow" />
          </div>
        </div>
      )
    );
  }
}

ChannelBarSlider.propTypes={
  // eslint-disable-next-line react/forbid-prop-types
  channels:PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  currentFocusedChannel: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  currentLiveChannel:PropTypes.object,
  findChannelPositionFromID:PropTypes.func,
  onFocusedChannel:PropTypes.func,
  resetFocusedChannel: PropTypes.func,
}

ChannelBarSlider.defaultProps={
  channels:undefined,
  currentFocusedChannel:undefined,
  currentLiveChannel:undefined,
  findChannelPositionFromID:undefined,
  onFocusedChannel:undefined,
  resetFocusedChannel:undefined,

}


const mapStateToProps= state =>({
  channels: state.app.liveChannels,
  currentLiveChannel: state.app.currentChannel,

})

export default connect(mapStateToProps)(ChannelBarSlider);








