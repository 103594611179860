/* eslint-disable react/button-has-type,jsx-a11y/control-has-associated-label,react/destructuring-assignment */
import React from "react";
// import * as PropTypes from "prop-types";
import { connect } from "react-redux";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import history from "../../storage/history";
import "./style.scss";
import logo from "../../assets/images/rlaxx_logo_mobile.svg";
import logoLong from "../../assets/images/rlaxx_logo_long.svg";
import secondSequence from "../../assets/images/second-sequence.svg";
import didomiImage from "../../assets/images/didomi_image.png";
import thirdSequence from "../../assets/images/third-sequence.svg";
import Localised from "../../components/localised";
import progressFirst from "../../assets/images/progress-first.svg";
import progressSecond from "../../assets/images/progress-second.svg";
import progressThird from "../../assets/images/progress-third.svg";
import DidomiConsentPreferences from "../../components/DidomiConsentPreferences";
import { setAppProperty } from "../../storage/app-properties";
import {PAGES, trackPageView} from "../../helpers/tracking";

class OnBoarding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phase: 0,
    };
    this.goToNextStep = this.goToNextStep.bind(this);
    this.goToPreviousStep = this.goToPreviousStep.bind(this);
  }

  componentDidMount() {
    trackPageView(PAGES.STARTUP, "welcome");
  }

  componentDidUpdate( prevState) {
    const {phase}=this.state;
    if(prevState.phase!==phase){
      trackPageView(PAGES.STARTUP, this.setTrackingPhase());
    }
  }

  setTrackingPhase() {
    const { phase } = this.state;
    switch (phase) {
      case 0:
        return "welcome";
      case 1:
        return "consent";
      case 2:
        return "finish";
      default:
        return "welcome";
    }
  }

  generateImageAndSlides() {
    const { phase } = this.state;
    let content;
    switch (phase) {
      case 0:
        content = {
          image: thirdSequence,
          progress: progressFirst,
          title: "startup.welcome.title",
          description: "startup.welcome.description",
          buttonContent: "buttons.continue",
        };
        break;
      case 1:
        content = {
          image: didomiImage,
          progress: progressSecond,
          title: "",
          description: "",
          buttonContent: "buttons.continue",
        };
        break;
      case 2:
        content = {
          image: secondSequence,
          progress: progressThird,
          title: "startup.finish.title",
          description: "startup.finish.description",
          buttonContent: "buttons.start_watching_now",
        };
        break;
      default:
        content = {
          image: thirdSequence,
          progress: progressFirst,
          title: "startup.welcome.title",
          description: "startup.welcome.description",
          buttonContent: "buttons.continue",
        };
    }
    return content;
  }

  goToHomePage() {
    const { phase } = this.state;
    if (phase === 2) {
      setAppProperty("gdprAccepted", true);
      const enteredUrl =
        this.props.location?.state?.link.indexOf("start") !== -1
          ? "/home"
          : this.props.location.state.link;
      const path = enteredUrl || "/home";
      history.push(path);
    }
  }

  goToNextStep() {
    const { phase } = this.state;
    if (phase < 2) {
      this.setState({ phase: phase + 1 });
    }
  }

  goToPreviousStep() {
    const { phase } = this.state;
    if (phase > 0) {
      this.setState({ phase: phase - 1 });
    }
  }

  renderSlides() {
    const { image, progress, title, description, buttonContent } = this.generateImageAndSlides();
    const { phase } = this.state;
    const { countryCode } = this.props;
    return (
      <div className="on-boarding-slides">
        <div className="on-boarding-image">
          <img src={image} alt="rlaxx-startup" />
        </div>
        <div className={`on-boarding-explanation ${phase === 2 && "ready"}`}>
          <img src={progress} alt="progress" />
          <div className="on-boarding-content">
            <h3>
              <Localised node={title} />
            </h3>
            <p>
              {countryCode.toLowerCase() === "us" && phase === 2 ? (
                <Localised
                  node="startup.finish.description_us_age_confirmation"
                  dangerouslySetInnerHTML
                />
              ) : (
                <Localised node={description} />
              )}
            </p>
          </div>
          <div className="on-boarding-buttons">
            {phase === 1 && (
              <DidomiConsentPreferences
                type="onboarding"
                phase={phase}
                goToNextStep={this.goToNextStep}
              />
            )}
            {/* eslint-disable-next-line no-unused-expressions */}
            <button
              onClick={() => {
                // eslint-disable-next-line no-unused-expressions
                phase === 2 ? this.goToHomePage() : this.goToNextStep();
              }}
              disabled={phase === 1}
            >
              <Localised
                node={
                  countryCode.toLowerCase() === "us" && phase === 2
                    ? "buttons.start_watching_now_us_age_confirmation"
                    : buttonContent
                }
              />
            </button>
          </div>

          {phase > 0 && (
            <button className="on-boarding-back" onClick={() => this.goToPreviousStep()}>
              <Localised node="buttons.back" />
            </button>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { deviceType } = this.props;
    const { phase } = this.state;
    return (
      <div className={`on-boarding-wrapper ${deviceType} ${deviceType === "mobile"}`}>
        <img className="logo" src={deviceType === "desktop" ? logoLong : logo} alt="rlaxx-logo" />
        <SwitchTransition>
          <CSSTransition key={phase} in appear timeout={250} classNames="fade-transition">
            <div className="on-boarding-group">{this.renderSlides()}</div>
          </CSSTransition>
        </SwitchTransition>
      </div>
    );
  }
}


const mapStateToProps = (state) => ({
  deviceType: state.app.deviceType,
  countryCode: state.app.countryCode,
});

export default connect(mapStateToProps, null, null)(OnBoarding);
