/* eslint-disable */
import React from "react";
import {DidomiSDK} from "@didomi/react";
import Localised from "../localised";




class DidomiConsentPreferences extends React.Component {

  constructor(props) {
    super(props);
    this.didomiObject={};
    this.didomiConfig={
      regulations: {
        ccpa: {
          enabled: true,
          lspa: false, // Whether your company has signed the LSPA or not (defaults to false)
        }
      }}

  }
  onDidomiReady(didomi) {
    const { type } = this.props;
    this.didomiObject = didomi;
    const gdprAcceptedFromBrowser=localStorage.getItem("RLAXX_APP_PROPERTIES", "gdprAccepted")
    const gdprAccepted=gdprAcceptedFromBrowser?gdprAcceptedFromBrowser:false;

    if (this.didomiObject.isConsentRequired() === true )
      if(type==="onboarding"){
        setTimeout(()=>{this.didomiObject.notice.show();
        },110)
      }else if(type==="splash"){
        this.didomiObject.notice.hide();
      }
    if(type==="onboarding") {
        document.documentElement.style.setProperty('--didomi-opacity', '1')
    }
  }

  /**
   * Called everytime the consent changes
   */
  consentHasChanged(cwtToken) {

  }

  goToNextPage() {
    const { type, goToNextStep, phase } = this.props;
    if (type === "onboarding" && typeof goToNextStep === "function") {
      if (phase === 1) goToNextStep();
    }
  }


  render() {
    const {type}=this.props;
    return (
      <>
        <DidomiSDK
          iabVersion={2}
          config={this.didomiConfig}
          apiKey="d032ff9a-95a8-4e88-b9fa-dd151c13354f"
          gdprAppliesGlobally={true}
          sdkPath="https://sdk.privacy-center.org/"
          embedTCFStub={true}
          onReady={this.onDidomiReady.bind(this)}
          onNoticeHidden={()=>this.goToNextPage()}
          onConsentChanged={this.consentHasChanged.bind(this)}
        />
        {/* eslint-disable-next-line react/button-has-type */}
        {type!=="splash" && <button className="lang-button" onClick={() => this.didomiObject.preferences.show()}>
          <Localised node={`settings.consent_preferences.title`} />
        </button>}
      </>
    );
  }
}

export default DidomiConsentPreferences
