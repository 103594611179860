import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import app from './redux/app-data/reducers';
import player from './redux/player-data/reducers';
import locales from './redux/locales/reducers';

// eslint-disable-next-line import/no-cycle
import * as appActions from './redux/app-data/actions';
import * as playerActions from './redux/player-data/actions';
// eslint-disable-next-line import/no-cycle
import * as localesActions from './redux/locales/actions';

export default createStore(
  combineReducers({ app, player, locales }),
  // eslint-disable-next-line no-underscore-dangle
  compose(applyMiddleware(thunk), window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f)
);

export { appActions, playerActions, localesActions };
