/* eslint-disable no-else-return */
import React from 'react'
import './style.scss'
import {getAssetTitle} from "../../../helpers/assets";
import adsTimer from '../../../helpers/ads/ads-timer';
import adsCounter from '../../../helpers/ads/ads-counter';
import history from "../../../storage/history";
import Localised from "../../localised";
import {mobileSize} from "../../../helpers/measurements";

const getNodeAfterAdPlay = () => {
    if (history.location.pathname.indexOf('/home') !== -1 || adsTimer.hasPrerollPlayed || adsCounter.hasPrerollPlayed) {
        return 'will_continue';
    }
    return 'will_start';
};




// eslint-disable-next-line react/prop-types
 const CurrentVideoFollow = React.memo(({ currentVideo, type, screenSize,UIActive }) => {

    const classList=()=>{
        if(screenSize<mobileSize){
            return 'mobile';
        }else if(!UIActive&&type===1){
                return 'inActive'

        }
        return '';
    }

     const position=()=>{
       if(screenSize>=mobileSize){
           return {bottom:"4.88vh"}
       }
       return {top:`${screenSize*0.5625-25}px`}
     }

     return (<div className={`ad-label ${classList()}`} style={position()}>
         {type === 1 ? <strong className={getAssetTitle(currentVideo).length>80 && screenSize<mobileSize && "long-text"}>
           {`${getAssetTitle(currentVideo)}${screenSize>=mobileSize ? "\u00A0":""}`} </strong> : null}
         <Localised node={`general.ads.${getNodeAfterAdPlay()}`}/>
     </div>)
 });

 CurrentVideoFollow.defaultProps=({
     type:1
 })
 export  default  CurrentVideoFollow;
