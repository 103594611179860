// eslint-disable-next-line import/no-cycle
import store from '../../storage/store';

let adInterval;
let adTimer = false;
let adElapsedTime = 0;
// eslint-disable-next-line no-unused-vars
let overrideTimer = 0;

export function getWurlStream(asset) {
  return Promise.resolve(asset);
}

export function loadWURLTimers(adDuration, adsCallback) {
  const { isAd } = store.getState().player;
  const adDurationTag = parseInt(adDuration, 10);

  // eslint-disable-next-line no-restricted-globals
  if (!isNaN(adDurationTag) && !isAd) {
    const duration = adDurationTag;
    if (!adInterval) {
      clearInterval(adInterval);
      adInterval = setInterval(() => {
        const { isBuffering, isPlaying } = store.getState().player;
        adTimer = adElapsedTime < duration;
        if (!isBuffering && isPlaying) {
          if (adTimer) {
            adElapsedTime += 1;
            if (!isAd) {
              adsCallback(true, duration - adElapsedTime, duration);
            }
          } else {
            adsCallback(false);
            adElapsedTime = 0;
            clearInterval(adInterval);
            adInterval = undefined;
          }
        }
      }, 1000);
    }
  }
}

export function stopWURLTimer(adsCallback) {
  if (adInterval) {
    clearInterval(adInterval);
    adInterval = undefined;
    adTimer = false;

    adsCallback(false);
  }

  adElapsedTime = 0;
  overrideTimer = 0;
}
