/* eslint-disable import/prefer-default-export,import/no-cycle */
// eslint-disable-next-line import/no-cycle
import axios from "axios";
import {
  getMultipleSerieData,
  getSRAssetChannelTitle,
  getSRAssetContentPartnerName,
  getSRAssetDescription,
  getSRAssetDuration,
  getSRAssetMetadata, getSRAssetSerieData,
  getSRAssetTitle,
  getSRThumbnail,
  getSRWatermark
} from './partners/sportradar';
import { parseAsLabel, trackVideoEvent} from './tracking';
import {  partnerIdentifiers } from './channels';
import contentApis from '../services/contentApis';
import appProperties from "../storage/app-properties";
import {getStreamableAsset} from "./video";
import store from "../storage/store";
import {updateContinueWatchingData} from "../storage/redux/app-data/actions";

export const getAssetFromID = (assetId, partnerIdentifier) => {
  switch (partnerIdentifier) {
    case partnerIdentifiers.SPORTRADAR:
      return contentApis.sportradar.getAsset(assetId);
    default:
      return Promise.reject(`${partnerIdentifier} is not a valid Partner Identifier`);
  }
};
export const getNextLaneContent = (incrementIndex = false) => {
  const contentLane = JSON.parse(sessionStorage.getItem('contentLane'));
  return new Promise((resolve, reject) => {
    if (contentLane) {
      if (incrementIndex) {
        contentLane.index += 1;
        sessionStorage.setItem('contentLane', JSON.stringify(contentLane));
      }
      const actualIndex = contentLane.index;
      resolve(contentLane.content[actualIndex]);
    } else reject();
  });
};
export const getNextContent = (asset, loadOnlySeries = false) => {
  const currentSerieData = getSRAssetSerieData(asset);
  const contentLane = JSON.parse(sessionStorage.getItem('contentLane'));

  return new Promise((resolve, reject) => {
    if (contentLane && !currentSerieData.id && !loadOnlySeries) {
      getNextLaneContent(true)
        .then(nextContentLaneAsset => resolve(nextContentLaneAsset))
        .catch(() => reject(null));
    } else if (currentSerieData.id) {
      getMultipleSerieData(currentSerieData.id).then(serieInfo => {
        axios
          .get(
            `${process.env.REACT_APP_RLAXX_BACKEND}api/v3/series/${currentSerieData.id}/seasons/
            ${currentSerieData.season}/episodes?api_token=${appProperties.portfolioKey}`
          )
          .then(resp => resp?.data)
          .then(currentSeasonEpisodes => {
            const currentEpisodeIndex = Object.keys(currentSeasonEpisodes).filter(
              episode =>
                currentSeasonEpisodes[episode].episodeData.episode_number === parseInt(currentSerieData.episode, 10)
            );
            const nextEpisodeIndex = parseInt(currentEpisodeIndex[0], 10) + 1;

            if (currentSeasonEpisodes[nextEpisodeIndex]) {
              resolve(currentSeasonEpisodes[nextEpisodeIndex]);
              return;
            }

            const currentSeasonIndex = serieInfo[0]?.seasons?.indexOf(parseInt(currentSerieData.season, 10));
            if (currentSeasonIndex + 1 >= serieInfo[0].seasons.length) {
              reject(null);
            } else {
              const nextSeasonIndex = (currentSeasonIndex + 1) % serieInfo[0].seasons.length;
              const nextSeasonNumber = serieInfo[0].seasons[nextSeasonIndex];
              axios
                .get(
                  `${process.env.REACT_APP_RLAXX_BACKEND}api/v3/series/${currentSerieData.id}/seasons/${nextSeasonNumber}
                  /episodes?api_token=${appProperties.portfolioKey}`
                )
                .then(resp => resp?.data)
                .then(nextSeasonEpisodes => {
                  if (nextSeasonEpisodes.length > 0) {
                    resolve(nextSeasonEpisodes[0]);
                  }
                  resolve(nextSeasonEpisodes);
                })
                .catch(() =>
                  getNextLaneContent()
                    .then(nextContentLaneAsset => resolve(nextContentLaneAsset))
                    .catch(() => reject(null))
                );
            }
          })
          .catch(() =>
            getNextLaneContent()
              .then(nextContentLaneAsset => resolve(nextContentLaneAsset))
              .catch(() => reject(null))
          );
      });
    } else if (asset.related[0] && !loadOnlySeries) {
      const {externalMatchId} = asset;
      let nextExternalMatchId;
      let assetFound;
      if (externalMatchId) {
        nextExternalMatchId = externalMatchId - 1;
        assetFound = asset.related.find(related => related.externalMatchId === nextExternalMatchId);
      } else {
        assetFound = asset.related[Math.floor(Math.random() * (asset.related.length - 1))];
      }

      // eslint-disable-next-line prefer-destructuring
      if (!assetFound) assetFound = asset.related[0];
      resolve(assetFound);
    } else {
      reject(null);
    }
  });
};



export const getAssetTitle = asset => asset?.title || getSRAssetTitle(asset);

export const getAssetMetadata = (asset, metaId) => asset?.[metaId] || getSRAssetMetadata(asset, metaId);

export const getAssetSubtitle = asset => asset?.subtitle || getAssetMetadata(asset, 'Season');

export const getAssetDescription = asset => asset?.description || getSRAssetDescription(asset);

export const getAssetChannelTitle = (asset) =>  asset?.channelTitle || getSRAssetChannelTitle(asset);

// eslint-disable-next-line import/no-mutable-exports

export const getAssetContentPartnerName = asset => asset?.contentPartnerName || getSRAssetContentPartnerName(asset);

export const getAssetThumbnail = (asset, manipulation) =>
  asset?.thumbnail || asset?.image || getSRThumbnail(asset, manipulation);

export const getAssetWatermark = asset => asset?.watermark || getSRWatermark(asset);

export const getAssetDuration = asset => asset?.duration || getSRAssetDuration(asset);


export const getCurrentAssetPlaybackType = () => {
  if (window.location.hash.indexOf('#/player') === -1) {
    return 'live';
  }
  return 'vod';
};




export const getAssetEventLabel = (asset, newPlaybackType=undefined) => {
  const getplaybackType=()=>{
    if(!newPlaybackType){
      return  getCurrentAssetPlaybackType() === 'vod' ? 'ondemand' : 'live';
    }

      return newPlaybackType;

  }


  const videoTitle = getAssetTitle(asset);
  const cpName = getAssetContentPartnerName(asset);
  const channelTitle = getAssetChannelTitle(asset);
  const playbackType = getplaybackType(newPlaybackType)

  return parseAsLabel(cpName, channelTitle, videoTitle, playbackType);
};

export const trackAssetEvent = (eventAction, asset, playbackType=undefined) => {
  trackVideoEvent(eventAction, getAssetEventLabel(asset, playbackType));

};


export const getAssetInContinueWatching = asset => {
  const savedWatching = JSON.parse(localStorage.getItem('continue_watching'));
  let savedAsset;
  if (savedWatching) {
    const index = savedWatching.findIndex(savedWatchingAsset => {
      if (savedWatchingAsset.content) {
        return savedWatchingAsset.content?.id === asset.id || savedWatchingAsset.content?.id === asset.content?.id;
      }
      return savedWatchingAsset.id === asset.id || savedWatchingAsset.id === asset.content?.id;
    });
    if (index !== -1) savedAsset = savedWatching[index];

    return { savedAsset, index };
  }
  return undefined;
};

export const compareSeriesContinueWatchingLane = asset => {
  const savedWatching = JSON.parse(localStorage.getItem('continue_watching'));
  let savedAsset;
  if (savedWatching) {
    const index = savedWatching.findIndex(savedContinueWatchingAsset => {
      const currentAssetSerieId = getSRAssetSerieData(asset)?.id;
      const savedAssetSerieId = getSRAssetSerieData(savedContinueWatchingAsset)?.id;
      if (!savedAssetSerieId || !currentAssetSerieId) return false;

      return savedAssetSerieId === currentAssetSerieId;
    });

    if (index !== -1) savedAsset = savedWatching[index];

    return { savedAsset, index };
  }
  return undefined;
};

export const checkContinueWatching = (currentTime, duration, currentAsset, forceDeleteCurrentAsset = false ) => {
  const isPreviouslySaved = !!currentAsset.savedTime;
  const parsedVideo = currentAsset;
  const savedWatching = JSON.parse(localStorage.getItem('continue_watching')) || [];
  let index = getAssetInContinueWatching(parsedVideo)?.index;

  return new Promise(res => {
    new Promise(resolve => {
      if (currentTime > 20 && currentTime < duration - 60) {
        parsedVideo.savedTime = Math.floor(currentTime);
        if (index !== undefined && index !== -1) {
          if (forceDeleteCurrentAsset && index !== -1) savedWatching.splice(index, 1);
          else if (!isPreviouslySaved) {
            savedWatching.splice(index, 1);
            savedWatching.splice(0, 0, parsedVideo);
          } else savedWatching[index] = parsedVideo;
        } else {
          index = compareSeriesContinueWatchingLane(parsedVideo)?.index;
          if (index !== undefined && index !== -1) savedWatching.splice(index, 1);

          if (savedWatching.length < 100) {
            // add item at first position while length is less than 100
            savedWatching.unshift(parsedVideo);
          } else {
            // limit continueWatching to 100 items
            savedWatching.pop();
            savedWatching.unshift(parsedVideo);
          }
        }
        resolve(savedWatching);
      } else if (currentTime > duration - 60) {
        if (index !== undefined && index !== -1) savedWatching.splice(index, 1); // remove item watched
        getNextContent(parsedVideo, true)
          .then(nextAsset => getStreamableAsset(nextAsset))
          .then(nextStreamableAsset => {
            // checks if next asset is already in continue watching and checks if any continue watching asset is from same serie of next asset
            index =
              getAssetInContinueWatching(nextStreamableAsset)?.index === -1
                ? compareSeriesContinueWatchingLane(nextStreamableAsset)?.index
                : getAssetInContinueWatching(nextStreamableAsset)?.index;

            // eslint-disable-next-line no-param-reassign
            nextStreamableAsset.savedTime = Math.floor(1);
            const nextAssetIndex = getAssetInContinueWatching(nextStreamableAsset)?.index;
            if (index !== undefined && index !== -1 && nextAssetIndex !== -1) {
              if (!isPreviouslySaved) {
                savedWatching.splice(index, 1);
                savedWatching.splice(0, 0, nextStreamableAsset);
              } else savedWatching[index] = nextStreamableAsset;
            } else savedWatching.unshift(nextStreamableAsset);

            resolve(savedWatching);
          })
          .catch(() => resolve(savedWatching));
      } else {
        if (forceDeleteCurrentAsset && index !== -1) savedWatching.splice(index, 1);
        resolve(savedWatching);
      }
    }).then(parsedSavedWatching => {
      localStorage.setItem('continue_watching', JSON.stringify(parsedSavedWatching));
      store.dispatch(updateContinueWatchingData(parsedSavedWatching, () => res()));
    });
  });
};





