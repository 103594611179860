import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// eslint-disable-next-line no-unused-vars
import {CSSTransition, SwitchTransition} from "react-transition-group";
import history, { clearHistory } from "../../storage/history";
import images from "../../helpers/images";
import "./style.scss";
import { isPortrait } from "../../helpers/measurements";
import RotateWarning from "../rotate-warning";
import Spinner from "../../components/spinner";
import PromotedSlider from "../../components/promoted-slider";
import { setAppProperty } from "../../storage/app-properties";
import store from "../../storage/store";
import Localised from "../../components/localised";

export default function Promoted() {
  const { deviceType, promoted } = useSelector((state) => state.app);
  const [channels, setChannels] = useState([]);
  const [activeChannel, setActiveChannel] = useState({});
  const [isOverView, setIsOVerView] = useState(promoted.length > 1);

  const modifyChannels = () =>
    promoted.map((ch) => {
      const background = images.getPath(images.PROMOGRAPHIC, ch.icon);
      const channelImage = images.getPath(images.LOGO, ch.icon);
      const watermarkImage = images.getPath(images.WATERMARK, ch.icon);
      return {
        ...ch,
        background,
        channelImage,
        watermarkImage,
      };
    });



  useEffect(() => {
    setChannels(modifyChannels());
  }, []);

  useEffect(() => {
    setActiveChannel(channels[0]);
  }, [channels]);


  //
  const watchChannel = () => {
    const savedPromoted = JSON.parse(localStorage.getItem("promoted"));
    if(savedPromoted && !savedPromoted.includes(activeChannel.id)){
      savedPromoted.push(activeChannel.id)
      localStorage.setItem("promoted", JSON.stringify(savedPromoted));
    }
    clearHistory();
    history.replace({ pathname: `/home/${activeChannel.id}`});
  };

  const skip = () => {
    let savedPromoted = JSON.parse(localStorage.getItem("promoted"));
    if (promoted) {
      if (savedPromoted) {
        promoted.forEach((channel) => savedPromoted.push(channel.id));
      } else {
        savedPromoted = promoted.map((channel) => channel.id);
      }
      localStorage.setItem("promoted", JSON.stringify(savedPromoted));
    }

    clearHistory();
    history.replace("/home");
  };

  const nextPromoted = () => {
    if (channels.indexOf(activeChannel) === channels.length - 1){
      clearHistory();
      history.replace("/home");
    }else {
      setActiveChannel(channels[channels.indexOf(activeChannel) + 1]);
    }
    let savedPromoted = JSON.parse(localStorage.getItem("promoted"));
    if (promoted) {
      if (savedPromoted) {
        savedPromoted.push(activeChannel.id);
      } else {
        savedPromoted=[activeChannel.id]
      }
      localStorage.setItem("promoted", JSON.stringify(savedPromoted));
    }
  };

  const previousPromoted = () => {
    if (channels.indexOf(activeChannel) === 0 && !isOverView) {
      setIsOVerView(true);
    } else {
      setActiveChannel(channels[channels.indexOf(activeChannel) - 1]);
    }
  };

  const neverShow = () => {
    setAppProperty("showPromoted", false);
    skip();
  };

  const renderDots = () => (
    <div className="dots">
      {channels.map((ch) => (
        <div key={ch.id} className={`promoted-dots ${ch === activeChannel && "active-dot"}`} />
      ))}
    </div>
  );

  const renderTags = () => {
    // eslint-disable-next-line react/destructuring-assignment,camelcase
    const { tags, primary_color } = activeChannel;
    // eslint-disable-next-line react/destructuring-assignment
    let langCode = store.getState().locales.language;

    // eslint-disable-next-line camelcase
    if (!tags || !primary_color) {
      return null;
    }

    if (!tags[langCode] || tags[langCode].length <= 0) {
      langCode = store.getState().app.countryCode;
      if (langCode === "gb") langCode = "en";
    }
    if (!tags[langCode] || tags[langCode].length <= 0) {
      langCode = "en";
    }
    if (!tags[langCode] || tags[langCode].length <= 0) {
      return null;
    }

    return (
      <SwitchTransition>
        <CSSTransition key={activeChannel?.id} timeout={150} classNames="promoted-transition">
          <div className="channel-page-channel-tags ">
            {tags[langCode].map((item) => (
              <div key={item.id} className="channel-page-channel-tag-item">
                <div>
                  <div className="bullet" style={{ backgroundColor: primary_color }} />
                  <div className="bullet overlay" />
                </div>
                {item.name}
              </div>
            ))}
          </div>
        </CSSTransition>
      </SwitchTransition>
    );
  };

  const marginSize = () => {
    // eslint-disable-next-line no-nested-ternary
    let slicer = channels.length < 2 ? "auto" : channels.length === 2 ? "15px" : "-134px";
    if (deviceType === "desktop") {
      // eslint-disable-next-line no-nested-ternary
      slicer = channels.length < 2
          ? "auto"
          : channels.length === 2
          ? "25px"
          : " calc(-31.25vh + 72px)";
    } else if (deviceType === "tablet") {
      // eslint-disable-next-line no-nested-ternary
      slicer = channels.length < 2 ? "auto" : channels.length === 2 ? "25px" : "-294px";
    }
    return slicer;
  };

  const renderChannelsLogo = () => {
    if (isOverView && channels) {
      return (
        <div className="channels-logo">
          {channels.map((ch, index) => (
            <div
              key={ch.id}
              className="promoted-slider-channel"
              style={{
                backgroundImage: `url(${ch?.channelImage})`,
                zIndex: `${channels.length - index}`,
                margin: `auto 0 auto ${index === 0 ? 0 : marginSize()}`,
              }}
            />
          ))}
        </div>

      );
    }
    if (!isOverView && channels) {
      return (
        <>
          <PromotedSlider
            deviceType={deviceType}
            activeSlide={channels.indexOf(activeChannel)}
            channels={channels}
          />
          {activeChannel?.tags && renderTags()}
        </>
      );
    }
    return <Spinner />;
  };

  const renderOverViewInfo = () => (

      <div>
        <h3 className={deviceType === "desktop" && "one-channel-new"}>
          <Localised node="channel_promotion.new_on_rlaxxtv" />
        </h3>
        <p>
          <Localised
            node="channel_promotion.summary_text"
            dangerouslySetInnerHTML
            variables={{ missed: channels.length }}
          />
        </p>
        {/* eslint-disable-next-line jsx-a11y/no-redundant-roles,react/button-has-type */}
        <button className="promotion-button" role="button" onClick={() => setIsOVerView(false)}>
          <Localised node="buttons.show_new_channels" />
        </button>
      </div>
  );

  const channelDescription=()=>{
    const langCode = store.getState().locales.language;
    if(activeChannel?.descriptions?.[langCode]?.description){
      return activeChannel?.descriptions?.[langCode]?.description;
      // eslint-disable-next-line no-else-return
    }else if( activeChannel?.descriptions?.en?.description){
      return activeChannel?.descriptions?.en?.description;
    }

   return "";

  }

  const renderChannelInfo = () => (

    <div>
      {channels.length > 1 && renderDots()}
      <SwitchTransition>
        <CSSTransition key={activeChannel?.id} timeout={150} classNames="promoted-transition">
          <div>
            {channels.length === 1 && (
              <h3 className="one-channel-new">
                <Localised node="channel_promotion.new_on_rlaxxtv" />
              </h3>
            )}
            <h3>{activeChannel?.title}</h3>
            <p>{channelDescription()}</p>
          </div>
        </CSSTransition>
      </SwitchTransition>
      {/* eslint-disable-next-line jsx-a11y/no-redundant-roles,react/button-has-type */}
      <div className="promotion-channels-button-group">
        {/* eslint-disable-next-line jsx-a11y/no-redundant-roles,react/button-has-type */}
        <button className="promotion-button" role="button" onClick={() => nextPromoted()}>
          <Localised
            node={
              // eslint-disable-next-line no-nested-ternary
              channels.indexOf(activeChannel) !== channels.length - 1
                ? "buttons.next"
                : channels.length===1? "buttons.continue": "buttons.finish"
            }
          />
        </button>
        {/* eslint-disable-next-line jsx-a11y/no-redundant-roles,react/button-has-type */}
        <button className="promotion-button" role="button" onClick={() => watchChannel()}>
          <Localised node="buttons.watch_now" />
        </button>
      </div>
    </div>
  );


  return (

    <div className="promotion-page">

      <div className={`promoted-wrapper  ${deviceType}`}>
        <div className={`content-detail-wrapper ${deviceType === "desktop" && "desktop"}`}>
          <SwitchTransition>
            <CSSTransition key={isOverView} timeout={150} classNames="promoted-transition">
              <>
          {deviceType === "mobile" && isPortrait() === "landscape" && (
            <RotateWarning closeItem={undefined} type="portrait" />
          )}
          <div
            className={`content-background ${deviceType === "tablet" && "tablet"} ${isPortrait()}`}
            style={{ backgroundImage: `url(${activeChannel?.background})` }}
          >
            <div className="content-info-wrapper">
              <div className="blur" />
              {renderChannelsLogo()}
            </div>
          </div>

          <div className="promotion-info">
            {isOverView ? renderOverViewInfo() : renderChannelInfo()}
            <div className={`promotion-direction ${!isOverView && "channels"}`}>

              {(channels.length>1||activeChannel!==channels[channels.length-1])
                &&
              (
                // eslint-disable-next-line react/button-has-type
              <button className="promotion-direct-button" onClick={() => skip()}>
                <Localised node="buttons.skip" />
              </button>)}
              {/* eslint-disable-next-line react/button-has-type,no-unused-expressions */}
              <button
                className="promotion-direct-button"
                onClick={() => {
                  // eslint-disable-next-line no-unused-expressions
                  isOverView||channels.length===1 ? neverShow() : previousPromoted();
                }}
              >
                <Localised
                  node={isOverView||channels.length===1 ? "buttons.nerver_show_messages_like_this" : "buttons.back"}
                />
              </button>
            </div>
          </div>
              </>
            </CSSTransition>
          </SwitchTransition>
        </div>

      </div>

    </div>

  );
}
