/* eslint-disable */
// eslint-disable-next-line import/prefer-default-export
export const detectAdBlocker = () => {
  const adsBlock = document.getElementById('banner-ad');

  if(adsBlock) {
    const computedStyle = window.getComputedStyle(adsBlock);
    if(computedStyle.display != 'block') return true;
    if(computedStyle.visibility != 'visible') return true;
    if(computedStyle.opacity != '1') return true;
  }
  return false;
}
