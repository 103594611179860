/* eslint-disable import/prefer-default-export */

import { getCurrentDate } from '../../helpers/channels';
import {desktopEpgSliderSize, mobileEpgSliderSize, mobileSize} from "../../helpers/measurements";

export const dateToLocalISO = date => {
  const off = date.getTimezoneOffset();
  const absoff = Math.abs(off);
  return `${new Date(date.getTime() - off * 60 * 1000).toISOString().substr(0, 23) +
  (off > 0 ? '-' : '+') +
  (absoff / 60).toFixed(0).padStart(2, '0')}:${(absoff % 60).toString().padStart(2, '0')}`;
};


export const differenceBetweenDays = target => {
  const startDate = new Date(target);
  startDate.setHours(0);
  startDate.setMinutes(0);
  startDate.setSeconds(0);

  const currentDate = getCurrentDate();
  currentDate.setHours(0);
  currentDate.setMinutes(0);
  currentDate.setSeconds(0);

  const differenceTime = startDate - currentDate;
  return Math.ceil(differenceTime / (1000 * 60 * 60 * 24));
};



export const renderSkeleton=(screenSize, fallbackItem)=>{
  const sliderSize = screenSize < mobileSize ? mobileEpgSliderSize : desktopEpgSliderSize;
  const loadingSlidesNum=Math.ceil(window.innerWidth/sliderSize);
  const loadingSlides=[]
  // eslint-disable-next-line no-plusplus
  for(let i=0; i<loadingSlidesNum; i++){
    loadingSlides.push(fallbackItem)
  }
  return loadingSlides;
}
