/* eslint-disable */
import {
  AD_ENDED_EVENT,
  AD_STARTED_EVENT,
  AD_TYPE_MIDROLL,
  AD_TYPE_PREROLL,
  ADPOD_ENDED_EVENT,
  ADPOD_FAILED_EVENT,
  playAd,
  VIDEO_ENDED_EVENT
} from './ads';

class AdsCounter {
  static TAG = '[Ads Counter]';
  static DEBUG = true;

  constructor() {
    // eslint-disable-next-line no-underscore-dangle
    this._initialized = false;
    this._prerollEnabled = true;
    this._midrollEnabled = true;

    this.prerollAfter = 1;
    this.midrollAfter = 4;

    this.videosCounter = 0;

    this._prerollPlayed = false;

    this._listening = false;
    this.paused = false;
  }

  get initialized() {
    return this._initialized;
  }

  get listenting() {
    return this._listening;
  }

  get isPrerollEnabled() {
    return this._prerollEnabled;
  }

  set prerollEnabled(bool) {
    this._prerollEnabled = bool;
  }

  get isMidrollEnabled() {
    return this._midrollEnabled;
  }

  set midrollEnabled(bool) {
    this._midrollEnabled = bool;
  }

  get hasPrerollPlayed() {
    return this._prerollPlayed;
  }

  listen = () => {
    if (!this._listening) {
      /* document.addEventListener(AD_STARTED_EVENT, this.onAdStarted);
      document.addEventListener(AD_ENDED_EVENT, this.onAdEnded);
      document.addEventListener(ADPOD_ENDED_EVENT, this.onAdpodEnded);
      document.addEventListener(ADPOD_FAILED_EVENT, this.onAdpodFailed);
      document.addEventListener(VIDEO_ENDED_EVENT, this.onVideoEnded); */

      this._listening = true;
    }
  };

  unlisten = () => {
    if (this._listening) {
      // document.removeEventListener(AD_STARTED_EVENT, this.onAdStarted);
      // document.removeEventListener(AD_ENDED_EVENT, this.onAdEnded);
      // document.removeEventListener(AD_FAILED_EVENT, this.onAdFailed);

      this._listening = false;
    }
  };

  init = (delay = undefined) => {

    this.listen();
    document.addEventListener(AD_STARTED_EVENT, this.onAdStarted);
    document.addEventListener(AD_ENDED_EVENT, this.onAdEnded);
    document.addEventListener(ADPOD_ENDED_EVENT, this.onAdpodEnded);
    document.addEventListener(ADPOD_FAILED_EVENT, this.onAdpodFailed);
    document.addEventListener(VIDEO_ENDED_EVENT, this.onVideoEnded);
    this._initialized = true;
    this.paused = false;
  };

  pause = () => {
    this.paused = true;
  };

  stop = () => {
    this.pause();
    this.unlisten();
    this._prerollPlayed = false;
    this.videosCounter = 0;
  };

  resume = () => {
    this.listen();
    this.paused = false;
  };

  playMidroll = () => {
    this.pause();
    playAd(AD_TYPE_MIDROLL);
  };

  restartCounter = () => {
    this.pause();

    this.videosCounter = 0;

    this.resume();
  };

  onAdStarted = () => {
    if (!this._listening) return;


    this.pause();
  };

  onAdEnded = () => {
    if (!this._listening) return;


    this.restartCounter();
  };

  onAdpodEnded = () => {
    if (!this._listening) return;

    if (!this._prerollPlayed) this._prerollPlayed = true;
    this.restartCounter();
  };

  onAdpodFailed = () => {
    if (!this._listening) return;

    console.error(AdsCounter.TAG, 'Ad playing failed.', this._errorCounter, 'try out of', this._disableAfterErrors);
    this.pause();
    this._errorCounter += 1;
    if (this._disableAfterErrors <= -1 || this._errorCounter <= this._disableAfterErrors) {
      this.restartCounter();
    }
  };

  onVideoEnded = () => {
    if (!this._listening || this.paused) return;

    this.videosCounter += 1;

    if (this._prerollEnabled && !this._prerollPlayed && this.videosCounter === this.prerollAfter) {
      playAd(AD_TYPE_PREROLL);
    } else if (this._midrollEnabled && this.videosCounter >= this.midrollAfter) {
      this.playMidroll();
    }
  };
}

const adsCounter = new AdsCounter();

if (AdsCounter.DEBUG) {
  window.adsCounter = adsCounter;
}

export default adsCounter;
