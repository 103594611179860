/* eslint-disable prefer-object-spread */
import CONSTANTS from './constants';

const initialState = {
  currentVideo: undefined,
  isPlaying: false,
  isBuffering: false,
  isLoading: false,
  isAd: false,
  isMuted: true,
  adRemaining: 0,
  adTotal: 0
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CONSTANTS.SET_PLAYING_VIDEO: {
      return Object.assign({}, state, { currentVideo: action.payload, isBuffering: true });
    }
    case CONSTANTS.SET_IS_PLAYING: {
      return Object.assign({}, state, { isPlaying: action.payload });
    }
    case CONSTANTS.SET_IS_BUFFERING: {
      return Object.assign({}, state, { isBuffering: action.payload });
    }
    case CONSTANTS.SET_IS_LOADING: {
      return Object.assign({}, state, { isLoading: action.payload });
    }
    case CONSTANTS.SET_IS_ADVERTISEMENT: {
      const { isAd, remainingTime, totalTime } = action.payload;
      return Object.assign({}, state, { isAd, adRemaining: remainingTime, adTotal: totalTime });
    }
    case CONSTANTS.SET_IS_MUTED: {
      return Object.assign({}, state, { isMuted: action.payload });
    }
    default:
      return state;
  }
}
