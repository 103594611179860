/* eslint-disable eqeqeq */
import React from 'react';
import PropTypes from 'prop-types';
import  './style.scss';
import PlayerContext from "../../storage/contexts/PlayerContext";
import { mobileSize} from "../../helpers/measurements";
import {pad2} from "../../utils";


class ProgressBarHome extends React.Component {
  constructor(props) {
    super(props);
    this.refProgressBar = React.createRef();
    this.state={
      active:false
    }
  }

  componentDidMount() {
    if (this.refProgressBar.current) {
      const{isProgressBarScrolling}=this.props;
      this.refProgressBar.current.addEventListener("touchstart", () => {
        this.setState({ active: true }, () => {
          if (typeof isProgressBarScrolling == "function") {
            isProgressBarScrolling(true);
          }
        });
      });
      this.refProgressBar.current.addEventListener("mouseenter", () => {
        this.setState({ active: true }, () => {
          if (typeof isProgressBarScrolling == "function") {
            isProgressBarScrolling(true);
          }
        });
      })
      this.refProgressBar.current.addEventListener("touchend", () => {
        this.setState({active: false},()=>{
          if(typeof isProgressBarScrolling=='function'){
            isProgressBarScrolling(false);
          }
        })
      })
      this.refProgressBar.current.addEventListener("mouseleave", () => {
        this.setState({active: false},()=>{
          if(typeof isProgressBarScrolling=='function'){
            isProgressBarScrolling(false);
          }
        })
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { currentLiveChannel, isLive, forwardLive } = this.props;
    if (prevProps.currentLiveChannel !== currentLiveChannel) {
      if (typeof forwardLive === "function" && !isLive) {
        forwardLive();
      }
    }
  }

  getProgress = () => {
    if (this.refProgressBar.current) {
      const { currentTime, startRealTime, endRealTime } = this.props;
      return `${
        Math.floor((((currentTime - startRealTime) * 100) / (endRealTime - startRealTime)) * 10) /
        10
      }%`;
    }
    return "";
  };

  setProgress() {
    const { play, pause, setCurrentTime, forwardLive, startTime, currentTime } = this.props;
    const { current } = this.context;
    if (this.refProgressBar.current && current) {
      if (current.isPaused === false) pause();
      const time = Number(this.refProgressBar.current.value - this.refProgressBar.current.min);
      const currentMaxTime = Number(currentTime - startTime);
      if (time > currentMaxTime) {
        play();
        forwardLive();
      } else {
        setCurrentTime(time);
        play();
      }
    }
  }

  generateIntervalTime = (dataToShow) => {

    const time = new Date(dataToShow * 1000);
    return `${pad2(time.getHours())}:${pad2(time.getMinutes())}`;

  }

  progressBarValue = () => {
    // eslint-disable-next-line no-unused-vars
    const {
      startTime,
      endTime,
      currentLiveChannel,
      focusedChannelId,
      currentTime,
      timeShifting,
      videoTime,
      startRealTime,
      endRealTime,
    } = this.props;
    const progressBarValues = { min: startTime, max: endTime, current: currentTime };
    if (currentLiveChannel && focusedChannelId) {
      if (Number(currentLiveChannel) !== Number(focusedChannelId) || !timeShifting) {
        progressBarValues.current = currentTime;
        progressBarValues.min = startRealTime;
        progressBarValues.max = endRealTime;
      } else {
        progressBarValues.min = 0;
        progressBarValues.max = endTime - startTime;
        progressBarValues.current = videoTime;
      }
    }
    return progressBarValues;
  };

  render() {
    // eslint-disable-next-line no-unused-vars
    const { timeShifting, isLive,screenSize, startTime, deviceType } = this.props;
    const{active}=this.state;
    const { max, min, current } = this.progressBarValue();
    const realTimeLine = this.getProgress();
    const indicatorGap=screenSize<mobileSize?15:25;
    const backgroundInputSize = `${Math.floor(((current - min) / (max - min)) * 1000) / 10}% 100%`;

    return (
      <div
        className={`wrapper ${!timeShifting && "inactive"}
        ${deviceType}`}
      >
        {this.refProgressBar.current && (
          <div className="progress-real" style={{ width: realTimeLine }} />
        )}

        <div style={{left:`calc(${Math.floor(((current - min) / (max - min)) * 1000) / 10}% - ${indicatorGap}px`}}
             className={`indicator ${active && "active"}`}>{isLive?'LIVE':this.generateIntervalTime(startTime+current)}</div>
        <input
          type="range"
          onChange={() => {
            if (timeShifting) this.setProgress();
          }}
          style={{ backgroundSize: backgroundInputSize }}
          ref={this.refProgressBar}
          className={`progressbar-home  ${screenSize > mobileSize && "desktop"}
          ${screenSize < mobileSize && active && "active"}`}
          max={max}
          min={min}
          value={timeShifting ? current : null}
          step={2}
        />
      </div>
    );
  }
}

ProgressBarHome.propTypes = {
  startTime: PropTypes.number,
  currentTime: PropTypes.number,
  endTime: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  currentLiveChannel:PropTypes.number,
  isLive:PropTypes.bool,
  forwardLive:PropTypes.func,
  play:PropTypes.func,
  pause:PropTypes.func,
  setCurrentTime:PropTypes.func,
  focusedChannelId: PropTypes.string,
  videoTime:PropTypes.number,
};

ProgressBarHome.defaultProps = {
  startTime: 0,
  currentTime: 0,
  endTime: 1,
  currentLiveChannel:undefined,
  isLive:false,
  forwardLive:undefined,
  play:undefined,
  pause:undefined,
  setCurrentTime:undefined,
  focusedChannelId:undefined,
  videoTime:undefined,
};

ProgressBarHome.contextType = PlayerContext;
export default ProgressBarHome;
