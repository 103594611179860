import qs from 'query-string';
import axios from 'axios';

const srProperties = {
  portal: undefined,
  menu: undefined,
  navigationItems: undefined,
  deviceCategory: undefined,
  countryCode: undefined
};

const appendSRParams = (
  url,
  {
    portalId = srProperties.portal.id,
    categoryId = srProperties.deviceCategory,
    countryCode = srProperties.countryCode,
    limit = 20,
    teaserLimit = 20
  } = {}
) => {
  const parsed = qs.parseUrl(url);
  parsed.query.device_category_id = categoryId;
  parsed.query.portal_id = portalId;
  parsed.query.userCountry = countryCode;
  parsed.query.limit = limit;
  parsed.query.teaserLimit = teaserLimit;

  return `${parsed.url}?${qs.stringify(parsed.query)}`;
};

const setSRProperty = (property, value) => {
  srProperties[property] = value;
};

const getFromSRAPI = (srUrl, config = {}) => axios.get(appendSRParams(srUrl, config));

// eslint-disable-next-line eqeqeq
const findNavigationItem = (id, navigationItems = []) => navigationItems.find(elem => id == elem.id);

const findDeviceCategory = url => qs.parseUrl(url).query.device_category_id;

const parseMenuData = (portal, bigScreen) => {
  const {
    data: { navigationSide, navigationItems }
  } = bigScreen;
  const portalCode = portal.portal;

  if (!portalCode || !navigationSide || !navigationSide[portalCode]) {
    throw new Error('There is something wrong with the navigation side information.');
  } else {
    const mainMenu = navigationSide[portalCode][0];

    return mainMenu.children.map(menuItem => ({
      ...menuItem,
      ...findNavigationItem(menuItem.nav_id, navigationItems)
    }));
  }
};

const parseNavigationItems = (portal, bigScreen) => {
  const {
    data: { navigationItems, navigationSide }
  } = bigScreen;

  const portalCode = portal.portal;

  if (!portalCode || !navigationSide || !navigationSide[portalCode]) {
    throw new Error('There is something wrong with the navigation side information.');
  } else {
    // const mainMenu = navigationSide[portalCode][0];

    return navigationItems.reduce((acc, item) => {
      acc[item.id] = item;
      item?.target?.children?.forEach(ch => {
        acc[ch.id] = ch;
      });
      return acc;
    }, {});
  }
};


const initializeSRProperties = (bigScreen, portalCode = undefined) => {
  const { data } = bigScreen;

  if (!data) throw new Error(`Missing data object on BigScreen config, BigScreen -> ${JSON.stringify(bigScreen)}`);

  try {
    const array = [1, 2, 3];
    array.find(i => i === 1);
  } catch (e) {
    throw new Error('This device cannot find elements on an array.');
  }

  if (!Array.isArray(data.portals)) throw new Error('Missing portals array on BigScreen');

  const portal =
    data.portals.find(p =>
      portalCode ? p.portal.toLowerCase().replace('uk', 'gb') === portalCode.toLowerCase() : p.default
    ) ||
    data.portals.find(p => (portalCode ? p.countries.includes(portalCode) : p.default)) ||
    data.portals.find(p => p.default) ||
    data.portals[0];

  const menu = parseMenuData(portal, bigScreen);
  const navigationItems = parseNavigationItems(portal, bigScreen);
  const deviceCategory = findDeviceCategory(menu[0].target.url);

  setSRProperty('portal', portal);
  setSRProperty('menu', menu);
  setSRProperty('navigationItems', navigationItems);
  setSRProperty('deviceCategory', deviceCategory);
  setSRProperty('countryCode', portalCode);
};

export { srProperties, initializeSRProperties, setSRProperty, appendSRParams, getFromSRAPI };
