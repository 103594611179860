import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import {mobileSize} from "../../helpers/measurements";



class ProgressBarVod extends React.Component{
  // constructor(props) {
  //   super(props);
  //   // this.refProgressBar=React.createRef();
  //
  // }
  //
  // componentDidMount() {
  // }



  getProgress=()=>{
    const{currentTime,duration,refProgress}=this.props
    if(refProgress.current) {
      return `${currentTime * 100 / duration }% 100%`
    }
    return '';
  }

  setProgress=()=>{
    const {setCurrentTime,refProgress}=this.props;
    if(refProgress.current){
      setCurrentTime(refProgress.current.value)
    }
  }

  timeConverter = (time) => {
    let minutes = Math.floor(time / 60);
    let seconds = Math.floor(time % 60);
    let hours = 0;
    if (minutes > 60) {
      hours = Math.floor(minutes / 60);
      minutes = Math.floor(minutes % 60);
    }
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    //  Delay switching duration Element to avoid NaN
    let calculatedTime;
    if (seconds) {
      if (hours) {
        calculatedTime = `${hours}:${minutes}:${seconds}`;
      } else {
        calculatedTime = `${minutes}:${seconds}`;
      }
    }
    return calculatedTime;
  };




  render(){
    const{duration,currentTime,screenSize,UIActive,refProgress}=this.props;
    const backGroundSize=this.getProgress();
    return(screenSize &&(
        <div className={`progressbar-wrapper ${screenSize<mobileSize && 'mobile'}`}>
          <span className="time">{this.timeConverter(currentTime)} </span>
          <input
                 type='range'
                 onChange={()=>{this.setProgress()}}
                 style={ {backgroundSize:backGroundSize}}
                 ref={refProgress}
                 className={this.screenSize<mobileSize?"progressbar-home":"progressbar-home desktop"}
                 max={duration}
                 min={0}
                 value={currentTime}
                 step={2}
                 disabled={!UIActive}/>
          <span className="time">{`-${(duration && currentTime)?this.timeConverter(duration-currentTime):0}`} </span>
        </div>


    ))
  }
}

ProgressBarVod.propTypes = {
  duration: PropTypes.number,
  currentTime: PropTypes.number,
  setCurrentTime: PropTypes.func,
  screenSize:PropTypes.number.isRequired
};

ProgressBarVod.defaultProps = {
  duration: 0,
  currentTime: 0,
  setCurrentTime:undefined
};

// eslint-disable-next-line react/jsx-props-no-spreading
export default React.forwardRef((props, ref) => <ProgressBarVod refProgress={ref} {...props}/>);
