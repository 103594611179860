/* eslint-disable no-undef,no-console */



export const PAGES = {
  LIVE_PLAYER: 'liveplayer',
  PROGRAM_GUIDE: 'program-guide',
  ON_DEMAND: 'on-demand',
  SETTINGS: 'settings',
  STARTUP: 'startup',
  EXIT: 'exit',
  ERROR: 'error',
  BETAGATE:'betagate',
  PLAYER:'player'
};

export const EVENTS = {
  VIDEO: {
    ID: 'video',
    CATEGORY: 'Video',
    ACTIONS: {
      AD_CALLED: 'Ad Called',
      AD_STARTED: 'Ad Started',
      AD_ENDED: 'Ad Ended',
      AD_FAILED: 'Ad Failed',
      PLAY: 'Play',
      PAUSE: 'Pause',
      PLAY_INITIAL: 'Play-Initial',
      PLAY_RESTART: 'Play-Restart',
      COMPLETED: 'Completed',
      MUTE:'Video-Muted',
      UNMUTE:'Video-Unmuted',
      FULLSCREEN:'Enter Fullscreen',
      CANCEL_FULLSCREEN:'Leave Fullscreen'
    }
  },
  AD: {
    ID: 'video',
    CATEGORY: 'Ad',
    ACTIONS: {
      BLOCK_START: 'Block Start',
      BLOCK_FAILED: 'Block Failed',
      REQUEST: 'Request',
      START: 'Start',
      FIRST_QUARTILE: 'First quartile',
      SECOND_QUARTILE: 'Second quartile',
      THIRD_QUARTILE: 'Third quartile',
      COMPLETE: 'Complete',
      CANCELLED: 'Cancelled',
      REQUEST_FAILED: 'Request Failed',
      RESPONSE_EMPTY: 'Response Empty',
      RESPONSE_SUCCESS: 'Response Successful'
    }
  },
  APP: {
    ID: 'video',
    CATEGORY: 'Settings',
    ACTIONS: {
      CHANGE_PROPERTY: 'Change Property',
      SET_CONSENT: 'Set Consent',
      CHANGE_LANGUAGE: 'Change Language',
      NOTIFICATION:'Notification Preferences'
    }
  }
};

// eslint-disable-next-line no-unused-vars
const getParsedVideoEventString = (videoTitle, channelTitle, playbackType, epgStartTime) => {
  const loweredTitle = videoTitle.toLowerCase();
  let type = 'ondemand';
  if (playbackType === 'live') {
    type = `live (${epgStartTime.toLocaleString()})`;
  }
  return `${loweredTitle} - ${channelTitle} - ${type}`;
};

export const initTracking = (brand, platform) => {
  _paq.push(['MediaAnalytics::removePlayer', 'youtube']);
  _paq.push(['enableHeartBeatTimer']);

  const userLayer = {
    dimension1: brand
  };


  // console.info('[GTM] Tracking Initialization:', userLayer);



  dataLayer.push(userLayer);
  // _paq.push(['setCustomVariable', 1, 'Brand', brand, 'visit']);
  // use Custom Dimension instead of Variable as it's going to be deprecated by matomo
  _paq.push(['setCustomDimension', 1, brand]);
  _paq.push(['setCustomDimension', 2, platform]);

  // eslint-disable-next-line no-use-before-define
  trackPageView('');
};

const encodeToPath = (string = '') =>
  (typeof string === 'string' ? string : '')
    ?.toLowerCase()
    .replace(/[âäáà]/g, 'a')
    .replace(/[ëêéè]/g, 'e')
    .replace(/[ïîíì]/g, 'i')
    .replace(/[öôóò]/g, 'o')
    .replace(/[üûúù]/g, 'u')
    .replace(/[ñ]/g, 'n')
    .replace(/[ß]/g, 'ss')
    .replace(/[^\w\d]+/g, ' ')
    .replace(/ +/g, '-');

const getPathFromLayers = (...layers) => {
  let path = '';
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < layers.length; i++) {
    if (layers[i]) {
      path += `/${encodeToPath(layers[i])}`;
    }
  }
  return path;
};

export const trackPageView = (...layers) => {
  if (layers.length < 1) {
    console.error('You cannot track an empty page view');
    return;
  }

  const page = layers[0];

  const path = `/${page}${getPathFromLayers(...layers.slice(1))}`;
  const screenLayer = {
    event: 'pageview',
    page: path,
    eventCategory: undefined,
    eventAction: undefined,
    eventLabel: undefined
  };

  dataLayer.push(screenLayer);

  _paq.push(['setCustomUrl', path]);
  _paq.push(['trackPageView']);
};

export const trackEvent = (eventId, eventCategory, eventAction, eventLabel, eventValue) => {
  const eventLayer = {
    event: eventId,
    eventCategory,
    eventAction,
    eventLabel,
    eventValue
  };


  dataLayer.push(eventLayer);
  _paq.push(['trackEvent', eventCategory, eventAction, eventLabel, eventValue]);
};

export const parseAsLabel = (...args) => args.join(';');

export const trackCustomEvent = (eventType, eventAction, eventLabel, eventValue) => {
  if (eventType.ID && eventType.CATEGORY) {
    trackEvent(eventType.ID, eventType.CATEGORY, eventAction, eventLabel, eventValue);

  } else {
    console.error(`[GTM] Event cannot be parsed: ${eventAction} - ${eventLabel}.`, eventType);
  }
};

export const trackVideoEvent = (eventAction, eventLabel, eventValue = undefined) => {
  trackEvent(EVENTS.VIDEO.ID, EVENTS.VIDEO.CATEGORY, eventAction, eventLabel, eventValue);

};

export const trackAdEvent = (eventCategory, eventAction, eventLabel, eventValue = undefined) => {
  trackEvent(EVENTS.AD.ID, eventCategory, eventAction, eventLabel, eventValue);

};
