/* eslint-disable import/prefer-default-export */
import CONSTANTS from './constants';

function callAction(action, newState) {
  return {
    type: action,
    payload: newState
  };
}

export function setPlayingVideo(data) {
  return dispatch =>
    new Promise(resolve => {
      resolve(dispatch(callAction(CONSTANTS.SET_PLAYING_VIDEO, data)));
    });
}

export const playing = value => ({ type: CONSTANTS.SET_IS_PLAYING, payload: value });
export const buffering = value => ({ type: CONSTANTS.SET_IS_BUFFERING, payload: value });
export const loading = value => ({ type: CONSTANTS.SET_IS_LOADING, payload: value });
export const muted = value => ({ type: CONSTANTS.SET_IS_MUTED, payload: value });
export const advertisement = (isAd, remainingTime, totalTime) => ({
  type: CONSTANTS.SET_IS_ADVERTISEMENT,
  payload: { isAd, remainingTime, totalTime }
});
