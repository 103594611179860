/* eslint-disable react/jsx-no-bind,react/button-has-type,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React from "react";
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import './style.scss';
import Header from "../../components/header";
import Accordion from "../../components/accordion";
import Localised from "../../components/localised";
import arrow from '../../assets/rlaxxTV_Icons_SVG_220114/icon/indicator-arrow-right/white.svg'
import store, {localesActions} from "../../storage/store";
import history from "../../storage/history";
import back from "../../assets/rlaxxTV_Icons_SVG_220114/icon/back/white.svg";
import logo from "../../assets/images/rlaxx_logo_mobile.svg";
import TabBar from "../../components/tab-bar";
import {EVENTS, PAGES, trackCustomEvent, trackPageView} from '../../helpers/tracking'
import PlayerContext from "../../storage/contexts/PlayerContext";
import DidomiConsentPreferences from "../../components/DidomiConsentPreferences";
import {getMobileDevicePlatform, mobileSize} from "../../helpers/measurements";
import appProperties, {setAppProperty} from "../../storage/app-properties";





class Settings extends React.Component{
    constructor(props) {
        super(props);
        this.state={activeTab:undefined,
          selectedLanguage:store.getState().locales.language, notificationOn:appProperties.showPromoted, activeFaq:undefined}
        this.collapseFaqAccordion=this.collapseFaqAccordion.bind(this)
        this.settingsTabArr=['language','notifications_and_announcements', 'consent_preferences','help_and_support','privacy_policy','imprint']

    }

    componentDidMount() {
      const {
          match: {
            params: { activeTab }
        }}=this.props

      const {current}=this.context;
        if(current) current.mute();
        this.setState({activeTab:activeTab || 'language',selectedLanguage:store.getState().locales.language})
    }

    componentDidUpdate(prevProps, prevState) {
        const{selectedLanguage, activeTab, notificationOn}=this.state;

        if(prevState.activeTab !== activeTab) {
          trackPageView(PAGES.SETTINGS, activeTab)
        }

        if(prevState.selectedLanguage!==selectedLanguage){
            trackCustomEvent(EVENTS.APP, EVENTS.APP.ACTIONS.CHANGE_LANGUAGE, `${prevState.selectedLanguage} -> ${selectedLanguage}`);
            this.forceUpdate();}
        if(prevState.notificationOn!==notificationOn){
          trackCustomEvent(EVENTS.APP, EVENTS.APP.ACTIONS.NOTIFICATION , `${notificationOn}`);
        }
    }



  // eslint-disable-next-line class-methods-use-this
    get privacyText() {
      return store.getState().locales.translatedPrivacy || store.getState().locales.fallbackPrivacy;
    }

  clickRlaxxAppButton=()=> {
    let path = "";
    if (getMobileDevicePlatform() === "Android") {
      path = "https://play.google.com/store/apps/details?id=com.rlaxxtv.tvapp.atv";
    } else if (getMobileDevicePlatform() === "IOS") {
      path = "https://apps.apple.com/app/rlaxx-tv/id1537986411";
    }

    return path;
  }

    changeLanguage(language) {

            this.setState({selectedLanguage:language},()=>{
              const {selectedLanguage}=this.state;
              store.dispatch(localesActions.setLanguage(selectedLanguage));
            })

    };



    collapseFaqAccordion(key){
        this.setState({activeFaq:key})
    }

    changeNotifications(bool){
        this.setState({notificationOn:bool},()=>{
          setAppProperty('showPromoted',bool)
          // if(bool===false){
          //   let savedPromoted=JSON.parse(localStorage.getItem('promoted'))
          //   if(savedPromoted) savedPromoted=[];
          //   localStorage.setItem('promoted',JSON.stringify(savedPromoted))
          // }
        })

    }

    changeActiveTab(settingsTabEl){
      this.setState({activeTab:settingsTabEl},()=>{
        history.push(`/settings/${settingsTabEl}`)
      })
    }



    renderNav(){
        const {activeTab}=this.state;
        const tabDivs=[]
      // eslint-disable-next-line no-plusplus
        for(let i=0; i<=this.settingsTabArr.length-1;i++){
            tabDivs.push(
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
                <div className="settings-tab" key={this.settingsTabArr[i]}
                     role="button"
                     onClick={()=>this.changeActiveTab(this.settingsTabArr[i])}>
                    <div className="tab-info">
                        <div className={`tab-title ${activeTab===this.settingsTabArr[i]&&'active'}`}>
                            <Localised node={`settings.${this.settingsTabArr[i]}.title`}/>
                        </div>
                        <div className={`tab-subtitle ${activeTab===this.settingsTabArr[i]&&'active'}`}>
                          <Localised node={`settings.${this.settingsTabArr[i]}.subtitle`}/>
                        </div>

                    </div>
                    <div className="settings-tab-arrow">
                        <img  src={arrow} alt="arrow"/>
                    </div>

                </div>
            )


        }
        return tabDivs;
    }

    renderAccordion(){
      const{screenSize}=this.props;
      const{activeFaq}=this.state;
      const accordionElements=[];
      // eslint-disable-next-line no-plusplus
      for(let i=1; i<=4; i++){
        accordionElements.push(
        <Accordion  screenSize={screenSize}
                    item={`item_${i}`}
                    active={activeFaq}
                    collapseFaqAccordion={this.collapseFaqAccordion}
                    title={<Localised node={`settings.faq.item_${i}.title`}/>}
                    content={<Localised node= {`settings.faq.item_${i}.content`}/>}
        /> )
        }
      return accordionElements;
    }

    renderTabDetail(){
        const{activeTab,selectedLanguage,notificationOn}=this.state;
        const {languagesList}=this.props;
        if(activeTab==='language'){
            return(
                <div className="active-tab-detail">
                    <div className="active-tab-detail-explanation"><p><Localised node="settings.language.explanation"/></p></div>
                    <div className="active-tab-detail-content">
                        {languagesList&&languagesList.map(lang=>{
                            const checked= selectedLanguage===lang.code;
                          // eslint-disable-next-line react/button-has-type
                            return(<button className="lang-button" key={lang.code} onClick={()=>{this.changeLanguage(lang.code)}}>
                            <input type="radio" id={lang.code}
                                   name={lang.code}
                                   value={lang.code}
                                   onChange={()=>true }
                                   checked={checked} />
                            <label htmlFor={lang.code}>{lang.name}</label>
                            </button>)})

                            }
                    </div>

                </div>
            )
        }
        if(activeTab==='notifications_and_announcements'){
            return (
                <div className="active-tab-detail">
                    <div className="active-tab-detail-explanation title">
                      <p><Localised node="settings.notifications_and_announcements.new_available_channels.title"/></p>
                    </div>
                    <div className="active-tab-detail-explanation-detail">
                      <p><Localised node="settings.notifications_and_announcements.new_available_channels.subtitle"/></p>
                    </div>
                    <div className="active-tab-detail-content">
                      {/* eslint-disable-next-line react/button-has-type */}
                        <button className="lang-button" key="on"  onClick={()=>{this.changeNotifications(true)}}>
                            <input type="radio" id="on" name="on" value="on"  onChange={()=>true } checked={notificationOn} />
                          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                            <label htmlFor="on"><Localised node="general.on"/></label>
                        </button>
                      {/* eslint-disable-next-line react/button-has-type */}
                        <button className="lang-button" key="off"  onClick={()=>{this.changeNotifications(false)}}>
                            <input type="radio"
                                   id="off"
                                   name="off"
                                   value="off"
                                   onChange={()=>true }
                                   checked={!notificationOn} />
                          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                            <label htmlFor="off"><Localised node="general.off"/></label>
                        </button>
                    </div>
                </div>
            )
        }
        if(activeTab==='faq'){
            return(
                <div className="active-tab-detail">
                  {this.renderAccordion()}
                </div>

            )
        }
        if(activeTab==='consent_preferences'){
          return (<div className="active-tab-detail">
                    <div className="active-tab-detail-content">
                      <DidomiConsentPreferences/>
                    </div>

                  </div>)
        }

        if(activeTab==="help_and_support"){
            return(
                <div className="active-tab-detail">
                    <div className="active-tab-detail-explanation help-and-support">
                      <p><Localised node="settings.help_and_support.explanation"/></p>
                    </div>
                    <div className="active-tab-detail-content help-and-support ">
                      {/* eslint-disable-next-line react/jsx-no-target-blank */}
                      <a target="_blank" href="https://rlaxxtv.com/support">
                          {/* eslint-disable-next-line react/button-has-type */}
                          <button className="lang-button"><Localised node="settings.help_and_support.contact_button"/> </button>
                      </a>
                    </div>

                </div>
            )
        }
        if(activeTab==="privacy_policy"){
            return(
                <div className="active-tab-detail privacy" >
                  {/* eslint-disable-next-line react/no-danger */}
                    <article dangerouslySetInnerHTML={{ __html: this.privacyText }}
                        key="privacy-content"
                        id="privacy-policy-content"

                    />
                </div>)
        }
        if(activeTab==="imprint"){
            return(
                <div className="active-tab-detail">
                    <div className="active-tab-detail-explanation"><p><Localised node="settings.about_app.description"/></p></div>
                </div>
                )
        }

        return null;
    }


    renderTab(){
        const{activeTab}=this.state;

        return(
            <div className="active-tab">
                <div className="active-div-title">
                    <h3><Localised node={`settings.${activeTab}.title`}/>
                    </h3></div>
                {this.renderTabDetail()}

            </div>
        )
    }

    render(){
        const {screenSize, deviceType}=this.props;
        return(
         <div className="settings-wrapper">
             {deviceType==="desktop"&& <Header  screenSize={screenSize} deviceType={deviceType} />}
             <div className={`settings-view ${deviceType==="mobile" && 'mobile'} ${deviceType==="tablet" && 'tablet'}`}>
                 {deviceType!=="mobile"&&  <div className="settings-nav">
                     <div className="settings-title"><h2><Localised  node="navigation.settings"/></h2></div>

                         <div className="settings-tabs">

                             <div >
                                 {this.renderNav()}
                               {deviceType==="tablet" && <div className="tablet-button-wrapper">
                               <div className="target-wrapper">
                                 <a target="_blank" href={this.clickRlaxxAppButton()} rel="noreferrer">
                                   <button className="header-button-more">rlaxx TV App</button>
                                 </a>
                               </div>
                                 <div className="target-wrapper">
                                 <a target="_blank" href="https://rlaxxtv.com/" rel="noreferrer">
                                 <button className="header-button-more">More About rlaxx TV</button>
                                 </a>
                                 </div>
                               </div>}
                             </div>

                         </div>
                 </div>
                 }
                 <div className={screenSize<mobileSize && 'tab-mobile'} >
                     {deviceType==="mobile"&&
                         <div className="content-header">
                           {/* eslint-disable-next-line import/no-named-as-default-member,react/button-has-type */}
                           <div className="target-wrapper" onClick={()=>{history.goBack()}}>
                             <button>
                               <img src={back} alt="back-button"/>
                               <Localised node={`buttons.${screenSize >= mobileSize ? 'close' : 'back'}`} />
                             </button>
                           </div>
                             {screenSize<mobileSize&&
                               <img className="logo" src={logo} alt="rlaxx-logo"/>
                             }
                         </div>
                     }
                     {this.renderTab()}
                     {(deviceType!=="desktop"||screenSize<mobileSize)&&<TabBar deviceType={deviceType}/>}
                 </div>
             </div>

           {(deviceType==="desktop"||deviceType==="tablet")&&
           <div className={`version-number ${deviceType==="tablet" && "tablet"}`}>
             <p> <Localised node="general.app_version"/>: {process.env.REACT_APP_VERSION}</p>
           </div>
           }
         </div>

        )
    }


}

Settings.propTypes={
  // eslint-disable-next-line react/forbid-prop-types
  match:PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  languagesList:PropTypes.array,
  screenSize: PropTypes.number.isRequired,
}

Settings.defaultProps={
  match:undefined,
  languagesList:undefined
}

Settings.contextType=PlayerContext;

const mapStateToProps = state => ({

    screenSize:state.app.screenSize,
    currentLanguage: state.locales.language,
    languagesList: state.locales.list,
    list:state.locales.list,
    translations: state.locales.translations,
    fallback: state.locales.fallback,
    translatedPrivacy: state.locales.translatedPrivacy,
    fallbackPrivacy: state.locales.fallbackPrivacy,
    deviceType:state.app.deviceType

})


export default connect(mapStateToProps,)(Settings)
