/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React from 'react';
import './style.scss'
import PropTypes from "prop-types";
import up from '../../assets/images/icon/indicator-arrow/up.png';
import down from '../../assets/images/icon/indicator-arrow/down.png'

class  Accordion extends React.Component{
    constructor(props) {
        super(props);
        this.state={isActive:false}
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {

        const{active,item}=this.props;
        if(prevProps.active!==active){
            if(active!==item){
              // eslint-disable-next-line react/no-did-update-set-state
                this.setState({isActive:false})
            }

        }
    }




    changeActiveAccordion(){
        const {isActive}=this.state;
        const { item, collapseFaqAccordion }=this.props;
       this.setState({isActive:!isActive},()=> {
                collapseFaqAccordion(item)
       })

    }

    render(){
        const { title, content }=this.props;
        const {isActive}=this.state;

            return (
                <div className="accordion-item" >
                    <div className="accordion-title" onClick={() =>this.changeActiveAccordion() }>
                        <div>{title}</div>
                        <div><img src={isActive?up:down} alt="arrow" style={{width:"0.9375rem"}}/></div>
                    </div>
                    {isActive && <div className="accordion-content">
                      <div className="accordion-wrapper">
                        <p>{content}</p>
                      </div>
                    </div>}
                </div>
            );
        }

}


Accordion.propTypes={
  // eslint-disable-next-line react/forbid-prop-types
  active:PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  item:PropTypes.object,
  collapseFaqAccordion:PropTypes.func,
  title:PropTypes.string,
  content:PropTypes.string,

}

Accordion.defaultProps={
  active:undefined,
  item:undefined,
  collapseFaqAccordion:undefined,
  title:"",
  content:"",
}

export default Accordion;
