import React from 'react'
import './style.scss'

const BurgerIcon=(props)=>{
  const {collapsed}=props;
  return(<div className="menu-button-wrapper">
    <div className={`menu-btn ${collapsed===1 && "collapsed"}`}>
      <div className="menu-btn-burger"/>
    </div>
  </div>)
}


export default BurgerIcon;
