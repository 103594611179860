import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import store from '../storage/store';

const nodeReducer = (object, key) => object[key] || '';

const getTranslationValue = (nodeString, currentTranslations, fallbackTranslations) => {
  const node = nodeString.split('.');
  return (
    (currentTranslations && node.reduce(nodeReducer, currentTranslations)) ||
    (fallbackTranslations && node.reduce(nodeReducer, fallbackTranslations)) ||
    nodeString
  );
};

const replaceVariables = (translation, variables) => {
  let replaced = `${translation}`;
  // eslint-disable-next-line no-restricted-syntax
  for (const key in variables) {
    if (Object.prototype.hasOwnProperty.call(variables, key)) {
      const value = variables[key];
      replaced = replaced.replace(`{{${key}}}`, value);
    }
  }
  return replaced;
};

export const getTranslation = (
  nodeString,
  variables,
  currentTranslations = store.getState().locales.translations,
  fallbackTranslations = store.getState().locales.fallback
) => {
  let translation = getTranslationValue(nodeString, currentTranslations, fallbackTranslations);
  translation = replaceVariables(translation, variables);
  return translation;
};

// eslint-disable-next-line react/prop-types
const Localised = React.memo(({ node, variables, translations, fallback, dangerouslySetInnerHTML }) => {
  const translation = getTranslation(node, variables, translations, fallback);
  if (!dangerouslySetInnerHTML) {
    // eslint-disable-next-line react/jsx-fragments
    return <React.Fragment>{translation}</React.Fragment>;
  }
  // eslint-disable-next-line react/no-danger
  return <span dangerouslySetInnerHTML={{ __html: translation }} />;
});

Localised.propTypes = {
  /** Translation node to be used using dot notation: general.buttons.accept */
  node: PropTypes.string.isRequired,
  /** JSON Object where each key is the placeholder to be replaced with their specific value */
  variables: PropTypes.oneOfType([PropTypes.object]),
  /** Parse HTML from the translation value */
  dangerouslySetInnerHTML: PropTypes.bool
};

Localised.defaultProps = {
  variables: {},
  dangerouslySetInnerHTML: false
};

const mapStateToProps = state => ({
  translations: state.locales.translations,
  fallback: state.locales.fallback
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(Localised);
