/* eslint-disable */
import adsTimer from './ads-timer';
import adsCounter from './ads-counter';
import { partnerIdentifiers } from '../channels';
import {detectAdBlocker} from "./detectAdBlocker";


const DEBUG = true;
const TAG = '[ADS]';

const MIN_GAP = 4 * 60;

let timerAds = false;
export const isTimerAds = () => timerAds;

export const PREROLL_DELAY = 60;
export const MIDROLL_DELAY = 8 * 60;

let initialized = false;
export const areAdsInitialized = () => initialized;

export const initLiveAds = partnerIdentifier => {

  if(!detectAdBlocker()) {
    restartAds();
    initialized = true;
    switch (partnerIdentifier) {
      case partnerIdentifiers.VEVO:

        initLiveCounterAds();
        break;
      default:

        initLiveTimerAds();
        break;
    }
  }
};

export const initLiveCounterAds = () => {
  adsTimer.stop();
  adsCounter.init();
  timerAds = false;
};

export const initLiveTimerAds = (delay = PREROLL_DELAY) => {
  const { lastAdVODAgo } = adsTimer;
  let prerollDelay = delay;

  if (adsTimer.hasPrerollVODPlayed && adsTimer.lastAdVODAgo < MIN_GAP) {
    prerollDelay = MIN_GAP - adsTimer.lastAdVODAgo;
  }

  adsCounter.stop();
  adsTimer.stop();
  adsTimer.prerollDelay = prerollDelay;
  adsTimer.midrollDelay = MIDROLL_DELAY;
  adsTimer.lastAdAgo = lastAdVODAgo;
  adsTimer.init();
  timerAds = true;
};

export const initVODAds = () => {
  if(!detectAdBlocker()){

    restartAds();
    initLiveTimerAds(0);
  }

};

export const stopLiveAds = () => {
  adsTimer.stop();
};

export const AD_PLAY_EVENT = 'ad_play';
export const AD_STARTED_EVENT = 'ad_started';
export const AD_ENDED_EVENT = 'ad_ended';
export const ADPOD_STARTED_EVENT = 'adpod_started';
export const ADPOD_ENDED_EVENT = 'adpod_ended';
export const ADPOD_FAILED_EVENT = 'adpod_failed';

export const AD_RESTART_EVENT = 'ad_restarted';

export const VIDEO_ENDED_EVENT = 'video_ended';

export const AD_TYPE_PREROLL = 'preroll';
export const AD_TYPE_MIDROLL = 'midroll';

export const playAd = type => {
  setTimeout(() => {document.dispatchEvent(new CustomEvent(AD_PLAY_EVENT, { detail: { type }, bubbles: true }))}, 3000);
};

export const restartAds = () => {
  document.dispatchEvent(new CustomEvent(AD_RESTART_EVENT, { bubbles: true }));
};

export const adStarted = (adType, adPartnerID, asset) => {

  document.dispatchEvent(new CustomEvent(AD_STARTED_EVENT, { bubbles: true }));
};

export const adEnded = () => {

  document.dispatchEvent(new CustomEvent(AD_ENDED_EVENT, { bubbles: true }));
};

export const adpodFailed = () => {

  document.dispatchEvent(new CustomEvent(ADPOD_FAILED_EVENT, { bubbles: true }));
};

export const adpodStarted = (adpodType, asset) => {

  document.dispatchEvent(new CustomEvent(ADPOD_STARTED_EVENT, { bubbles: true }));
};

export const adpodEnded = (adpodType, asset) => {

  document.dispatchEvent(new CustomEvent(ADPOD_ENDED_EVENT, { bubbles: true }));
};

export const videoEnded = () => {

  document.dispatchEvent(new CustomEvent(VIDEO_ENDED_EVENT, { bubbles: true }));
};

if (DEBUG) {
  window.$ads = {
    playAd,
    videoEnded,
    timerAds
  };
}
