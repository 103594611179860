// eslint-disable-next-line max-len
/* eslint-disable react/no-unused-state,camelcase,react/button-has-type,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,jsx-a11y/interactive-supports-focus */
import React from 'react'
import { throttle } from 'lodash';
import './style.scss'
import axios from "axios";
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import VanillaTilt from "vanilla-tilt";
import {getFromSRAPI, srProperties} from "../../storage/sr-properties";
import {getChannelFromID} from "../../storage/redux/app-data/actions";
import history from "../../storage/history";
import store from "../../storage/store";
import Spinner from "../../components/spinner";
import Header from "../../components/header";
import back from "../../assets/rlaxxTV_Icons_SVG_220114/icon/back/white.svg";
import logo from "../../assets/images/rlaxx_logo_mobile.svg";
import {getAssetFromID, getAssetTitle, getAssetWatermark} from "../../helpers/assets";
// import left from "../../assets/rlaxxTV_Icons_SVG_220114/icon/indicator-arrow-left/white.svg";
import Localised, { getTranslation } from '../../components/localised'
import {images} from "../../helpers";
import TabBar from "../../components/tab-bar";
import up from "../../assets/rlaxxTV_Icons_SVG_220114/icon/indicator-arrow-up/white.svg";
import ContentDetail from "../content-detail/single-item";
import { PAGES, trackPageView } from '../../helpers/tracking'
import RotateWarning from "../rotate-warning";
import PlayerContext from "../../storage/contexts/PlayerContext";
import {
  isPortrait,
  mobileSize,
  changeHeaderTransition
} from "../../helpers/measurements";
import {getPartnerIdentifierFromAsset} from "../../helpers/channels";
import CollectionDetail from "../content-detail/collection-item";
import {
  getMultipleSerieData,
  getSRAssetMetadata, getSRAssetSerieData,
} from "../../helpers/partners/sportradar";
import seriesImg from "../../assets/rlaxxTV_Icons_SVG_220114/icon/series/white.svg";
import playlistImg from "../../assets/rlaxxTV_Icons_SVG_220114/icon/playlist/white.svg";





class ChannelPage extends  React.Component{
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loaded:false,
      channel: undefined,
      pageInfo: undefined,
      tabs: [],
      currentTab: 0,
      minimizedHeader:false,
      selectedItem:{assetType:undefined, partnerId:undefined, assetId:undefined,teaserType:undefined, pageId:undefined},
      scrolled:false,
      tabScrollValue:0
    };
    this.refContentDetail=React.createRef();
    this.tabRef=[];
    this.refChannelPage=React.createRef();
    this.refTabContent=React.createRef();
    this.refContentBlock=React.createRef();
    this.refChannelHeader=React.createRef();
    this.refChannelSubHeader=React.createRef();
    this.refWaterMarkImage=React.createRef();
    this.refWaterMark=React.createRef();
    this.selectItemToShow=this.selectItemToShow.bind(this);
    this.closeItem=this.closeItem.bind(this);
    this.requestPageThrottled = throttle(axios.get, 1000);

  }

  componentDidMount() {

    VanillaTilt.init(document.querySelectorAll(".content-wrapper"), {
      max: 0,
      speed: 400,
      scale:1.03,
      glare: true,
      gyroscope:false,
      transition:true,
      'max-glare': 0.4
    })
    const {
      match: { params }
    } = this.props;
    // eslint-disable-next-line camelcase
    const { page_id } = params;
    const { loading } = this.state;
    const {current}=this.context;
    if(current) current.mute();
    const navItem = srProperties.navigationItems[page_id];
    if (!navItem || !navItem.target || !navItem.target.url) {
      history.replace('/error/404');
    } else if (loading) {
      this.getModulesData().then(() => {
        this.skeletonTO = setTimeout(() => {
          this.setState({ loaded: true }, () => {
            this.trackTabLoad();
          });
        }, 500);
      }).then(()=>{
        const{tabs}=this.state;
        // eslint-disable-next-line no-plusplus
        for(let i=tabs.length-1; i>=0; i--){
          this.setState({currentTab:i},()=>{
            this.requestNextPage()
            if(sessionStorage.getItem('currentTab') !== 'undefined' && sessionStorage.getItem('currentTab') !== null){
              const tabValue = parseInt(sessionStorage.getItem('currentTab'), 10);
              this.setState({currentTab:tabValue});
              document.getElementById("tabContent").scrollTop = sessionStorage.getItem('contentDetailScrollPos');
            }
          })
        }
      }).then(()=>this.getAssetIdFromPathname().catch(()=>{this.setState({currentTab:0},()=>{
        this.closeItem()
      })}))
    }

  }


  componentDidUpdate(prevProps){
    const {
      match: {
        params: { page_id,assetId },
      },
    } = this.props;
    if(prevProps.match.params.page_id!==page_id){
      const navItem = srProperties.navigationItems[page_id];
      if (!navItem || !navItem.target || !navItem.target.url) {
        history.replace('/error/404');
      } else{
        this.getModulesData().then(() => {
          this.skeletonTO = setTimeout(() => {
            this.setState({ loaded: true }, () => {
              this.trackTabLoad();
            });
          }, 500);
        }).then(()=>{
          const{tabs}=this.state;
          // eslint-disable-next-line no-plusplus
          for(let i=tabs.length-1; i>=0; i--){
            this.setState({currentTab:i},()=>{
              this.requestNextPage()
            })
          }
        }).then(()=>this.getAssetIdFromPathname().catch(()=>this.closeItem()))
      }

    }

    if (prevProps.match.params.page_id===page_id && prevProps.match.params.assetId !== assetId){

      this.getAssetIdFromPathname().catch(()=>{
        this.closeItem()});
    }
  }

  onBackButtonClick = () => {
    sessionStorage.setItem('currentTab', undefined);
    // eslint-disable-next-line react/destructuring-assignment
    const onDemandChannelUrl = sessionStorage.getItem('onDemandChannelUrl');
    if(onDemandChannelUrl){
      history.push(onDemandChannelUrl.replace('#', ''))
    }
    else {
      history.replace('/on-demand');
    }
  }

  getAssetFromAssetId(assetId) {
    const { tabs } = this.state;
    let asset;
    // eslint-disable-next-line no-plusplus
    // for (let i = 0; i < tabs.length; i++) {
    //   // eslint-disable-next-line eqeqeq
    //   asset = tabs[i].content.find((item) => Number(item.id) === Number(assetId));
    //   if (asset) break;
    // }
    // return asset;

    return new Promise(resolve=>{
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < tabs.length; i++) {
        // eslint-disable-next-line eqeqeq
        asset = tabs[i].content.find((item) => Number(item.id) === Number(assetId));
        if (asset){
          resolve(asset);
        }
      }
      if(!asset){
        getAssetFromID(assetId,'SportRadar').then(res=>{
          // eslint-disable-next-line prefer-destructuring
          asset = res.data.data.content[0]
        }).catch(()=>asset=undefined).then(()=>resolve(asset))
      }
    })

  }

  getAssetIdFromPathname() {
    const {
      match: {
        params: { assetId },
      },
    } = this.props;
    const {selectedItem}=this.state;
    // eslint-disable-next-line eqeqeq
    if(assetId==selectedItem.assetId ) Promise.resolve();
    return new Promise((resolve) => {
      this.selectedItemTo = setTimeout(() => {
        this.getAssetFromAssetId(Number(assetId)).then(asset=>{
          if(!asset){throw new Error()}
          if(asset?.id){ const assetToShow = {...asset,
            assetType: "vod",
            assetId: asset.id,
            partnerId: getPartnerIdentifierFromAsset(asset),
            pageId:asset?.page_id
          };
            this.selectItemToShow(assetToShow)
            this.forceUpdate(resolve)

          }
        }).catch(()=>{
          this.closeItem() })
      },150)})
  }


  getModulesData = () => {
    const {
      // eslint-disable-next-line react/prop-types
      match: { params }
    } = this.props;
    const { page_id } = params;

    const navItem = srProperties.navigationItems[page_id];
    return new Promise(resolve => {
      this.skeletonTO = setTimeout(() => {
        getFromSRAPI(navItem.target.url)
          .then(resp => resp?.data?.data)
          .then(({modules, general}) => {
            store
              .dispatch(getChannelFromID(modules[0].description.en))
              .then(channel => {
                const parsedChannel = this.parseImages(channel);
                this.setState({channel: parsedChannel, pageInfo: general});
              })
              .catch(e => {
                console.error(e);
                this.setState({channel: {title: modules[0].title.en}, pageInfo: general});
              });
            // create an axios request to get each tab information
            const tabResponses = modules[0].filter?.groups
              ?.filter(tab => tab.url)
              .map(tab => axios.get(tab.url).catch(e => e));
            if (tabResponses?.length > 0) {
              return Promise.all(tabResponses);
            }
            return Promise.reject(new Error('No tabs found'));
          })
          .then(responses => responses.filter(resp => !(resp instanceof Error))) // filter failed responses
          .then(responses => {
            const {pageInfo} = this.state;
            const tabsArray = [];
            const seriesIds = [];

            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < responses.length; i++) {
              const tab = responses[i].data?.data;
              const {meta, links} = responses[i].data;
              if (tab) {
                if (meta) {
                  tab.meta = meta;
                }
                if (links) {
                  tab.links = links;
                }
                tab.content = tab.content.map(content => {

                  const serieId = !content.page_id
                    ? getSRAssetMetadata(content, 'seriesId')
                    : content.editorial?.translations?.en?.description?.split('#')[1];

                  // eslint-disable-next-line no-param-reassign,react/destructuring-assignment
                  content.primary_color = this.state?.channel?.primary_color;

                  if (serieId) seriesIds.push(serieId);
                  return {...content, watermark: pageInfo?.page_logo, serieId};
                });
                if (tab?.description?.en === "Highlights") {
                  tab.content = tab.content.slice(0, 10);
                }

                tabsArray.push(tab);
              }
            }
            return {tabsArray, seriesIds};
          })
          .then(({tabsArray, seriesIds}) => {
            if (seriesIds && seriesIds.length > 0) {
              return getMultipleSerieData(seriesIds)
                .then(resp =>
                  tabsArray.map(tab => {
                    // eslint-disable-next-line no-param-reassign
                    tab.content = tab.content.map(content => {
                      if (content?.serieId) {
                        // eslint-disable-next-line no-param-reassign,eqeqeq
                        content.serieData = resp.find(serieInfo => serieInfo?.id == content?.serieId);
                        return content;
                      }
                      return content;
                    });
                    return tab;
                  })
                )
                .catch(() => {
                  console.error('Failed loading seriesData');
                  return tabsArray;
                });
            }
            return tabsArray;
          })
          .then(parsedTabs => {
            this.setState({tabs: parsedTabs, loading: false}, () => {
              resolve();
            });
          });
      }, 250);
    })

          // .then(responses => {
          //   const { pageInfo } = this.state;
          //   const tabs = [];
          //
          //   // eslint-disable-next-line no-plusplus
          //   for (let i = 0; i < responses.length; i++) {
          //     const tab = responses[i].data?.data;
          //     const { meta, links } = responses[i].data;
          //     if (tab) {
          //       if (meta) {
          //         tab.meta = meta;
          //       }
          //       if (links) {
          //         tab.links = links;
          //       }
          //       tab.content = tab.content.map(content => ({ ...content, watermark: pageInfo?.page_logo }));
          //       tabs.push(tab);
          //     }
          //   }
          //   // const tabs = responses.map(resp => resp.data?.data);
          //
          //   this.setState(
          //     {
          //       tabs,
          //       loading: false
          //     },
          //     () => {
          //       this.forceUpdate(resolve);
          //     }
          //   );
          // });
      // }, 250);
    .catch(error => {
      console.error('It was not possible to load the page data:', error);
      history.replace('/error/502');
    });
  };



  requestNextPage = () => {
    const { tabs, currentTab, pageInfo } = this.state;
    const tab = tabs[currentTab];
    const {
      meta,
      title,
      links: { next }
    } = tab;

    const currentPage = meta?.current_page;

    if (next && !this.requesting && title.en !== 'on_demand.highlights') {
      this.requesting = true;

      this.requestPageThrottled(next)
        .then(
          ({ data }) =>
            new Promise(res => {
              if (data.meta.current_page > currentPage) {
                const seriesIds = [];
                // eslint-disable-next-line no-param-reassign
                data.data.content = data.data.content.map(content => {
                  const serieId = !content.page_id
                    ? getSRAssetMetadata(content, 'seriesId')
                    : content.editorial?.translations?.en?.description?.split('#')[1];
                  if (serieId) seriesIds.push(serieId);
                  return { ...content, serieId };
                });

                if (seriesIds && seriesIds.length > 0) {
                  getMultipleSerieData(seriesIds)
                    .then(resp => {
                      // eslint-disable-next-line no-param-reassign
                      // eslint-disable-next-line no-param-reassign
                      data.data.content = data.data.content.map(content => {
                        if (content?.serieId) {
                          // eslint-disable-next-line no-param-reassign,eqeqeq
                          content.serieData = resp.find(serieInfo => serieInfo?.id == content?.serieId);
                          return content;
                        }
                        return content;
                      });
                      res(data);
                    })
                    .catch(() => {
                      console.error('Failed loading seriesData');
                      res(data);
                    });
                } else {
                  res(data);
                }
              }
            })
        )
        .then(parsedData => {
          tab.meta = parsedData.meta;
          tab.links = parsedData.links;
          tab.content = [...tab.content, ...parsedData.data.content];
          tab.content = tab.content.map(content => ({ ...content, watermark: pageInfo?.page_logo }));
          this.setState({ tabs });
        })
        .catch(error => {
          console.error("Couldn't load the next page:", error);
        })
        .then(() => {
          this.requesting = false;
          this.requestPageThrottled.cancel();
          this.forceUpdate();
        });
    }
  };

  parseImages = channel => {
    // eslint-disable-next-line no-param-reassign
    channel.promographic = images.getPath(images.PROMOGRAPHIC, channel.icon);

    return channel;
  };


  trackTabLoad = () => {
    const { channel, currentTab, tabs } = this.state;
    if (tabs[currentTab]) {
      trackPageView(
        PAGES.ON_DEMAND,
        channel?.title || 'missing-channel-info',
        getTranslation(tabs[currentTab].title.en)
      );
    }
  };

  selectTab(tab){
    if(this.tabRef){
      const {tabs}=this.state;
      sessionStorage.setItem('currentTab', tabs.indexOf(tab));
      if(tabs) this.setState({currentTab:tabs.indexOf(tab)},()=>{
        this.requestNextPage()
        this.trackTabLoad();

      })
    }

  }

  selectItemToShow(asset){
    this.setState({selectedItem:asset},()=>{
      const {selectedItem}=this.state;
      const {
        // eslint-disable-next-line react/prop-types
        match: { params:{page_id} }
      } = this.props;
      history.push(`/on-demand/channel-page/${page_id}/asset/${selectedItem.assetId}`)
    })
  }

  closeItem(){
    sessionStorage.setItem('currentTab', undefined);
    sessionStorage.setItem('scrollValue', undefined);
    sessionStorage.setItem('scrollPos', undefined);
    sessionStorage.setItem('seasonTab', undefined);
    this.setState({scrolled:0})
    this.setState({selectedItem:{assetType:undefined, partnerId:undefined, assetId:undefined, teaserType:undefined}},()=>{
      const {
        // eslint-disable-next-line react/prop-types
        match: { params:{page_id} }
      } = this.props;
      history.replace(`/on-demand/channel-page/${page_id}`)

    })
  }



  clickItemToShow(item){
    sessionStorage.setItem('contentDetailScrollPos', document.getElementById("tabContent").scrollTop);
    if(item.teaserType==="content"){
      const selectedContent={assetType:"vod", partnerId:-1, assetId:item.id, teaserType:undefined, pageId:undefined}
      this.selectItemToShow(selectedContent)

    }else if(item.teaserType==='collection'){
      const selectedContent={assetType:"vod", partnerId:-1, assetId:item.id, teaserType:item.teaserType, pageId:item.page_id,
        editorial:item.editorial}
      this.selectItemToShow(selectedContent)
    }
  }

  scrollTop(){
    if(this.refContentBlock.current){
      this.refTabContent.current.scrollTop=0
    }
  }





  renderTags() {
    // eslint-disable-next-line react/destructuring-assignment,camelcase
    const { tags, primary_color } = this.state.channel;
    // eslint-disable-next-line react/destructuring-assignment
    let langCode = store.getState().locales.language;

    // eslint-disable-next-line camelcase
    if (!tags || !primary_color) {
      this.areChannelTags = false;
      return null;
    }
    if (!tags[langCode] || tags[langCode].length <= 0) {
      langCode = store.getState().app.countryCode;
      if (langCode === 'gb') langCode = 'en';
    }
    if (!tags[langCode] || tags[langCode].length <= 0) {
      this.areChannelTags = false;
      return null;
    }

    this.areChannelTags = true;

    return (
      <div className={`channel-page-channel-tags ${(this.refTabContent.current.scrollTop && this.refTabContent.current.scrollTop > 0 )
      && 'minimizedHeader'}`}>
        {tags[langCode].map(item => (
          <div key={item.id} className="channel-page-channel-tag-item">
            <div>
              <div className="bullet" style={{ backgroundColor: primary_color }} /> <div className="bullet overlay" />
            </div>
            {item.name}
          </div>
        ))}
      </div>
    );
  }



  renderTabs(){
    const{tabs, currentTab}=this.state;
    const {screenSize}=this.props;
    return(tabs&&
      <div className={`channel-tab-bar ${screenSize<mobileSize && tabs.length<3 && 'less-tabs'}`}>
        {tabs&&tabs.map(tab=>
          <div className="target-wrapper" role="button" onClick={()=>{this.selectTab(tab)}}>
          <button className={`tab ${currentTab===tabs.indexOf(tab)&&'active'}`}
                  key={tab.id} ref={ref=>{this.tabRef[tabs.indexOf(tab)]=ref}}
                  onClick={()=>{this.selectTab(tab)}}>
            <Localised node={tab.title.en}/>
          </button>
          </div>
        )}
      </div>

    )
  }

  renderContentDetail(){
    const{selectedItem, scrolled, currentTab}=this.state;
    if(selectedItem.teaserType==="collection") return <CollectionDetail
      assetType={selectedItem.assetType}
      assetId={selectedItem.assetId}
      partnerId={selectedItem.partnerId}
      closeItem={this.closeItem}
      selectItem={this.selectItemToShow}
      collectionAsset={selectedItem}
      teaserType={selectedItem.teaserType}
      pageId={selectedItem.pageId}
      pageView={PAGES.ON_DEMAND}
      scrolled={scrolled}
      currentTab={currentTab}
      // scrollTop={this.scrollTopDetailPage}
    />
    return <ContentDetail assetType={selectedItem.assetType}
                          assetId={selectedItem.assetId}
                          partnerId={selectedItem.partnerId}
                          closeItem={this.closeItem}
                          selectItem={this.selectItemToShow}
                          teaserType={selectedItem.teaserType}
                          pageId={selectedItem.pageId}
                          pageView={PAGES.ON_DEMAND}
    />

  }

  // eslint-disable-next-line react/sort-comp,class-methods-use-this
  getSubtitle(item){
    if(item.teaserType==="collection") {
      const seasonTitle = (item.serieData?.seasons && item.serieData.seasons.length > 1)
        ? getTranslation("general.seasons") :
        getTranslation("general.season");

      const subtitle=item.serieId?`${item.serieData?.seasons?.length} ${seasonTitle}`
        :
        `${getTranslation("general.playlist")}`
      return (
        subtitle &&
        <div className="content-subtitle">
          <img className="subtitle-icon" src={(item.serieData?.seasons || item.serieId) ? seriesImg : playlistImg} alt="series"/>
          <p className="subtitle-item">{subtitle}</p>
        </div>
      )
    }
    return (
        getSRAssetSerieData(item) && getSRAssetSerieData(item).season &&
        <div className="content-subtitle">
          <img className="subtitle-icon" src={seriesImg} alt="series"/>
          <p className="subtitle-item">{getSRAssetSerieData(item).title}</p>
        </div>
      )
  }

  // eslint-disable-next-line class-methods-use-this
  getTitle(item){
    let title=getAssetTitle(item)||undefined;
    const serieData = getSRAssetSerieData(item);
    const seasons=getTranslation("general.season_short");
    const episodes=getTranslation("general.episode_short");
    if (serieData && serieData.episode) {
      title = `${seasons}${serieData.season}
      ${episodes}${serieData.episode}: ${title}`;
    }
    return (
      (getSRAssetSerieData(item) && getSRAssetSerieData(item).season) &&
      title
    )
  }

  renderTabContent(){
    const{tabs,currentTab}=this.state;
    const{ deviceType}=this.props;
    VanillaTilt.init(document.querySelectorAll(".content-div"), {
      max: 0,
      speed: 400,
      scale:1.03,
      glare: true,
      gyroscope:false,
      transition:true,
      'max-glare': 0.4,
    })
    if(tabs&&tabs[currentTab]&&tabs[currentTab].content) {
      return(
        <div ref={this.refContentBlock} className={`channel-content-wrapper ${deviceType}`}>
          {tabs[currentTab].content.map(item=>{
            if ( item.id) {
              const backgroundImage = images.getSRContentImage((item.editorial.image ? item.editorial.image.path : null), '400x225') || undefined;
              return <div className={`content-wrapper ${deviceType}`} onClick={()=>{this.clickItemToShow(item)} } >
                <div key={item.id} className="content-div" role="button"
                     style={{backgroundImage:`url('${backgroundImage}')`}}>
                  <div className="filter"/>
                  {item?.editorial?.translations?.en &&
                  <div className="content-title">
                    {
                      this.getSubtitle(item)
                    }

                    <p>{(item && item.serieData && item.teaserType !== "collection") ? this.getTitle(item) : item.editorial.translations.en.title}</p>
                  </div>}

                  { getAssetWatermark(item) && <img src={getAssetWatermark(item)} alt={item.editorial.translations.en.title}/>}
                </div>


              </div>
            }
            return null;
          })}
        </div>
      )
    }

    return null;
  }

  renderHeader(){
    const{screenSize, deviceType}=this.props;
    if(deviceType ==="desktop") return  <Header screenSize={screenSize} deviceType={deviceType}/>

    return  (
      <div className="channel-page-mobile-header">
        {/* eslint-disable-next-line react/button-has-type,react/destructuring-assignment */}
          <div className="target-wrapper" onClick={this.onBackButtonClick}>
            <button ><img src={back} alt="back-button"/><Localised node="buttons.back" /></button>
            <img className="logo" src={logo} alt="rlaxx-logo"/>
          </div>
      </div>
    )
  }

  render(){
    const {channel,currentTab, tabs,selectedItem, tabScrollValue}=this.state;
    // eslint-disable-next-line no-unused-vars
    const{vod,screenSize, liveChannels, deviceType}=this.props;
    // eslint-disable-next-line camelcase,react/destructuring-assignment
    const {page_id}=this.props.match.params;
    // eslint-disable-next-line eqeqeq
    const channelData=vod[0].content.find(item=>item.page_id==page_id)
    // eslint-disable-next-line eqeqeq
    const waterMark=liveChannels.find(item=>item?.title.toLowerCase()==channelData?.title?.en.toLowerCase())
      ||channel?.watermark||undefined

    return(

      <>
        {deviceType==="mobile"&&isPortrait()==='landscape'&& <RotateWarning type='portrait'/>}
        <div ref={this.refChannelPage} className={`channel-page ${deviceType!=="mobile" &&'desktop'}`}>
          {(!(!tabs||!tabs[currentTab]||tabs[currentTab].content.length<=0) && channel)&&
          <div  ref={this.refChannelHeader}
                className={`channel-page-header ${deviceType==="mobile"?'mobile':'desktop'}
                ${(tabScrollValue && tabScrollValue > 0) && "minimizedHeader"}`}
                style={{ backgroundImage: channel?.promographic ? `url(${channel.promographic})` : ''}} >

            <div ref={this.refChannelSubHeader} className={`channel-header-background ${deviceType==="mobile"?'mobile':'desktop'}
            ${(tabScrollValue && tabScrollValue > 0) && "minimizedHeader"}`}>
              <div className="back-button">
                {deviceType==="desktop"&& <button onClick={this.onBackButtonClick}>
                  <img src={back} alt="back-button"/><Localised node="buttons.back" />
                </button>
                }
              </div>
               <div ref={this.refWaterMark} className={`watermark ${(tabScrollValue && tabScrollValue > 0) && "minimizedHeader"}
               ${deviceType==="tablet" && isPortrait()==="portrait" &&"tablet"}`}>
                 {waterMark && <img ref={this.refWaterMarkImage } src={waterMark.watermark||waterMark}
                                    alt={`${channelData?.title.en||channelData?.title}`}/>}
              </div>
              <div className={`channel-tags-wrapper ${(tabScrollValue && tabScrollValue > 0) && "minimizedHeader"}`}>
                    <div className="channel-tags">
                      {this.renderTags()}
                    </div>
              </div>

              <div className="blur-down" />
              {tabs&&this.renderTabs()}
            </div>

            {this.renderHeader()}

          </div>}

          <div id="tabContent" ref={this.refTabContent} className={`tab-content
          ${(!tabs||!tabs[currentTab]||tabs[currentTab].content.length<=0) && "spinnerGrid"}
          ${deviceType==="mobile"?'mobile':'desktop'}
          ${(tabScrollValue && tabScrollValue > 0) && "minimizedHeader"}`} onScroll={()=>
          {
            this.setState({tabScrollValue:this.refTabContent.current.scrollTop})
            if(this.refTabContent.current.scrollTop + 525 > this.refContentBlock.current.offsetHeight){
              this.requestNextPage();
            }
          }} >
            { (!tabs||!tabs[currentTab]||tabs[currentTab].content.length<=0) ? <Spinner /> :this.renderTabContent()}

            {!(!tabs||!tabs[currentTab]||tabs[currentTab].content.length<=0) &&
              <div className={`back-to-top ${deviceType==="desktop" &&'desktop'}`}>
                <button onClick={()=>{this.scrollTop()}}><img src={up} alt="up-arrow"/> <Localised node="buttons.back_to_top" /></button>
              </div>
            }

          </div>

          {selectedItem.assetType&&selectedItem.assetId&&selectedItem.partnerId
          &&
          <div className="detailed-item-wrapper">
            <div id="selectedItem" ref={this.refContentDetail}
                 onScroll={()=>
                 {
                   if(selectedItem.teaserType==="collection"){
                     this.setState({scrolled:this.refContentDetail.current.scrollTop})
                   }
                   else{changeHeaderTransition(this.refContentDetail,"detail-header",
                     deviceType==="desktop"?"linear-gradient(180deg, #0A1320 0%, rgba(10, 19, 32, 0.75) 59.9%, rgba(10, 19, 32, 0) 100%)":"")
                   }  }
                 } className={`selected-item-detail-page ${deviceType==="desktop" &&'desktop'}`}>
              {this.renderContentDetail()}
            </div>

          </div>
          }
          {deviceType!=="desktop"&&<TabBar deviceType={deviceType}/>}

        </div>
      </>
    )
  }

}



ChannelPage.propTypes={
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.func,
  screenSize: PropTypes.number.isRequired,
  vod: PropTypes.arrayOf('object'),
  liveChannels:PropTypes.arrayOf('object'),


}

ChannelPage.defaultProps={
  match:undefined,
  vod:undefined,
  liveChannels:undefined
}


ChannelPage.contextType=PlayerContext;

const mapStateToProps=state=>({

  screenSize:state.app.screenSize,
  vod:state.app.vod,
  liveChannels:state.app.liveChannels,
  deviceType:state.app.deviceType
})

export default connect(mapStateToProps,)(ChannelPage)
