// eslint-disable-next-line no-unused-vars
import React, {useEffect, useState} from "react";
import "./style.scss";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper-bundle.css";
import "swiper/swiper.scss";



function PromotedSlider(prop) {
  // eslint-disable-next-line no-unused-vars
  const [swiper, setSwiper] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const { channels, deviceType, activeSlide } = prop;


  useEffect(() => {

     if(swiper){
       swiper.slideTo(activeSlide);
     }
  }, [activeSlide]);

  const slides = channels.map((ch) => (
    <SwiperSlide key={ch.id} className={`promoted-slider-swiper ${deviceType==="mobile" && "mobile"}`}>
      <div
        key={ch.id}
        className="promoted-slider-channel-slide"
        style={{ backgroundImage: `url(${ch?.channelImage})` }}
        >
        <div className="promoted-overlay"/>
      </div>

    </SwiperSlide>
  ));

  return (
    <Swiper
      slidesPerView="auto"
      centeredSlides
      onSwiper={(s) => {setSwiper(s);
      }}
      allowTouchMove={false}
      spaceBetween={deviceType === "mobile" ? 15 : 25}
      mousewheel={{ forceToAxis: true }}
      simulateTouch
      className="promoted-slider"
      edgeSwipeDetection
      navigation={false}
      pagination={false}
      watchSlidesProgress
    >
      {slides}
    </Swiper>
  );
}

export default PromotedSlider;
