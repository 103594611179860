/* eslint-disable */
import {
  AD_ENDED_EVENT,
  AD_STARTED_EVENT,
  AD_TYPE_MIDROLL,
  AD_TYPE_PREROLL,
  ADPOD_ENDED_EVENT,
  ADPOD_FAILED_EVENT,
  ADPOD_STARTED_EVENT,
  playAd
} from './ads';

class AdsTimer {
  static TAG = '[Ads Timer]';
  static DEBUG = true;

  constructor() {
    // eslint-disable-next-line no-underscore-dangle
    this._initialized = false;
    this._prerollEnabled = true;
    this._midrollEnabled = true;
    this._prerollDelay = 60;
    this._midrollDelay = 8 * 60;
    this._errorDelay = 3 * 60;
    this._errorCounter = 0;
    this._disableAfterErrors = 0;

    this._adIntervalCounter = 0;
    this._adAgoCounter = -1;
    this._adVODAgoCounter = -1;

    this._lasAdsDuration = 0;

    this._adInterval = undefined;
    this._autoResumeTO = undefined;
    this._lastAdsDurationInterval = undefined;
    this.prerollTimeOut = undefined;

    this._prerollPlayed = false;
    this._prerollVODPlayed = false;

    this._listening = false;
  }

  get initialized() {
    return this._initialized;
  }

  get listenting() {
    return this._listening;
  }

  get prerollDelay() {
    return this._prerollDelay;
  }

  set prerollDelay(seconds) {
    if (isNaN(seconds)) throw new Error('Seconds must be a number');
    else this._prerollDelay = seconds;
  }

  get midrollDelay() {
    return this._midrollDelay;
  }

  set midrollDelay(seconds) {
    if (isNaN(seconds)) throw new Error('Seconds must be a number');
    else this._midrollDelay = seconds;
  }

  get errorDelay() {
    return this._errorDelay;
  }

  set errorDelay(seconds) {
    if (isNaN(seconds)) throw new Error('Seconds must be a number');
    else this._midrollDelay = seconds;
  }

  get isPrerollEnabled() {
    return this._prerollEnabled;
  }

  set prerollEnabled(bool) {
    this._prerollEnabled = bool;
  }

  get isMidrollEnabled() {
    return this._midrollEnabled;
  }

  set midrollEnabled(bool) {
    this._midrollEnabled = bool;
  }

  get hasPrerollPlayed() {
    return this._prerollPlayed;
  }

  get hasPrerollVODPlayed() {
    return this._prerollVODPlayed;
  }

  get nextAdIn() {
    if (this._prerollEnabled && !this.hasPrerollPlayed) {
      return this._prerollDelay - this._adIntervalCounter;
    }

    if (this._errorCounter > 0) {
      return this._errorDelay - this._adIntervalCounter;
    }

    return this._midrollDelay - this._adIntervalCounter;
  }

  get lastAdAgo() {
    return this._adAgoCounter;
  }

  set lastAdAgo(value) {
    this._adAgoCounter = value;
  }

  get lastAdVODAgo() {
    return this._adVODAgoCounter;
  }

  set lastAdVODAgo(value) {
    this._adVODAgoCounter = value;
  }

  get shouldRetryOnError() {
    return this._disableAfterErrors <= -1 || this._errorCounter <= this._disableAfterErrors;
  }

  listen = () => {
    if (!this._listening) {
      // document.addEventListener(AD_STARTED_EVENT, this.onAdStarted);
      // document.addEventListener(AD_ENDED_EVENT, this.onAdEnded);
      // document.addEventListener(ADPOD_FAILED_EVENT, this.onAdpodFailed);

      this._listening = true;
    }
  };

  unlisten = () => {
    if (this._listening) {
      // document.removeEventListener(AD_STARTED_EVENT, this.onAdStarted);
      // document.removeEventListener(AD_ENDED_EVENT, this.onAdEnded);
      // document.removeEventListener(AD_FAILED_EVENT, this.onAdFailed);

      this._listening = false;
    }
  };

  init = (delay = undefined) => {

    this.listen();

    document.addEventListener(ADPOD_STARTED_EVENT, this.onAdpodStarted);
    document.addEventListener(AD_STARTED_EVENT, this.onAdStarted);
    document.addEventListener(AD_ENDED_EVENT, this.onAdEnded);
    document.addEventListener(ADPOD_FAILED_EVENT, this.onAdpodFailed);
    document.addEventListener(ADPOD_ENDED_EVENT, this.onAdpodEnded);

    this._initialized = true;

    if (this.isPrerollEnabled || this.isMidrollEnabled) {
      this.startTimer();
    }
  };

  pause = () => {
    clearInterval(this._adInterval);
    clearTimeout(this._autoResumeTO);
  };

  stop = () => {
    this.pause();
    this.unlisten();
    clearTimeout(this.prerollTimeOut);
    this._prerollPlayed = false;
    this._errorCounter = 0;
    this._adIntervalCounter = 0;
  };

  resume = () => {
    this.listen();
    clearInterval(this._adInterval);
    clearTimeout(this._autoResumeTO);
    if (this.isPrerollEnabled && !this.hasPrerollPlayed) {
      this._adInterval = setInterval(() => {
        if (!this._listening) return;

        // play delayed preroll interval
        this._adIntervalCounter += 1;
        if (this._adAgoCounter >= 0) this._adAgoCounter += 1;

        if (this._adVODAgoCounter >= 0) this._adVODAgoCounter += 1;

        if (
          (this._errorCounter > 0 && this._adIntervalCounter > this._errorDelay && this.shouldRetryOnError) ||
          this._adIntervalCounter === this._prerollDelay + 1
        ) {
          this.pause();

          playAd(AD_TYPE_PREROLL);

          this._autoResumeTO = setTimeout(() => {
            this.resume();
          }, 2000);
        }
      }, 1000);
    } else if (this.isMidrollEnabled) {
      this._adInterval = setInterval(() => {
        if (!this._listening) return;

        // play midroll interval
        this._adIntervalCounter += 1;

        if (this._adAgoCounter >= 0) this._adAgoCounter += 1;

        if (this._adVODAgoCounter >= 0) this._adVODAgoCounter += 1;

        if (
          this.isMidrollEnabled &&
          ((this._errorCounter > 0 && this._adIntervalCounter > this._errorDelay && this.shouldRetryOnError) ||
            this._adIntervalCounter === this._midrollDelay + 1)
        ) {
          this.pause();

          this.playMidroll();

          this._autoResumeTO = setTimeout(() => {
            this.resume();
          }, 2000);
        }
      }, 1000);
    }
  };

  playMidroll = () => {
    this.pause();
    playAd(AD_TYPE_MIDROLL);
  };

  startTimer = () => {
    this.pause();
    this.resume();
  };

  restartTimer = (cleanErrors = true) => {
    this.pause();

    if (cleanErrors) this._errorCounter = 0;
    this._adIntervalCounter = 0;
    this._adAgoCounter = 0;
    this._adVODAgoCounter = 0;

    this.resume();
  };

  onAdStarted = () => {
    if (!this._listening) return;

    this.pause();
  };

  onAdEnded = () => {
    if (!this._listening) return;

    if (!this._prerollPlayed) {
      this._prerollPlayed = true;
    }
    if (!this._prerollVODPlayed) {
      this._prerollVODPlayed = true;
    }
    this.restartTimer();
  };

  onAdpodStarted = () => {
    this._midrollDelay = 8 * 60;

    this._lastAdsDurationInterval = setInterval(() => {
      this._lasAdsDuration += 1;
    }, 1000);
  };

  onAdpodFailed = () => {
    if (!this._listening) return;
    console.error(AdsTimer.TAG, 'Ad playing failed.', this._errorCounter, 'try out of', this._disableAfterErrors);
    this.pause();
    this._errorCounter += 1;
    if (this.shouldRetryOnError) {
      this.restartTimer(false);
    } else {
      if (!this._prerollPlayed) {
        this._prerollPlayed = true;
      }
      if (!this._prerollVODPlayed) {
        this._prerollVODPlayed = true;
      }

      this._midrollDelay = 8 * 60;
      clearInterval(this._lastAdsDurationInterval);
      this._lasAdsDuration = 0;

      this.restartTimer();
    }
  };

  onAdpodEnded = () => {
    if (!this._listening) return;

    if (!this._prerollPlayed) {
      this._prerollPlayed = true;
    }
    if (!this._prerollVODPlayed) {
      this._prerollVODPlayed = true;
    }

    clearInterval(this._lastAdsDurationInterval);
    this._midrollDelay -= this._lasAdsDuration - 1;
    this._lasAdsDuration = 0;

    this.restartTimer();
  };
}

const adsTimer = new AdsTimer();

if (AdsTimer.DEBUG) {
  window.adsTimer = adsTimer;
}

export default adsTimer;
