/* eslint-disable import/prefer-default-export,no-console */
import axios from 'axios';

import CONSTANTS from './constants';

import appProperties from '../../app-properties';
// eslint-disable-next-line import/no-cycle
import { localFiles } from '../../../helpers';
import { deviceLanguage } from '../../../utils';
import { getLanguages } from '../../../services/epg_api';

function callAction(action, newState) {
  return {
    type: action,
    payload: newState
  };
}

function getTranslation(langCode) {
  return localFiles.getFileContent(`locales/${langCode}.json`);
}


const fallbackLanguage = {
  code: 'en',
  name: 'English',
  local: true,
  translations: () => getTranslation('en'),
  privacy: () => axios.get(`https://files.rlaxxtv.com/files/privacy-policy/en.html`)
};


export function loadTranslation(code) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      if (code !== appProperties.fallback_language) {
        // eslint-disable-next-line no-use-before-define
        const language = getValidLanguage(getState().locales.list, code);
        if (!language) {
          reject(`We did not found a valid language for the code ${code}`);
          return;
        }
        const { translations, privacy } = language;

        if (typeof translations === 'string') {
          axios.get(translations).then(translation => {
            axios
              .get(privacy)
              .then(translatedPrivacy => {
                dispatch(callAction(CONSTANTS.SET_PRIVACY_TRANSLATED, translatedPrivacy.data));
              })
              .catch(error => {
                // eslint-disable-next-line no-console
                console.error('Error retrieving translated privacy policy:', error);
                dispatch(callAction(CONSTANTS.SET_PRIVACY_TRANSLATED, undefined));
              })
              .then(() => {
                resolve(dispatch(callAction(CONSTANTS.SET_TRANSLATIONS, translation.data)));
              });
          });
        } else {
          resolve();
        }
      } else {
        resolve();
      }
    });
}

export function setLanguage(code) {
  return dispatch =>
    new Promise((resolve, reject) => {
      if (code) {
        resolve(dispatch(callAction(CONSTANTS.SET_LANGUAGE, code)));
      } else {
        reject(`${code} is not a valid language`);
      }
    }).then(() => {
      if (code !== appProperties.fallback_language) {
        return dispatch(loadTranslation(code));
      }
      dispatch(callAction(CONSTANTS.SET_PRIVACY_TRANSLATED, undefined));
      return dispatch(callAction(CONSTANTS.SET_TRANSLATIONS, undefined));
    });
}

function getValidLanguage(languagesList, lookForCode) {
  if (Array.isArray(languagesList)) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < languagesList.length; i++) {
      if (languagesList[i].code === lookForCode) {
        return languagesList[i];
      }
    }
  }
  return fallbackLanguage;
}

const getFallbackLanguage = getState =>
  getValidLanguage(getState().locales.list || [], appProperties.fallback_language);

export function initTranslations() {
  return (dispatch, getState) =>
    new Promise((resolve) => getLanguages().then(response => {
        resolve(dispatch(callAction(CONSTANTS.SET_LANGUAGE_LIST, response.data)))})
        // eslint-disable-next-line no-console
        .catch(err => console.log(err)))
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error('There was an error getting the languages list:', err);
      })
      .then(() => {

        const fallback = getFallbackLanguage(getState);

        return (fallback.local ? fallbackLanguage.translations() : axios.get(fallback.translations))
          .then(response => dispatch(callAction(CONSTANTS.SET_FALLBACK, response.data)))
          .catch(error => {
            console.error('Error while loading fallback translations:', error);
          });
      })
      .then(() => {

        const fallback = getFallbackLanguage(getState);

        return (fallback.local ? fallbackLanguage.privacy() : axios.get(fallback.privacy))
          .then(response => dispatch(callAction(CONSTANTS.SET_PRIVACY_FALLBACK, response.data)))
          .catch(error => {
            console.error('Error while loading fallback privacy policy:', error);
          });
      })
      .then(() => {
        const savedLanguageCode =
          localStorage.getItem(CONSTANTS.STORAGE_SAVED_LANGUAGE) ||
          getValidLanguage(getState().locales.list, deviceLanguage()).code;
        if (savedLanguageCode) {
          return dispatch(setLanguage(savedLanguageCode));
        }
        return Promise.resolve();
      });
}
