/* eslint-disable prefer-object-spread */
import CONSTANTS from './constants';

const initialState = {
  language: undefined,
  list: undefined,
  translations: undefined,
  fallback: undefined,
  translatedPrivacy: undefined,
  fallbackPrivacy: undefined
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CONSTANTS.SET_LANGUAGE_LIST: {
      return Object.assign({}, state, { list: action.payload });
    }
    case CONSTANTS.SET_LANGUAGE: {
      localStorage.setItem(CONSTANTS.STORAGE_SAVED_LANGUAGE, action.payload);
      return Object.assign({}, state, { language: action.payload });
    }
    case CONSTANTS.SET_TRANSLATIONS: {
      return Object.assign({}, state, { translations: action.payload });
    }
    case CONSTANTS.SET_FALLBACK: {
      return Object.assign({}, state, { fallback: action.payload });
    }
    case CONSTANTS.SET_PRIVACY_TRANSLATED: {
      return Object.assign({}, state, { translatedPrivacy: action.payload });
    }
    case CONSTANTS.SET_PRIVACY_FALLBACK: {
      return Object.assign({}, state, { fallbackPrivacy: action.payload });
    }
    default:
      return state;
  }
}
