import React from 'react';
import {connect} from "react-redux";
// import * as PropTypes from "prop-types";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import './style.scss';
import Lottie from "react-lottie";
import history from "../../storage/history";
import PlayerContext from '../../storage/contexts/PlayerContext';
import splashLogoData from "../../helpers/splash-logo.json"
import spinnerData from "../../helpers/spinner.json"
import secondSequence from '../../assets/images/second-sequence.svg'
import thirdSequence from '../../assets/images/third-sequence.svg'
import Localised from "../../components/localised";
import DidomiConsentPreferences from "../../components/DidomiConsentPreferences";
import {isPortrait} from "../../helpers/measurements";
import {PAGES, trackPageView} from "../../helpers/tracking";
// import {EVENTS, PAGES, trackCustomEvent, trackPageView} from '../../helpers/tracking'
// import {isPortrait} from "../../helpers/measurements";





class SplashScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = { sequence: 0 };
    this.animationWidth=220;
  }

  componentDidMount() {

    trackPageView(PAGES.STARTUP, "splash")
    const { current } = this.context;


    const {loadFailed}=this.props;
    if (current) {
      current.mute();
    }
    let time = 0;
    let stateSequence = 0;
    this.statusInterval = setInterval(() => {
      time += 5;
      if (time===5 || time === 15 || time === 30 || time === 60) {
        stateSequence += 1;
        this.setState({ sequence:stateSequence },()=>{
          // eslint-disable-next-line no-shadow
          const {sequence}=this.state;
          if(sequence===4) {
            history.push({ pathname: `/error/400`, state: { clearStorage: false } });
            loadFailed();
          }
        });
      }

    }, 5000);
  }


  componentWillUnmount() {
    clearInterval(this.statusInterval);
    this.setState({sequence:0})
  }



  setAnimationWidth=()=>{
    const {deviceType}=this.props;
    if(deviceType==="desktop"){
      this.animationWidth=540;
    }else if(deviceType==="tablet"){
      this.animationWidth=382.8;
    }else{
      this.animationWidth=220;
    }
   return this.animationWidth;
  }

  setSpinnerWidth=()=>{
    const {deviceType}=this.props;
    let width;
    if(deviceType==="desktop"){
      width=67;
    }else if(deviceType==="tablet") {
      width=100;
    }else{
      width=40;
    }
    return width;
  }

  renderSequentially() {
    const { sequence } = this.state;
    const {  deviceType } = this.props;

    if (sequence === 0) {
      const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData:splashLogoData,
      };
      return (
        <div className="splash-logo">
          <Lottie
            options={defaultOptions}
            width={this.setAnimationWidth()}
          />
        </div>

      );
    }
    if (sequence === 1) {
      const didomi= <DidomiConsentPreferences
        type="splash"
      />
      return (
        <div className="second-sequence consent">
          <img src={secondSequence} alt="watching-tv" />
          {didomi}
        </div>
      );
    }
    if (sequence === 2) {
      const header=   <Localised node="startup.loading-hint-1.title" />
      const description=<Localised node="startup.loading-hint-1.description" />
      return (
        <div className="second-sequence">
          <div
            className="third-sequence-explaination"
            style={deviceType!=="desktop" ? { order: 2 } : { order: 1 }}
          >
            <h2>
              {header}
            </h2>
            <p>
              {description}
            </p>
          </div>
          <div
            className="second-sequence-image"
            style={deviceType!=="desktop" ? { order: 1 } : { order: 2 }}
          >
            <img src={secondSequence} alt="watching-tv" />
          </div>

        </div>
      );
    }
    if (sequence === 3) {
      const header=<Localised node="startup.loading-hint-2.title" />
      const description= <Localised node="startup.loading-hint-2.description" />
      return (
        <div className="second-sequence">
          <div
            className="third-sequence-explaination"
            style={deviceType!=="desktop" ? { order: 2 } : { order: 1 }}
          >
            <h2>{header} </h2>
            <p>{description}</p>
          </div>
          <div
            className="second-sequence-image"
            style={deviceType!=="desktop" ? { order: 1 } : { order: 2 }}
          >
            <img src={thirdSequence} alt="watching-tv" />
          </div>
        </div>
      );
    }

    return null;
  }

  render() {
    const {  deviceType } = this.props;
    const { sequence } = this.state;
    const spinnerOptions = {
      loop: true,
      autoplay: true,
      animationData:spinnerData,
    };

    return (
      <div className={`loading-wrapper
       ${deviceType!=="desktop" && "mobile"}
       ${deviceType==="mobile" && isPortrait()==="landscape" && "landscape"}
       ${deviceType==="tablet" && "tablet"}`}>
        <TransitionGroup className="splash-transition">
          <CSSTransition key={sequence} in appear timeout={250} classNames="splash-transition">
            <div className="splash-wrapper">
              {sequence !== 0 && sequence !== 4 && (
                <div className="web-spinner">
                  <Lottie options={spinnerOptions} width={this.setSpinnerWidth()} />
                </div>
              )}
              {this.renderSequentially()}
            </div>
          </CSSTransition>
        </TransitionGroup>
        <div style={{opacity:"0"}}>
          <img src={secondSequence} alt="watching-tv" />
          <img src={thirdSequence} alt="watching-tv" />
        </div>
      </div>
    );
  }
}



const mapStateToProps = state => ({
  deviceType:state.app.deviceType
});

SplashScreen.contextType = PlayerContext;

export default connect(mapStateToProps, null, null,)(SplashScreen);
