/* eslint-disable react/button-has-type,jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */
import React from 'react'
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import axios from "axios";
import VanillaTilt from "vanilla-tilt";
import history from "../../storage/history";
import Header from "../../components/header";
import back from '../../assets/rlaxxTV_Icons_SVG_220114/icon/back/white.svg'
import logo from '../../assets/images/rlaxx_logo_mobile.svg'
import TabBar from "../../components/tab-bar";
import Localised, {getTranslation} from "../../components/localised";
import {images} from "../../helpers";
import {
  getAssetChannelTitle,
  getAssetTitle,
  getAssetWatermark,
  trackAssetEvent
} from "../../helpers/assets";
import './style.scss'
import up from "../../assets/rlaxxTV_Icons_SVG_220114/icon/indicator-arrow-up/white.svg";
import ContentDetail from "../content-detail/single-item";
import {EVENTS, PAGES, trackPageView} from '../../helpers/tracking'
import RotateWarning from "../rotate-warning";
import {changeHeaderTransition, isPortrait, mobileSize} from "../../helpers/measurements";
import Spinner from "../../components/spinner";
import {appendSRParams} from "../../storage/sr-properties";
import {getPartnerIdentifierFromAsset} from "../../helpers/channels";
import {getSRAssetSerieData} from "../../helpers/partners/sportradar";
import seriesImg from "../../assets/rlaxxTV_Icons_SVG_220114/icon/series/white.svg";
import playlistImg from "../../assets/rlaxxTV_Icons_SVG_220114/icon/playlist/white.svg";
import store, {playerActions} from "../../storage/store";
import PlayerContext from "../../storage/contexts/PlayerContext";
import CollectionDetail from "../content-detail/collection-item";




class OnDemandChannels extends React.Component{
    constructor(props) {
        super(props);
        const {vod, match: {
          params: { contentId }
        }}=this.props;
        this.refContentDetail=React.createRef();
        this.channels=vod.find(item=>item.id===Number(contentId))
        this.refChannelGroup =React.createRef()
        this.refOnDemandChannels =React.createRef()
        this.selectItemToShow=this.selectItemToShow.bind(this);
        this.closeItem=this.closeItem.bind(this);
        this.state={selectedItem:{assetType:undefined, partnerId:undefined, assetId:undefined,teaserType:undefined, pageId:undefined, scrolled:false}}
    }

    componentDidMount() {
      sessionStorage.setItem('onDemandChannelUrl', window.location.hash);
      if(localStorage.scrollPosition) {
        document.getElementById("onDemandChannelsAll").scrollTop = localStorage.getItem("scrollPosition");
      }
      const {screenSize}=this.props;
      VanillaTilt.init(document.querySelectorAll('.channel-wrapper '), {
        max: 0,
        speed: 400,
        scale:1.03,
        glare: true,
        transition:true,
        'max-glare': screenSize>=mobileSize?0.4:0.1,
      })
      this.getAssetIdFromPathname().catch(()=>this.closeItem());
        trackPageView(PAGES.ON_DEMAND, 'all', this.channels.title.en);
        const {current} =this.context;
        if(current) current.mute();
        this.requestNextPage();
      if(this.refOnDemandChannels.current) this.refOnDemandChannels.current.addEventListener('scroll',()=>{
        changeHeaderTransition(this.refOnDemandChannels,"on-demand-mobile-header")

      })
    }

  componentDidUpdate(prevProps) {
    const {
      vod,
      match: {
        params: { contentId,assetId },
      },
    } = this.props;

    if (prevProps.match.params.assetId !== assetId && assetId){
      this.getAssetIdFromPathname().catch(()=>{
        this.closeItem()});
    }
    if(prevProps.match.params.contentId !== contentId && contentId){
      this.channels=vod.find(item=>item.id===Number(contentId))
      if(this.channels){
        this.forceUpdate();
      }

    }
  }

  componentWillUnmount() {
    const scrollPosition = document.getElementById("onDemandChannelsAll").scrollTop;
    localStorage.setItem("scrollPosition", scrollPosition);
    clearTimeout(this.selectedItemTo)
  }

  getAssetFromAssetId=(assetId)=>{
    const assetToShow=this.channels.content.find((item) => Number(item.id) === Number(assetId));
    if(assetToShow) return assetToShow;
    return undefined;
  }



  getAssetIdFromPathname() {
    const {
      match: {
        params: { assetId },
      },
    } = this.props;

    const {selectedItem}=this.state;
    if(assetId===selectedItem.assetId ) Promise.resolve();
    return new Promise((resolve) => {
      this.selectedItemTo = setTimeout(() => {
        const asset = this.getAssetFromAssetId(assetId);
        if(asset?.id){ const assetToShow = {...asset,
          assetType: "vod",
          assetId: asset.id,
          partnerId: getPartnerIdentifierFromAsset(asset),
          pageId:asset?.page_id
        };
          this.selectItemToShow(assetToShow)
          this.forceUpdate(resolve)}
      },250)})
  }

  requestNextPage = () => {
    const { vod, match: {
      params: { contentId }
    }} = this.props;
    const row= vod.find(item=>item.id===Number(contentId))
    const currentRow=vod.indexOf(row)
    const module = vod[currentRow];
    const {
      meta,
    } = module;

    const currentPage = meta?.current_page;

    if (module?.links?.next && !this.requesting) {
      this.requesting = true;

      axios
        .get(appendSRParams(module?.links?.next))
        .then(({ data }) => {
          if (data.meta.current_page > currentPage) {
            module.meta = data.meta;
            module.links = data.links;
            this.channels.content = [
              ...this.channels.content,
              ...data.data.content
            ];
          }
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error("Couldn't load the next page:", error);
        })
        .then(() => {
          this.requesting = false;
          this.forceUpdate();
        });
    }
  };

  playContinueToWatchItem = (continueVideo) => {
    const {current}=this.context;
    store.dispatch(playerActions.setPlayingVideo(continueVideo))
      .then(()=>current.setStartTime(continueVideo.savedTime)).then(() => {

      trackAssetEvent(EVENTS.VIDEO.ACTIONS.PLAY_INITIAL,continueVideo,'on-demand');

      trackPageView(PAGES.ON_DEMAND, `${PAGES.PLAYER}`, getAssetChannelTitle(continueVideo),
        getAssetTitle(continueVideo))


      history.push('/player');


    }).catch(e => {
      console.error(e);
      history.push({ pathname: '/error/501', state: { clearStorage: true } });

    });

  }


    selectItemToShow(asset){
        this.setState({selectedItem:asset},()=>{
          const {selectedItem}=this.state;
          const {
            match: {
              params: { contentId },
            },
          } = this.props;
          history.replace(`/on-demand/vod/${contentId}/asset/${selectedItem.assetId}`)
        })

    }

    closeItem(){
      this.setState({scrolled:0})
      this.setState({selectedItem:{assetType:undefined, partnerId:undefined, assetId:undefined, teaserType:undefined}},()=>{
          const {
            match: {
              params: { contentId },
            },
          } = this.props;
          history.push(`/on-demand/vod/${contentId}`)
        })
    }


    scrollTop(){
        if(this.refChannelGroup.current){
            this.refOnDemandChannels.current.scrollTop=this.refOnDemandChannels.current.offsetTop;
         }
    }




    clickItemToShow(item){
        if(item.teaserType==="promo"){
            history.push(`/on-demand/channel-page/${item.page_id}`)
        }else if(item.savedTime){

          this.playContinueToWatchItem(item)
        }else if(item.teaserType==="content"){
            const selectedContent={assetType:"vod", partnerId:-1, assetId:item.id, teaserType:undefined, pageId:undefined}
            this.selectItemToShow(selectedContent)

        }else if(item.teaserType==='collection'){
           const selectedContent={assetType:"vod", partnerId:-1, assetId:item.id, teaserType:item.teaserType, pageId:item.page_id}
            this.selectItemToShow(selectedContent)
        }
    }

  backgroundImage(item){
    const path=item?.content?.editorial?.image.path||item?.editorial?.image.path
    if(this.channels.type==="vertical-promotion" && item) return `url(${item.image})`
    return `url(${images.getSRContentImage(path,'400x225')})`
  }


  renderContentDetail(){
    const{selectedItem, scrolled}=this.state;
    if(selectedItem.teaserType==="collection") return <CollectionDetail
      assetType={selectedItem.assetType}
      assetId={selectedItem.assetId}
      partnerId={selectedItem.partnerId}
      closeItem={this.closeItem}
      selectItem={this.selectItemToShow}
      collectionAsset={selectedItem}
      teaserType={selectedItem.teaserType}
      pageId={selectedItem.pageId}
      pageView={PAGES.ON_DEMAND}
      scrolled={scrolled}
      // scrollTop={this.scrollTopDetailPage}
    />
    return <ContentDetail assetType={selectedItem.assetType}
                          assetId={selectedItem.assetId}
                          partnerId={selectedItem.partnerId}
                          closeItem={this.closeItem}
                          selectItem={this.selectItemToShow}
                          teaserType={selectedItem.teaserType}
                          pageId={selectedItem.pageId}
                          pageView={PAGES.ON_DEMAND}
    />

  }


    renderChannels=()=>{
       const {vod, deviceType}=this.props;


        return  (vod&&<div ref={this.refChannelGroup} className="channel-group">
                    <div className= {`content-title ${deviceType==="tablet" &&"tablet"}`}>
                        {deviceType==="desktop" &&
                        // eslint-disable-next-line import/no-named-as-default-member
                        <button onClick={()=>{history.push("/on-demand")}}>
                          <img src={back} alt="back-button"/> <Localised node="buttons.back" />
                        </button>}
                        <p> <Localised node={this.channels.title.en}/> </p> </div>
                      <div className={`content-wrapper
                      ${deviceType==="tablet" &&"tablet"}
                      ${this.channels.type==="vertical-promotion"?'withoutTitle':'withTitle'}`}>
                          {this.channels.content.map(
                              item=>{
                                let title=getAssetTitle(item)||undefined;
                                const serieData = getSRAssetSerieData(item);
                                const seasons=getTranslation("general.season_short")
                                const episodes=getTranslation("general.episode_short")
                                let subtitle=serieData.id?serieData.title:undefined
                                const seasonTitle = (item.serieData?.seasons && item.serieData.seasons.length > 1)
                                ? getTranslation("general.seasons") :
                                getTranslation("general.season");
                                if (serieData && serieData.episode) {
                                  title = `${seasons}${serieData.season}
                                  ${episodes}${serieData.episode}: ${title}`;
                                }

                                if( item.teaserType==="collection"){
                                  subtitle=item.serieId?`${item.serieData.seasons.length} ${seasonTitle}`
                                    :
                                    `${getTranslation("general.playlist")}`

                                }
                                const continueWatchingItem=item.savedTime;
                                const duration=item?.content?.playtime||item?.playtime;

                                return (item.id&&
                                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                                  <div className={`channel-wrapper ${deviceType==="tablet" &&"tablet"}
                                  ${this.channels.type!=="vertical-promotion"&& deviceType!=="mobile"&&'withTitle'}`}
                                       onClick={()=>{this.clickItemToShow(item)} }>

                                      <div key={item.id} className="channel-div" role="button"
                                           style={{backgroundImage:this.backgroundImage(item)}}>
                                          {item.type!=="vertical-promotion"&& getAssetWatermark(item)&&
                                          <img  className="asset-watermark" src={getAssetWatermark(item)} alt="channel-watermark"/>}
                                        {item.teaserType!=="promo"&&
                                          <div className={`item-desc ${item.savedTime && "continue-to-watch"}`}>
                                            {subtitle &&
                                              <div className="item-subtitle">
                                                <img src={item.serieId?seriesImg:playlistImg} alt="series"/>
                                                <p>{subtitle}</p>
                                              </div>
                                            }
                                            <div className="item-title">
                                              <p className={item.type === "collection" && "collection"}>{title.en||title}</p>
                                            </div>
                                          </div>}
                                        {continueWatchingItem &&
                                          <div className="continue-watching-item">
                                            <div className="duration">
                                              <div style={{ width:`${(item.savedTime/duration)*100}%`}}/>
                                            </div>
                                          </div>
                                        }
                                      </div>



                                  </div>
                              )}
                          )}
                      </div>
                </div>)
    }

    renderHeader(){
      const {screenSize, deviceType}=this.props;
      if(deviceType==="desktop") return   <Header screenSize={screenSize} deviceType={deviceType} />
      return (
          <div id="on-demand-mobile-header" className="on-demand-mobile-header">
              {/* eslint-disable-next-line import/no-named-as-default-member */}
            <div className="target-wrapper" role="button" onClick={()=>{history.push('/on-demand')}}>
              <button ><img src={back} alt="back-button"/> <Localised node="buttons.back" /></button>
            </div>
              <img src={logo} alt="rlaxx-logo"/>
          </div>
      )
    }


    render(){
        const {vod, deviceType}=this.props
        const {selectedItem }=this.state;
        return(
            <div className={`on-demand-channels ${deviceType==="desktop" && 'desktop'}`}>
              {deviceType==="mobile"&& isPortrait()==='landscape'&&  <RotateWarning type='portrait'/>}
                <div id="on-demand-channels-header" className="on-demand-channels-header">
                    {this.renderHeader()}
                </div>
                <div id="onDemandChannelsAll" ref={ this.refOnDemandChannels }  className="on-demand-channels-all" onScroll={this.requestNextPage} >
                    {vod&&this.channels?this.renderChannels():<Spinner/>}
                    <div className="back-to-top" role="button" onClick={()=>{this.scrollTop()}}>
                        <button ><img src={up} alt="up-arrow"/> <Localised node="buttons.back_to_top" /></button>
                    </div>
                </div>

                {deviceType!=="desktop" && <TabBar deviceType={deviceType}/>}
                {selectedItem.assetType&&selectedItem.assetId&&selectedItem.partnerId &&
                    <div className="detailed-item-wrapper">
                      <div id="selectedItem"
                        ref={this.refContentDetail}
                        onScroll={()=>
                        {
                          if(selectedItem.teaserType==="collection"){
                            this.setState({ scrolled:this.refContentDetail.current.scrollTop})
                          }
                          else{changeHeaderTransition(this.refContentDetail,"detail-header",
                            deviceType==="desktop"?"linear-gradient(180deg, #0A1320 0%, rgba(10, 19, 32, 0.75) 59.9%, rgba(10, 19, 32, 0) 100%)":"")
                          }  }
                        }
                        className={`selected-item-detail-page ${deviceType}`}>
                              {this.renderContentDetail()}
                      </div>
                    </div>
                }
            </div>
        )
    }


}


OnDemandChannels.contextType = PlayerContext;

OnDemandChannels.propTypes={
  screenSize:PropTypes.number.isRequired,
  vod:PropTypes.arrayOf('object'),
  match:PropTypes.func

}


OnDemandChannels.defaultProps={
  vod:undefined,
  match:undefined
}

const mapStateToProps=state=>({

    screenSize:state.app.screenSize,
    vod: state.app.vod,
    deviceType:state.app.deviceType

})

export default connect(mapStateToProps,)(OnDemandChannels)
