/* eslint-disable import/no-extraneous-dependencies,no-console,react/no-unused-state */
import React from 'react';
import {format, isToday, isWithinInterval} from 'date-fns';
import axios from 'axios';
import SmartBanner from "react-smartbanner";
import MobileDetect from 'mobile-detect'
import history from './storage/history';
import store, { appActions, localesActions, playerActions } from './storage/store';
import { images, video } from './helpers';
import {getChannels, getPromoted, isAccessible} from './services/epg_api';
import appProperties, {setAppProperty} from './storage/app-properties';
import { getCurrentDate, getPartnerIdentifierFromChannel, partnerIdentifiers } from './helpers/channels';
import PlayerContext from './storage/contexts/PlayerContext';
import { getEPGIdFromChannel } from './helpers/video';
import { parsedAssetsFromVevo } from './helpers/partners/vevo';
import contentApis from './services/contentApis';
import { getFromSRAPI, initializeSRProperties, srProperties } from './storage/sr-properties';
import {updatePromotedData, updateVODData} from './storage/redux/app-data/actions';
import assets from './services/apis/sportradar_api';
import SplashScreen from "./views/splash";
import Routes from "./storage/routes";
import './App.scss';
import {initTracking} from "./helpers/tracking";
import BetaGate from "./views/beta-gate";
import 'react-smartbanner/dist/main.css';
import {getOrientation, isPortrait, mobileSize} from "./helpers/measurements";
import {
  getMultipleSerieData,
  getSRAssetMetadata,
} from "./helpers/partners/sportradar";



const SPLASH_ANIMATION_DELAY = 7 * 1000;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialized: false,
      isModal: false,
      isUIForced: true,
      isUIHidden: false,
      betaPassword: true,
      delayError:false,
      translationLoaded:false
    };
  }

  componentDidMount() {
    const deviceType=this.detectDeviceType();
    store.dispatch(appActions.getDeviceType(deviceType))
    this.initApplication()
    window.addEventListener('resize',()=>{
      store.dispatch(appActions.getScreenSize(window.innerWidth))
      const newDeviceType=this.detectDeviceType();
      store.dispatch(appActions.getDeviceType(newDeviceType))
      this.setOrientation()
    })
    const isOnIOS = navigator.userAgent.match(/iPad/i)|| navigator.userAgent.match(/iPhone/i);
    const unload = isOnIOS ? "pagehide" : "beforeunload";
    window.addEventListener(unload, () => {
      store.dispatch(playerActions.setPlayingVideo(undefined));
      sessionStorage.removeItem('isLiveTvMuted')


    });
    document.addEventListener('initfailed', () => {
      window.location.reload();
    });

    window.addEventListener("orientationchange",this.setOrientation)
    if(window?.screen?.orientation?.onchange){
      window.screen.orientation.onchange=this.setOrientation
    }

  }


  componentWillUnmount() {

    window.removeEventListener('INIT_APP_DATA', this.initChannels);
    clearInterval(this.epgInterval);

  }



  isIpadOS=()=>navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform)



  detectDeviceType=()=>{
    const md= new MobileDetect(window.navigator.userAgent);
    const isTablet=md.tablet()
    const isMobile=md.mobile()
    let deviceType;
    if(window.innerWidth>1024 && !isTablet){
      deviceType="desktop"
    }else if(isTablet|| (this.isIpadOS && !isTablet && !isMobile && isPortrait()==="portrait")){
      deviceType="tablet"
    }else if(isMobile||(window.innerWidth<768 && isPortrait()==="portrait")){
      deviceType="mobile"
    }

    return deviceType;
  }


  detectDeviceForAPIKey=()=>{
    const md= new MobileDetect(window.navigator.userAgent);
    const isTablet=md.tablet()
    const isMobile=md.mobile()
    let deviceType;
    if(isTablet||(window.innerWidth<1024&& window.innerWidth>=768 && isPortrait()==="portrait")){
      deviceType="tablet"
    }else if(isMobile||(window.innerWidth<768 && isPortrait()==="portrait")){
      deviceType="mobile"
    }else{

      deviceType="desktop"
    }

    return deviceType;

  }

setOrientation=()=>{
    const rotation=getOrientation();
    store.dispatch(appActions.updateRotation(rotation))
}

  updateEpg = () => {
    const { liveChannels } = store.getState().app;
    return this.retrieveChannelsEPGInformation(liveChannels)
      .then(responses =>this.saveEPGInformation(responses, liveChannels));
  };

  retrieveChannelsEPGInformation = channelsArray => {
    const channelInfoPromises = [];
    const startDate = getCurrentDate(false);
    let clonedDate = new Date(startDate.getTime());

    const today = format(startDate, 'yyyy-MM-dd');

    clonedDate.setDate(startDate.getDate() - 1);
    const yesterday = format(clonedDate, 'yyyy-MM-dd');

    clonedDate.setDate(startDate.getDate() + 1);
    const tomorrow = format(clonedDate, 'yyyy-MM-dd');


    clonedDate = new Date(startDate.getTime());
    clonedDate.setHours(startDate.getHours() - parseInt(process.env.REACT_APP_EPG_HOURS_RANGE_FROM, 10));
    const startDay = format(clonedDate, "yyyy-MM-dd'T'HH:mm:ss");

    clonedDate = new Date(startDate.getTime());
    clonedDate.setHours(startDate.getHours() + parseInt(process.env.REACT_APP_EPG_HOURS_RANGE_TO, 10));
    const endDay = format(clonedDate, "yyyy-MM-dd'T'HH:mm:ss");

    const requestEpg = url =>
      axios.get(url).catch(error => {
        // eslint-disable-next-line no-console
        console.error(`[EPG] Error getting EGP response (${url}):`, error);
        return undefined;
      });

    channelsArray.forEach(channel => {
      switch (getPartnerIdentifierFromChannel(channel)) {
        case partnerIdentifiers.SPORTRADAR:
          if (channel.externalData.SportRadar.apiData.epg.endpoint) {
            channelInfoPromises.push(
              requestEpg(
                `${channel.externalData.SportRadar.apiData.epg.endpoint}&startDay=${startDay}&endDay=${endDay}`
              )
            );
          }
          break;
        case partnerIdentifiers.VEVO:
          if (channel.externalData.Vevo.apiData.epg) {
            channelInfoPromises.push(requestEpg(channel.externalData.Vevo.apiData.epg.blocks));
            channelInfoPromises.push(requestEpg(channel.externalData.Vevo.apiData.epg.content));
          }
          break;
        case partnerIdentifiers.PANTAFLIX:
          if (channel.externalData.Pantaflix.apiData.epg.endpoint) {
            channelInfoPromises.push(
              requestEpg(
                `${channel.externalData.Pantaflix.apiData.epg.endpoint}?api_token=${appProperties.portfolioKey}&day=${yesterday}`
              )
            );
            channelInfoPromises.push(
              requestEpg(
                `${channel.externalData.Pantaflix.apiData.epg.endpoint}?api_token=${appProperties.portfolioKey}&day=${today}`
              )
            );
            channelInfoPromises.push(
              requestEpg(
                `${channel.externalData.Pantaflix.apiData.epg.endpoint}?api_token=${appProperties.portfolioKey}&day=${tomorrow}`
              )
            );
          }
          break;
        default:
          break;
      }
    });

    return Promise.all(channelInfoPromises).then(responses => responses.filter(Boolean));
  };

  initEPGInterval = () => {
    clearInterval(this.epgInterval);
    this.epgInterval = setInterval(() => {
      this.updateEpg().catch(error => {
        // eslint-disable-next-line no-console
        console.log(error)
      });
    }, 10 * 60 * 1000);
  };

  loadFailed = () => {
    this.setState({ initialized: true, failed: true, delayError:true });
  };

  initApplication = () => {
    if (!appProperties.brand) setAppProperty('brand','Web')
    const backendApiKey=this.detectDeviceForAPIKey()==="desktop"
      ?process.env.REACT_APP_RLAXX_BACKEND_TOKEN
      :process.env.REACT_APP_RLAXX_MOBILE_BACKEND_TOKEN
    setAppProperty("portfolioKey",backendApiKey)
    initTracking(appProperties.brand, 'Web');
    if(window.innerWidth < mobileSize && history.location.pathname.includes('/program-guide')){
      const mobileUrl = history.location.pathname.replace("program-guide", "home");
      if (!appProperties.gdprAccepted) {
        history.push({pathname:"/start", state:{link: `${mobileUrl}`}})
      }
      else{
        history.push({pathname:mobileUrl});
      }
    }
    if (!appProperties.gdprAccepted) {
      history.push({pathname:"/start", state:{link: `${history.location.pathname}`}})

    }

    const {current}=this.context;
    store
      .dispatch(localesActions.initTranslations())
      .then(() =>this.setState({translationLoaded:true}))
      .then(()=> isAccessible().catch(error=>{
        if(error?.response?.status===423) {
          const isBeta=localStorage.getItem('betagate')
          const beta=isBeta?JSON.parse(isBeta):false
          if(beta===true){
            this.setState({betaPassword:true},()=>{
              console.info("user has already entered the password")
            })
          }else {
            setTimeout(() => {
              this.setState({betaPassword: false})
            }, 2500)
          }
        }
        else{
          throw error;
        }
      }))
      .then(()=>{
        if (!history.location.pathname.includes('/on-demand/') && !history.location.pathname.includes('epg')) {
          if (!appProperties.gdprAccepted) {
            history.push({pathname:"/start", state:{link: `${history.location.pathname}`}})
            this.initPromoted(true);
          } else {
            this.initPromoted();
          }
        }
      })
      .then(() =>this.initChannels())
      .then(() => this.initBigScreen())
      .then(() => {
        const { liveChannels } = store.getState().app;
        if (!liveChannels || liveChannels.length <= 0) {
          throw new Error('There is no live channel available');
        }
        if (current) {
          current.mute();
        }
        setTimeout(() => {
          if (current) {
            current.mute();
          }
        }, SPLASH_ANIMATION_DELAY / 2);
        setTimeout(() => {
          const {delayError}=this.state;
          this.setState({ initialized: true, failed: false },()=>{
            // eslint-disable-next-line no-unused-expressions
            console.info('<< Application Initialized >>');
            if(!delayError) {
              if (appProperties.gdprAccepted) {
                const enteredPath=(history.location.pathname.indexOf('start')!==-1 ||history.location.pathname.includes("/error"))
                  ?
                  "/home"
                  :
                  history.location.pathname
                history.push(`${enteredPath}`)
              } else {
                history.push({pathname:"/start", state:{link: `${history.location.pathname}`}})
              }
            }
          });

          this.initEPGInterval();


        }, SPLASH_ANIMATION_DELAY);
      })
      .catch(error => {
        let errorPath = '';
        let maintenanceDate='';
        if (error?.response?.status) {
          errorPath = error?.response?.status;

          if (error?.response?.data?.type) {
            errorPath += `/${error?.response?.data?.type}`;
          }


          if (error?.response?.data?.expected_until_utc_time) {
            maintenanceDate = new Date(error?.response?.data?.expected_until_utc_time);

            maintenanceDate = format(maintenanceDate, isToday(maintenanceDate) ? 'HH:mm' : 'dd/MM/yyyy HH:mm');


          }
        }


        history.push({ pathname: `/error/${errorPath}`, state: { clearStorage: false, maintenanceDate } });


        setTimeout(() => {
          this.setState({ initialized: true, failed: true });
        }, SPLASH_ANIMATION_DELAY);
      });
  };

  orderEPGGroups = epgData => {
    let orderEpgData = [];

    epgData.assets.forEach(asset => {
      const startAsset = new Date(asset.start);
      const endAsset = new Date(asset.end);
      epgData.assetGroups.forEach(group => {
        const startUnix =  new Date(group.start);
        const endUnix = new Date(group.end);
        // eslint-disable-next-line no-param-reassign
        group.startUnix = startUnix.getTime() / 1000;
        // eslint-disable-next-line no-param-reassign
        group.endUnix = endUnix.getTime() / 1000;
        // eslint-disable-next-line no-param-reassign
        group.startTime = new Date(startUnix.getTime());
        // eslint-disable-next-line no-param-reassign
        group.endTime = new Date(endUnix.getTime());
        // eslint-disable-next-line no-param-reassign
        group.duration = (group.endTime - group.startTime) / 1000;
        const startGroupDate = new Date(group.start);
        const endGroupDate = new Date(group.end);
        const groupInterval = { start: startGroupDate, end: endGroupDate };
        if (isWithinInterval(startAsset, groupInterval) && isWithinInterval(endAsset, groupInterval)) {
          if (!orderEpgData.includes(group)) {
            // eslint-disable-next-line no-param-reassign
            group.assets = group.assets.map(b => epgData.assets.find(a => a.id === b?.id));
            orderEpgData.push(group);
          }
        } else if (!orderEpgData.includes(asset)) orderEpgData.push(asset);
      });
    });

    epgData.assetGroups.forEach(group => {
      group.assets.forEach(groupAsset => {
        // eslint-disable-next-line no-shadow
        orderEpgData = orderEpgData.filter(assets => assets.id !== groupAsset?.id);
      });
    });
    return orderEpgData;
  };

  saveEPGInformation = (epgResponses, channels) =>
    new Promise(resolve => {
      const filtered = epgResponses
        .filter(response => response.status === 200 && response.data?.assets?.length > 0)
        .reduce((obj, response) => {
          // todo: merge assets if channel is already saved on obj
          if (response.data.slug && response.data.slug.startsWith('vevo')) {
            // response.data.id == 43406 || response.data.id == 43408
            response.data.assets = parsedAssetsFromVevo(response.data.assets);
          }

          if (obj[response.data.id]?.data?.slug !== response.data?.slug) {
            // eslint-disable-next-line no-param-reassign
            obj[response.data.slug] = response.data;
          } else if (!obj[response.data.id]) {
            // eslint-disable-next-line no-param-reassign
            obj[response.data.id] = response.data;
          } else {
            // eslint-disable-next-line no-param-reassign
            obj[response.data.id].assets = [...obj[response.data.id].assets, ...response.data.assets];
          }

          return obj;
        }, {});

      const filteredKeysSort = Object.values(filtered);
      const workingSRChannels = [];
      filteredKeysSort.forEach(channel => {
        store.getState().app.liveChannels.find((liveChannel, i) => {
          const cguid = video.getEPGIdFromChannel(liveChannel);
          // eslint-disable-next-line eqeqeq
          if (cguid == channel.id) {
            filtered[cguid].channelInfo = liveChannel;
            workingSRChannels[i] = liveChannel;
            // eslint-disable-next-line eqeqeq
            return cguid == channel.id;
            // eslint-disable-next-line no-else-return
          } else if (typeof cguid === 'string' && cguid.startsWith('vevo') && channel?.slug?.startsWith('vevo')) {
            filtered[channel?.slug].channelInfo = liveChannel;
            workingSRChannels[i] = liveChannel;
            // eslint-disable-next-line eqeqeq
            return cguid == channel.slug;
          }
          return false;
        });
      });
      const { liveChannels, epg } = store.getState().app;

      let parsedWorkingSRChannels;
      if (liveChannels.length !== channels.length) {
        parsedWorkingSRChannels = store.getState().app.liveChannels.concat(workingSRChannels);
      } else {
        parsedWorkingSRChannels = workingSRChannels;
      }

      const orderedChannels = channels.map(channel => parsedWorkingSRChannels.find(x => x === channel)).filter(Boolean);

      const parsedEpgData = { ...epg, ...filtered };

      // eslint-disable-next-line no-restricted-syntax
      for (const key in parsedEpgData) {
        // eslint-disable-next-line no-prototype-builtins
        if (parsedEpgData.hasOwnProperty(key)) {
          const epgData = parsedEpgData[key];
          if (epgData.assetGroups && epgData.assetGroups?.length > 0) {
            epgData.assets = this.orderEPGGroups(epgData);
            parsedEpgData[key] = epgData;
          }
        }
      }

      store.dispatch(appActions.updateLiveChannelsData(orderedChannels)).then(() =>
        store.dispatch(appActions.updateEpgData(parsedEpgData)).then(() => {
          resolve({ newChannels: orderedChannels, newEpg: parsedEpgData });
        })
      );
    });





  initBigScreen = () =>
    contentApis.sportradar
      .getBigScreenConfig()
      .then(resp => {
        store.dispatch(appActions.setCountry(resp.headers['x-country-code']));
        initializeSRProperties(resp.data, resp.headers['x-country-code']);
        // eslint-disable-next-line react/no-unused-state
        this.setState({ srPropertiesRetrieved: true })
        window.$srProperties = srProperties;
      })
      .catch(error => {
        console.error('Error trying to retrieve the BigScreen config:', error);
      })
      .then(()=>this.retrieveVOD())
      .catch(error => {
        console.error('Error trying to retrieve the VOD data:', error);
      });

  initChannels = () =>
    getChannels()
      .then(response => {
        const liveChannels = [];
        const parsed = response.data.map(channel =>{
          // eslint-disable-next-line prefer-object-spread
          const parsedChannel = Object.assign({}, channel, {
            white: images.getPath(images.WHITE, channel.icon),
            colour: images.getPath(images.COLOUR, channel.icon),
            logo: images.getPath(images.LOGO, channel.icon),
            watermark: images.getPath(images.WATERMARK, channel.icon)
          });


          liveChannels.push(parsedChannel);
          return parsedChannel;
        });


        store.dispatch(appActions.updateLiveChannelsData(liveChannels));
        store.dispatch(appActions.updateVODChannelsData(parsed));


        return this.updateEpg();
      })
      .then(() => {
        const {current}=this.context;
        const firstChannelEPGId =getEPGIdFromChannel(store.getState().app.liveChannels[0]);
        current.mute();
        return store.dispatch(
          appActions.updateCurrentPlayingVideoFromChannel(firstChannelEPGId, true, asset => {
            setTimeout(() => {
              current.setStartTime(video.liveVideoTotalProgress(asset.start));
            }, 100);
          })
        );
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error('There was an error getting the PG Channels >>', error);
        history.push({ pathname: '/error/404', state: { clearStorage: true } });
        throw Error;
      });



  initPromoted = (firstTimeUser = false) => {
    getPromoted()
      .then(response => {
        const { data } = response;
        const parsedData = data;
        if (firstTimeUser || appProperties.returningUser|| !appProperties.showPromoted) {
          const savedPromoted = parsedData.map(channel => channel.id);
          localStorage.setItem('promoted', JSON.stringify(savedPromoted));
          setAppProperty('returningUser', false);
          return;
        }
        const promoted = [];
        const savedPromoted = JSON.parse(localStorage.getItem('promoted'));
        parsedData.reverse().forEach(channel => {
          if (savedPromoted) {
            if (promoted.length < 8 && !savedPromoted.includes(channel.id)) promoted.push(channel);
          } else if (promoted.length <8) {
            promoted.push(channel);

          }
        });

        promoted.reverse();
        parsedData.forEach(channel=>{
          if(!savedPromoted.includes(channel.id) && !promoted.includes(channel)){
            savedPromoted.push(channel.id)
          }
        }) // if there are more than 8 channels, expected behaviour is showing just channels, others won't be shown according to Frederic.
        localStorage.setItem("promoted",JSON.stringify(savedPromoted))


        if (promoted.length !== 0 && appProperties.gdprAccepted) {
          store.dispatch(updatePromotedData(promoted)).then(() => {
            history.replace({ pathname: `/promoted`, state: { returning: true } });
          });
        }
      })
      .catch(error => {
        console.error('There was an error getting promoted channels', error);

      });
  };


  displayBeta=()=>{
    this.setState({betaPassword:true})
  }



  retrieveVOD(){
    if (!srProperties || !srProperties.menu?.length) {
      history.replace('/error/404');
      throw new Error('BigScreen config is coming with an empty menu.');
    }

    const { url } = srProperties.menu[0].target;

    const { vodChannels } = store.getState().app;

    const teaserLimit = 20;
    let limit = 20;
    if (vodChannels && vodChannels.length > 20) {
      limit = vodChannels.length;
    }
    return getFromSRAPI(url.replace('limit=12', `limit=${limit}`).concat(`&teaserLimit=${teaserLimit}`))
      .then(resp => resp.data?.data?.modules || [])
      .then(modules => {
        const moduleResponses = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < modules.length; i++) {
          const module = modules[i];
          moduleResponses.push(
            assets.getModuleData(module.id, { limit, teaserLimit }).catch(e => {
              console.error(e);
              return module;
            })
          );
        }
        if (moduleResponses.length > 0) return Promise.all(moduleResponses);
        return Promise.reject(new Error('No module found'));
      })
      .then(res => res.filter(resp => !(resp instanceof Error))) // filter failed responses
      .then(res => {
        const modulesData = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < res.length; i++) {
          if (res[i].data) {
            const module = {};
            const moduleData = res[i].data;
            const { meta, links, data } = moduleData;
            const { description, layout, id, title, totalContent, type } = data;
            module.content = data.content;
            module.meta = meta;
            module.links = links;
            module.description = description;
            module.layout = layout;
            module.id = id;
            module.title = title;
            module.totalContent = totalContent;
            module.type = type;
            modulesData.push(module);
          } else {
            modulesData.push(res[i]);
          }
        }
        return modulesData;
      })
      .catch(e => {
        console.error(e);
      })
      .then(onDemandModules => {
        const seriesIds = [];
        const modules = onDemandModules.map((module, i) => {
          // new Promise(res => {
          if (i === 0) {
            return module;
          }

          // eslint-disable-next-line no-param-reassign
          module.content = module.content.map(content => {
            const serieId = !content.page_id
              ? getSRAssetMetadata(content, 'seriesId')
              : content.editorial?.translations?.en?.description?.split('#')[1];
            if (serieId) seriesIds.push(serieId);
            return { ...content, serieId };
          });
          return module;
        });
        return { modules, seriesIds };
      })
      .then(({ modules, seriesIds }) => {
        if (seriesIds && seriesIds.length > 0) {
          return getMultipleSerieData(seriesIds)
            .then(resp =>
              modules.map(module => {
                // eslint-disable-next-line no-param-reassign
                module.content = module.content.map(content => {
                  if (content?.serieId) {
                    // eslint-disable-next-line no-param-reassign,eqeqeq
                    content.serieData = resp.find(serieInfo => serieInfo?.id == content?.serieId);
                    return content;
                  }
                  return content;
                });
                return module;
              })
            )
            .catch(() => {
              console.error('Failed loading seriesData');
              return modules;
            });
        }
        return modules;
      })
      .then(parsedOnDemandModules => {
        this.sliders = parsedOnDemandModules;
        store.dispatch(updateVODData(parsedOnDemandModules)).then(() =>
          this.setState({
            loading: false
          })
        )
      })
  };

  // eslint-disable-next-line consistent-return
  conditionalRender(){
    const {initialized, betaPassword}=this.state;
    if(!betaPassword) return <BetaGate displayBeta={this.displayBeta}/>
    if(betaPassword && !initialized) return <SplashScreen loadFailed={this.loadFailed}/>
    if(betaPassword && initialized) return <Routes/>

  }


  renderBanner(){
    const {translationLoaded}=this.state;
    if(translationLoaded && window.innerWidth<mobileSize ){
      return  <SmartBanner
        title='rlaxx TV'
        author="rlaxx TV GmbH"
        daysHidden={7}
        price={{ android:"Available" }}
        appMeta={{ ios: '', android: 'google-play-app' }}
      />
    }
    return null;
  }

  render(){
    return(
      <div id="app" className="App"  >
        {this.conditionalRender()}
      </div>
    )
  }
}

App.contextType = PlayerContext;

export default App
